import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MyPageNavigate from '../../components/MyPageNavigate/MyPageNavigate';
import styles from './MyCard.module.scss';
import plus from '../../assets/plus02.png';
import { isDesktop, isMobile } from 'react-device-detect';
import leftArrow from '../../assets/leftArrow02.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import LoginHandler from '../../components/LoginHandler/LoginHandler';
import prevNavi from '../../assets/prevNavi.png';
import mycard from '../../assets/dietProgram/mycard.png';
import cardAdd from '../../assets/dietProgram/cardAdd.png';
import CardDelete from '../../components/CardDelete/CardDelete';
import bc from '../../assets/dietProgram/payIcon/bc.png';
import citi from '../../assets/dietProgram/payIcon/citi.png';
import gwangju from '../../assets/dietProgram/payIcon/gwangju.png';
import hana from '../../assets/dietProgram/payIcon/hana.png';
import hyundai from '../../assets/dietProgram/payIcon/hyundai.png';
import jcb from '../../assets/dietProgram/payIcon/jcb.png';
import jeju from '../../assets/dietProgram/payIcon/jeju.png';
import jeonbuk from '../../assets/dietProgram/payIcon/jeonbuk.png';
import k from '../../assets/dietProgram/payIcon/k.png';
import kakaoPay from '../../assets/dietProgram/payIcon/kakao.png';
import kb from '../../assets/dietProgram/payIcon/kb.png';
import kdb from '../../assets/dietProgram/payIcon/kdb.png';
import lotte from '../../assets/dietProgram/payIcon/lotte.png';
import masterPay from '../../assets/dietProgram/payIcon/master.png';
import nh from '../../assets/dietProgram/payIcon/nh.png';
import post from '../../assets/dietProgram/payIcon/post.png';
import sae from '../../assets/dietProgram/payIcon/sae.png';
import samsung from '../../assets/dietProgram/payIcon/samsung.png';
import saving from '../../assets/dietProgram/payIcon/saving.png';
import shinhan from '../../assets/dietProgram/payIcon/shinhan.png';
import shinhyup from '../../assets/dietProgram/payIcon/shinhyup.png';
import toss from '../../assets/dietProgram/payIcon/toss.png';
import visa from '../../assets/dietProgram/payIcon/visa.png';
import woori from '../../assets/dietProgram/payIcon/woori.png';

export interface ICardType {
  id: string;
  card_name: string;
  card_number: string;
  subscriptions: ISubType[];
}

export interface ISubType {
  subscription_id: number;
  options: [];
}

function MyCard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDelete, setIsDelete] = useState(false);
  const [isChange, setIsChange] = useState(false);
  // 카드변경 아이디
  const [billId, setBillId] = useState();
  // 카드 변경 구독 아이디
  const [subId, setSubId] = useState();
  const [cardList, setCardList] = useState<ICardType[]>([]);
  const [selectId, setSelectId] = useState();
  const [subscribeChangeId, setSubscribeChangeId] = useState('');
  // 카드변경 선택한 아이디

  useEffect(() => {
    const pathState = location.state?.pathState;
    const billId = location.state?.billId;
    const subId = location.state?.subId;
    if (billId) setBillId(billId);
    if (subId) setSubId(subId);
    if (pathState) setIsChange(true);
    else setIsChange(false);
  }, []);

  useEffect(() => {
    axiosClient
      .get('/data/billkey', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (res.data.error_message) return;
        if (
          res.data.data &&
          res.data.data !== '등록된 결제수단이 존재하지 않습니다.'
        )
          setCardList(res.data.data);
      });
  }, []);

  const removeBracket = (value: string) => {
    if (value.startsWith('[') && value.endsWith(']')) {
      return value.slice(1, -1);
    }
    return value;
  };

  const getBankImg = (value: string) => {
    if (value.includes('비씨')) return bc;
    if (value.includes('하나')) return hana;
    if (value.includes('NH')) return nh;
    if (value.includes('우리')) return woori;
    if (value.includes('국민')) return kb;

    return '';
  };

  const maskNum = (number: string) => {
    const length = number.length;

    if (length <= 4) return number;
    const masked = '*'.repeat(length - 4);
    const notMasked = number.slice(-4);
    return masked + notMasked;
  };

  const handleSubChange = () => {
    if (!subscribeChangeId) return;
    if (!subId) return;

    let formData = new FormData();

    formData.append('subscription_id', subId);
    formData.append('bill_keys', subscribeChangeId);

    axiosClient
      // .post(
      //   `/func/subscription/change/billkey?subscription_id=${subId}&billkey_id=${subscribeChangeId}`,
      // )
      .post(`/func/subscription/delivery`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        console.log(res);
        alert('결제 수단이 변경되었습니다.');
        setCardList((prevList) =>
          prevList.map((card) => ({
            ...card,
            isSubscribed: card.id === subscribeChangeId,
          })),
        );
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Header />
      <LoginHandler />
      {isDesktop && <MyPageNavigate />}
      <div
        className={`${styles.contentWrap} ${isMobile ? styles.isMobile : ''} ${
          !cardList && styles.zeroContentWrap
        }`}
      >
        <div className={styles.header}>
          {isMobile && (
            <img onClick={() => navigate(-1)} src={prevNavi} alt="prev" />
          )}
          <span>간편 카드 등록</span>
          {isMobile && <div></div>}
        </div>
        <div className={styles.myCardsWrap}>
          {cardList && cardList ? (
            <div className={styles.noZeroCardWrap}>
              <div className={styles.cardCountWrap}>
                <span>등록된 카드</span>
                <span>{cardList && cardList.length + '개'}</span>
              </div>
              <div className={styles.cardListWrap}>
                {cardList &&
                  cardList.map((card: any, cardIdx) => {
                    const titleSet = new Set(); // 각 카드마다 새로운 Set 생성

                    return (
                      <div key={cardIdx} className={styles.cardListFrame}>
                        <div className={styles.cardInfoWrap}>
                          <div className={styles.leftWrap}>
                            {getBankImg(card?.card_name) && (
                              <img
                                src={getBankImg(card?.card_name)}
                                alt="bankLogo"
                              />
                            )}
                            {/* <span>BC {maskNum('333123123123121')}</span> */}
                            <span>
                              {removeBracket(card?.card_name) +
                                ' ' +
                                card?.card_number}
                            </span>
                          </div>
                          <div className={styles.rightWrap}>
                            {isChange && card.id !== subscribeChangeId ? (
                              <div
                                className={`${styles.deleteBtnWrap} ${
                                  card.id === subscribeChangeId
                                    ? styles.selectBtn
                                    : ''
                                }`}
                                onClick={() => {
                                  setSubscribeChangeId(card.id);
                                }}
                              >
                                선택
                              </div>
                            ) : (
                              <div className={styles.deleteBtnWrap}>삭제</div>
                            )}
                          </div>
                        </div>
                        {/* subscribeOutWrap 안보이게 하기 전 */}
                        {/* {card.subscriptions.map((sub: any, subIdx: any) => (
                          <div className={styles.subscribeOutWrap} key={subIdx}>
                            {sub.options.map((title: any, titleIdx: any) => {
                              const subTitle = title
                                ?.split('/')[1]
                                ?.trim()
                                .replace(/\s*\(.*$/, '');

                              if (
                                (!isChange ||
                                  (isChange &&
                                    sub.subscription_id === subId)) &&
                                subTitle &&
                                !titleSet.has(subTitle)
                              ) {
                                titleSet.add(subTitle);
                                return (
                                  <div
                                    key={titleIdx}
                                    className={styles.subscribeWrap}
                                  >
                                    <div className={styles.subscribeMarkWrap}>
                                      구독중
                                    </div>
                                    <span>{'[정기배송] ' + subTitle}</span>
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        ))} */}
                        {/* subscribeOutWrap 안보이게 */}
                        {card.subscriptions.map((sub: any, subIdx: any) => {
                          const subscriptionContent = sub.options
                            .map((title: any, titleIdx: any) => {
                              const subTitle = title
                                ?.split('/')[1]
                                ?.trim()
                                ?.replace(/\s*\(.*$/, '');
                              if (subTitle && !titleSet.has(subTitle)) {
                                titleSet.add(subTitle);
                                return (
                                  <div
                                    key={titleIdx}
                                    className={styles.subscribeWrap}
                                  >
                                    <div className={styles.subscribeMarkWrap}>
                                      구독중
                                    </div>
                                    <span>{'[정기배송] ' + subTitle}</span>
                                  </div>
                                );
                              }
                              return null;
                            })
                            .filter(Boolean); // null 값 제거

                          // subscriptionContent가 비어있지 않은 경우에만 subscribeOutWrap 렌더링
                          return subscriptionContent.length > 0 ? (
                            <div
                              className={styles.subscribeOutWrap}
                              key={subIdx}
                            >
                              {subscriptionContent}
                            </div>
                          ) : null;
                        })}
                      </div>
                    );
                  })}
              </div>
              <div className={styles.cardAddWrap}>
                <div
                  className={styles.cardAddBtnWrap}
                  onClick={() => {
                    navigate('/card-register');
                  }}
                >
                  <img src={cardAdd} alt="cardAdd" />
                  <span>카드 등록하기</span>
                </div>
              </div>
              {isChange && (
                <div className={styles.changeWrap}>
                  <div
                    className={`${styles.changeBtnWrap} ${
                      subscribeChangeId ? styles.okChange : ''
                    }`}
                    onClick={() => {
                      handleSubChange();
                    }}
                  >
                    결제수단 변경하기
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.zeroCardWrap}>
              <img src={mycard} alt="card" />
              <div className={styles.zeroTextWrap}>
                <span className={styles.noCard}>등록된 카드가 없어요.</span>
                <span className={styles.noCardAlert}>
                  간편 카드 등록을 시작해 보세요.
                </span>
              </div>
              <div
                className={styles.appendCardBtn}
                onClick={() => {
                  navigate('/card-register');
                }}
              >
                <span>카드 등록</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
      {isDelete && <CardDelete billKey={selectId} closer={setIsDelete} />}
    </div>
  );
}
export default MyCard;
