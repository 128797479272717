import styles from './SubscribeOrderDetail.module.scss';
import Header from '../../components/Header/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { IProgramType } from '../MyPage/MyPage';

function SubscribeOrderDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [programList, setProgramList] = useState<IProgramType>();

  useEffect(() => {
    if (!location.state.subList) return;
    setProgramList(location.state.subList);
  }, []);

  const getPricePerDay = (optionName: string) => {
    if (optionName.includes('붓기')) {
      return 9050;
    } else if (optionName.includes('클렌즈')) {
      return 7000;
    } else if (optionName.includes('풀')) {
      return 16050;
    } else {
      return 0;
    }
  };

  return (
    <div>
      <Header />
      <div
        className={`${styles.container} ${isDesktop ? styles.isDesktop : ''}`}
      >
        <div className={styles.contentWrap}>
          <div className={styles.topWrap}>
            <span>{programList?.nth}회차 진행중</span>
            <div>
              <span>다음 결제 예정일</span>
              <span>
                {programList?.status === 'C'
                  ? '없음'
                  : moment(programList?.next_payment_at).format('YYYY.M.D')}
              </span>
            </div>
          </div>
          <div className={styles.detailWrap}>
            <div className={styles.detailListFrame}>
              <div className={styles.title}>
                <span>
                  {'[정기배송] ' +
                    programList?.option_name
                      ?.split('/')[1]
                      .trim()
                      .replace(/\s*\(.*$/, '')}
                </span>
              </div>
              <div className={styles.subInfoWrap}>
                <span className={styles.hit}>
                  {programList &&
                    `₩${(
                      getPricePerDay(programList?.option_name) * 30
                    ).toLocaleString()}/월`}
                </span>
                <div>
                  <span>가장 최근 결제</span>
                  <span>
                    {moment(programList?.latest_payment_at).format('YYYY.M.D')}
                  </span>
                </div>
              </div>
              {/* <div className={styles.detailContainer}>
                <div className={styles.detailFrame}>
                  <div className={styles.titlePriceWrap}>
                    <span>제품구성</span>
                  </div>
                  <div className={styles.listWrap}>
                    <div className={styles.listFrame}>
                      <span>상품</span>
                      <span>옵션</span>
                    </div>
                    <div className={styles.listFrame}>
                      <span>상품</span>
                      <span>옵션</span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeOrderDetail;
