import Header from '../../components/Header/Header';
import styles from './DietProgram.module.scss';
import blueNotice from '../../assets/dietProgram/blueNotice.png';
import eatAlert from '../../assets/dietProgram/eatAlert.png';
import dietList from '../../assets/dietProgram/dietList.png';
import dietChat from '../../assets/dietProgram/dietChat.png';
import direct from '../../assets/dietProgram/direct.png';
import { useNavigate } from 'react-router-dom';
import dietProgramBanner from '../../assets/dietProgram/dietProgramBanner.png';
import dietProgramBannerPc from '../../assets/dietProgram/dietProgramBannerPc.png';
import { isDesktop, isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import DietProgramWelcomModal from '../../components/DietProgramWelcomModal/DietProgramWelcomModal';
import axiosClient from '../../libs/axiosClient';
import AppPushModal from '../../components/AppPushModal/AppPushModal';
import { ISurveyType } from '../DietManagement/DietManagement';

function DietProgram() {
  const navigate = useNavigate();
  const [appPushOpen, setAppPushOpen] = useState(false);
  const [welcomeOpen, setWelcomeOpen] = useState(false);
  const [isApp, setIsApp] = useState(false);
  const [isFirstView, setIsFirstView] = useState('');
  const [isProgram, setIsProgram] = useState('');
  const [foodCount, setFoodCount] = useState();
  const [dietCount, setDietCount] = useState();
  const [isFoodGenerate, setIsFoodGenerate] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('mobileApp')) setIsApp(true);
    else setIsApp(false);

    axiosClient
      .get('/data/subscription/check', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const result = res.data.data;
        setIsFirstView(result.is_first_viewed);
        setIsProgram(result.is_program);
        setFoodCount(result.unread_diet_survey_count);
        setDietCount(result.unread_consult_count);

        if (result.is_program === 'N') {
          alert('다이어트 프로그램을 이용하는 사람만 접근 가능합니다.');
          return navigate('/');
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // 식단 생성 여부
    axiosClient
      .get('/data/diet_survey/issue', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (res.data.data.issue_diet === 'Y') setIsFoodGenerate(true);
        else setIsFoodGenerate(false);
      })
      .catch((err) => {
        console.log(err.response.data.detail === 'Subscription not found');
      });

    // 푸쉬 허용 여부 가져오기
    if (localStorage.getItem('mobileApp')) {
      window.flutter_inappwebview
        .callHandler('getAppPush')
        .then((res: any) => {
          const result = res.data.data;
          // is_push 허용 했으면 팝업 안 뜨도록
          setAppPushOpen(result.is_push === 'Y' ? false : true);
        })
        .catch((err: any) => {});
    }
  }, []);

  useEffect(() => {
    if (!isFirstView || !isProgram) return;

    // 프로그램 페이지 첫 접속 시
    if (isFirstView === 'N' && isProgram === 'Y') {
      setWelcomeOpen(true);
    }
  }, [isFirstView, isProgram]);
  return (
    <div>
      <Header />
      <div
        className={`${styles.contentWrap} ${isDesktop ? styles.isDesktop : ''}`}
      >
        {isApp ? (
          <div className={styles.topWrap}>
            <div className={styles.leftWrap}>
              <img src={blueNotice} alt="notice" />
              <div className={styles.alertTextWrap}>
                <span>앱 알림</span>
                <br />
                <span>PUSH 설정</span>
                {isMobile && <br />}
                <span>을 켜주세요!</span>
              </div>
            </div>
            <div
              className={styles.rightWrap}
              onClick={() => {
                navigate('/diet-alert');
              }}
            >
              <div className={styles.rightTitleWrap}>
                <span className={styles.title}>제품 복용 알림</span>
                {isDesktop && (
                  <div className={styles.directWrap}>
                    <span>설정하기</span>
                    <img src={direct} alt="direct" />
                  </div>
                )}
              </div>
              <div className={styles.smallText}>
                <span>직접 설정하신 기간에 따라</span>
                <br />
                <span>알림을 보내드려요.</span>
              </div>
              {!isDesktop && (
                <div className={styles.directWrap}>
                  <span>설정하기</span>
                  <img src={direct} alt="direct" />
                </div>
              )}
              <img className={styles.eatAlertImg} src={eatAlert} alt="alert" />
            </div>
          </div>
        ) : (
          <div className={`${styles.topWrap} ${styles.webTopWrap}`}>
            <div
              className={styles.rightWrap}
              onClick={() => {
                // navigate('/diet-alert');
              }}
            >
              <div className={styles.rightTitleWrap}>
                <span className={styles.title}>
                  온닥 앱을 설치하고, 앱 알림 {isMobile && <br />}PUSH 설정을
                  켜주세요.
                </span>
              </div>
              <div className={styles.smallText}>
                <span>프로그램 지정 시간에 따라 </span>
                <span className={styles.hitText}>제품 복용 알림</span>
                <span>을 보내드려요!</span>
                <img
                  className={styles.eatAlertImg}
                  src={eatAlert}
                  alt="alert"
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={styles.bannerWrap}
          onClick={() => {
            navigate('/dietprogram-summary');
          }}
        >
          <img
            src={isDesktop ? dietProgramBannerPc : dietProgramBanner}
            alt="dietProgramBanner"
          />
        </div>
        <div className={styles.dietListWrap}>
          <div
            className={styles.listFrame}
            onClick={() => {
              navigate('/diet-management');
            }}
          >
            <div className={styles.left}>
              <img src={dietList} alt="list" />
              <div className={styles.textWrap}>
                <span>식단 관리 받기</span>
                {!isFoodGenerate && (
                  <span className={styles.smallText}>
                    지금 바로 딱맞춤 식단을 발급 받아보세요!
                  </span>
                )}
              </div>
            </div>
            <div className={styles.right}>
              {foodCount !== 0 && (
                <div className={styles.countWrap}>{foodCount}</div>
              )}
            </div>
          </div>
          <div
            className={styles.listFrame}
            onClick={() => {
              navigate('/diet-qna');
            }}
          >
            <div className={styles.left}>
              <img src={dietChat} alt="list" />
              <span>다이어트 상담하기</span>
            </div>
            <div className={styles.right}>
              {dietCount !== 0 && (
                <div className={styles.countWrap}>{dietCount}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {welcomeOpen && <DietProgramWelcomModal closer={setWelcomeOpen} />}
      {isApp && appPushOpen && <AppPushModal closer={setAppPushOpen} />}
    </div>
  );
}
export default DietProgram;
