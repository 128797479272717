import { isDesktop } from 'react-device-detect';
import Header from '../../components/Header/Header';
import styles from './Login.module.scss';
import loginLogo from '../../assets/loginLogo.png';
import loginBack from '../../assets/loginBack.png';
import kakaoLogo from '../../assets/kakaoLogo.png';
import userIcon from '../../assets/userIcon.png';
import passwordIcon from '../../assets/passwordIcon.png';
import prevNavi from '../../assets/prevNavi.png';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { KAKAO_AUTH_URL } from '../../auth/Kakao';
import axiosClient from '../../libs/axiosClient';
import { setToken } from '../../auth/auth';
import { handleRedirectUrlCheck } from '../../common/redirectUrlCheck';
import Cookies from 'universal-cookie';

function Login() {
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [orderId, setOrderId] = useState('');
  const [orderName, setOrderName] = useState('');
  const [orderTel, setOrderTel] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  let redirectURL = new URLSearchParams(location.search).get('rurl');
  const cookies = new Cookies();
  const [kakaoLoginGeustCookie, setKakaoLoginGuestCookie] = useState('');
  const [kakaoLoginReferrerCode, setKakaoLoginReferrerCode] = useState<
    string | null
  >('');
  const [deviceToken, setDeviceToken] = useState('');
  const [isFirstView, setIsFirstView] = useState('');
  const [isProgram, setIsProgram] = useState('');

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    axiosClient
      .post(
        '/func/user/login',
        {
          id: id,
          password: pw,
        },
        {
          withCredentials: true,
          headers: {
            device: deviceToken,
          },
        },
      )
      .then((res) => {
        setToken(res.data);

        if (localStorage.getItem('mobileApp')) {
          axiosClient
            .get('/data/subscription/check', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            })
            .then((res) => {
              setIsFirstView(res.data.data.is_first_viewed);
              setIsProgram(res.data.data.is_program);
              if (
                res.data.data.is_first_viewed === 'N' &&
                res.data.data.is_program === 'Y'
              ) {
                navigate('/dietprogram');
              } else if (handleRedirectUrlCheck(redirectURL || '/')) {
                window.location.href = redirectURL || '/';
              } else {
                window.location.href = '/';
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          if (handleRedirectUrlCheck(redirectURL || '/')) {
            // navigate(redirectURL || '/');
            window.location.href = redirectURL || '/';
          } else {
            // navigate('/');
            window.location.href = '/';
          }
        }

        if (localStorage.getItem('mobileApp')) {
          window.flutter_inappwebview
            .callHandler('syncAccessToken')
            .then((res: any) => {
              return res;
            })
            .catch((err: any) => {
              return err;
            });
        }
      })
      .catch((err) => {
        alert(err.response.data.detail);
      });
  }
  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (localToken) navigate(-1);
  }, []);

  useEffect(() => {
    if (
      cookies.get('guest_cookie') === undefined ||
      cookies.get('guest_cookie') === null
    )
      setKakaoLoginGuestCookie('');
    else setKakaoLoginGuestCookie(cookies.get('guest_cookie'));
    setKakaoLoginReferrerCode(localStorage.getItem('refCode'));
  }, []);

  const kakaoLogin = () => {
    localStorage.setItem('rurl', redirectURL || '/');
    if (!localStorage.getItem('mobileApp')) {
      window.location.href = KAKAO_AUTH_URL;
      return;
    }

    let refCode = '';
    if (kakaoLoginReferrerCode) {
      const parsedData = JSON.parse(kakaoLoginReferrerCode);
      refCode = parsedData.refCodeUrl;
    }

    window.flutter_inappwebview
      .callHandler('kakaoLogin', {
        guest_cookie: kakaoLoginGeustCookie,
        referrer_code: refCode,
      })
      .then((res: any) => {
        if (JSON.stringify(res.statusCode) === '200') {
          window.localStorage.removeItem('refCode');
          let rurl = localStorage.getItem('rurl');

          axiosClient
            .get('/data/subscription/check', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            })
            .then((res) => {
              setIsFirstView(res.data.data.is_first_viewed);
              setIsProgram(res.data.data.is_program);
              if (
                res.data.data.is_first_viewed === 'N' &&
                res.data.data.is_program === 'Y'
              ) {
                navigate('/dietprogram');
              } else if (handleRedirectUrlCheck(redirectURL || '/')) {
                window.location.href = rurl || '/';
              } else {
                window.location.href = '/';
              }
            })
            .catch((err) => {
              console.log(err);
            });
          // if (handleRedirectUrlCheck(rurl || '/')) {
          //   // navigate(rurl || '/');
          //   window.location.href = rurl || '/';
          // } else {
          //   // navigate('/');
          //   window.location.href = '/';
          // }
        }
      })
      .catch((err: any) => {
        if (err.response && err.response.data && err.response.data.detail)
          return err.response.data.detail;
        navigate('/login');
      });
  };

  useEffect(() => {
    if (localStorage.getItem('mobileApp')) {
      window.flutter_inappwebview
        .callHandler('getDeviceToken')
        .then((res: any) => {
          setDeviceToken(JSON.stringify(res.data.token).replace(/\"/g, ''));
        })
        .catch((err: any) => {});
    }
  }, []);

  const handleNonMember = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!orderId || !orderName || !orderTel)
      return alert(
        '주문번호, 주문자명, 주문자 연락처를 다시 한 번 확인해주세요.',
      );
    if (orderId.length !== 17)
      return alert(`주문번호는 '-' 포함 17자리로 작성해 주세요.`);

    navigate('/non-myorder', {
      state: {
        orderId: orderId,
        orderName: orderName,
        orderTel: orderTel,
      },
    });
  };

  return (
    <div className={`${styles.container} ${!isDesktop && styles.isMobile}`}>
      {isDesktop && <Header />}
      {!isDesktop && (
        <div className={styles.backBtn} onClick={() => navigate(-1)}>
          <img src={prevNavi} alt="backBtn" />
        </div>
      )}
      <div className={styles.contentWrap}>
        <h2 className={styles.title}>로그인</h2>
        <form onSubmit={(e) => handleSubmit(e)} className={styles.loginForm}>
          <div
            style={{
              display: 'block'
                // localStorage.getItem('mobileApp') &&
                // navigator.userAgent.toLowerCase().includes('ondoc-ios')
                //   ? 'none'
                //   : 'block',
            }}
          >
            <div className={styles.kakaoLoginBtn} onClick={kakaoLogin}>
              <img src={kakaoLogo} alt="kakaoLogo" />
              <span>카카오로 시작하기</span>
            </div>
            <div className={styles.or}>
              <div></div>
              <span>OR</span>
              <div></div>
            </div>
          </div>
          <div className={styles.inputWrap}>
            <input
              type="text"
              value={id}
              onChange={(e) => setId(e.currentTarget.value)}
              onBlur={() => setId((prev) => prev.trim())}
              placeholder="아이디"
            />
          </div>
          <div className={styles.inputWrap}>
            <input
              type="password"
              value={pw}
              onChange={(e) => setPw(e.currentTarget.value)}
              onBlur={() => setPw((prev) => prev.trim())}
              placeholder="비밀번호"
            />
          </div>
          <button className={styles.loginBtn}>로그인</button>
        </form>
        <ul className={styles.menu}>
          <li onClick={() => navigate('/findAccount?tab=0')}>아이디찾기</li>
          <li onClick={() => navigate('/findAccount?tab=1')}>비밀번호찾기</li>
          <li onClick={() => navigate('/join')}>회원가입</li>
        </ul>
        <div className={styles.or}>
          <div></div>
          <span>OR</span>
          <div></div>
        </div>
        <h2 className={`${styles.title} ${styles.nonTitle}`}>
          비회원 주문 조회
        </h2>
        <form
          onSubmit={(e) => handleNonMember(e)}
          className={`${styles.loginForm} ${styles.nonForm}`}
        >
          <div className={styles.inputWrap}>
            <input
              type="text"
              value={orderId}
              maxLength={17}
              onChange={(e) => setOrderId(e.currentTarget.value)}
              onBlur={() => setOrderId((prev) => prev.trim())}
              placeholder="주문번호"
            />
          </div>
          <div className={styles.inputWrap}>
            <input
              type="text"
              value={orderName}
              onChange={(e) => {
                const inputValue = e.currentTarget.value;
                const filteredValue = inputValue.replace(
                  /[^ㄱ-ㅎ가-힣a-zA-Z]/g,
                  '',
                );
                setOrderName(filteredValue);
              }}
              onBlur={() => setOrderName((prev) => prev.trim())}
              placeholder="주문자명"
            />
          </div>
          <div className={styles.inputWrap}>
            <input
              type="text"
              value={orderTel}
              maxLength={11}
              onChange={(e) => {
                const value = e.currentTarget.value;
                if (/^\d*$/.test(value)) setOrderTel(value);
              }}
              onBlur={() => setOrderTel((prev) => prev.trim())}
              placeholder="주문자 연락처"
            />
          </div>
          <button className={`${styles.loginBtn} ${styles.nonBtn}`}>
            비회원 주문 조회하기
          </button>
        </form>
      </div>
    </div>
  );
}
export default Login;
