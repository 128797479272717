import styles from './DietProgramSummary.module.scss';
import programDetail from '../../assets/dietProgram/programDetail.png';
import appleBtn from '../../assets/dietProgram/appleBtn.png';
import googleBtn from '../../assets/dietProgram/googleBtn.png';
import Header from '../../components/Header/Header';
import { isDesktop } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

function DietProgramSummary() {
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <div
        className={`${styles.container} ${isDesktop ? styles.isDesktop : ''}`}
      >
        <img src={programDetail} alt="programDetail" />
        {/* <div className={styles.appBtnWrap}>
          <img
            onClick={() => {
              window.location.href =
                'https://apps.apple.com/kr/app/%EC%98%A8%EB%9D%BC%EC%9D%B8%EB%8B%A5%ED%84%B0-%EC%98%A8%EB%8B%A5/id6475955762';
            }}
            src={appleBtn}
            alt="appleBtn"
          />
          <img onClick={() => {}} src={googleBtn} alt="googleBtn" />
        </div> */}
      </div>
    </div>
  );
}

export default DietProgramSummary;
