import styles from './OrderFrame.module.scss';
import rightArrow2 from '../../assets/rightArrow04.png';
import rightArrow3 from '../../assets/rightArrow05.png';
import imsiProduct from '../../assets/imsi/imsiProduct4.png';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../libs/axiosClient';
import { IOrderDataType } from '../../pages/OrderComplete/OrderComplete';

interface IPropsType {
  page?: string;
  orderData: IOrderDataType;
}
function OrderFrame(props: IPropsType) {
  const navigate = useNavigate();
  return (
    <>
      {isMobile ? (
        <div className={styles.isMobile}>
          <div className={styles.orderDate}>
            <span>
              {props.orderData.order_date.substring(2, 10).replace(/-/gi, '.')}{' '}
              결제
            </span>
            <div
              onClick={() => {
                if (props.orderData.pay_status === '결제중')
                  navigate(
                    `/order-continue/${props.orderData.combined_order_id}`,
                  );
                else navigate(`/orderDetail/${props.orderData.order_id}`);
              }}
            >
              <span>상세보기</span>
              <img src={rightArrow2} alt="arrow" />
            </div>
          </div>
          <div className={styles.productWrap}>
            <div
              className={styles.productThumbWrap}
              onClick={() =>
                navigate(
                  `/productDetail/${props.orderData.products[0].product_id}`,
                )
              }
            >
              <img src={props.orderData.products[0]?.image} alt="thumbnail" />
            </div>
            <div className={styles.productInfoWrap}>
              <span>{props.orderData.order_id}</span>
              <span>{props.orderData.products[0].title}</span>
              <span>{props.orderData.total_price.toLocaleString()}원</span>
            </div>
            <div
              className={styles.status}
              style={{
                borderColor:
                  props.orderData.pay_status == '배송대기'
                    ? '#F6F7F9'
                    : props.orderData.pay_status == '배송준비'
                    ? '#00BE35'
                    : props.orderData.pay_status == '배송중'
                    ? '#B7E7BD'
                    : props.orderData.pay_status == '배송완료'
                    ? '#121842'
                    : props.orderData.pay_status == '주문취소'
                    ? '#FF3D3D'
                    : props.orderData.pay_status === '결제중'
                    ? '#3559ED'
                    : props.orderData.pay_status == '결제대기'
                    ? '#3259E6'
                    : props.orderData.pay_status == '결제완료'
                    ? '#131C31'
                    : '',
                color:
                  props.orderData.pay_status == '배송대기'
                    ? '#999999'
                    : props.orderData.pay_status == '배송준비'
                    ? '#00BE35'
                    : props.orderData.pay_status == '배송중'
                    ? '#00BE35'
                    : props.orderData.pay_status == '배송완료'
                    ? '#141736'
                    : props.orderData.pay_status == '주문취소'
                    ? '#FF3D3D'
                    : props.orderData.pay_status === '결제중'
                    ? '#3559ED'
                    : props.orderData.pay_status == '결제대기'
                    ? '#fff'
                    : props.orderData.pay_status == '결제완료'
                    ? '#fff'
                    : '',
                backgroundColor:
                  props.orderData.pay_status == '배송대기'
                    ? '#F6F7F9'
                    : props.orderData.pay_status == '결제대기'
                    ? '#3259E6'
                    : props.orderData.pay_status == '결제완료'
                    ? '#131C31'
                    : 'white',
              }}
            >
              {props.orderData.pay_status}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${styles.isPC} ${
            props.page == 'myPage' ? styles.secondDesign : ''
          }`}
        >
          <div className={styles.orderInfo}>
            <span>
              {props.orderData.order_date.substring(0, 10).replace(/-/gi, '.')}
            </span>
            <span>{props.orderData.order_id}</span>
          </div>
          <div className={styles.productInfo}>
            <div
              className={styles.productThumbWrap}
              onClick={() =>
                navigate(
                  `/productDetail/${props.orderData.products[0].product_id}`,
                )
              }
            >
              <img src={props.orderData.products[0]?.image} alt="thumbnail" />
            </div>
            <span>{props.orderData.products[0].title}</span>
          </div>
          <span className={styles.price}>
            {props.orderData.total_price.toLocaleString()}원
          </span>
          <div className={styles.statusWrap}>
            <div
              className={styles.status}
              style={{
                borderColor:
                  props.orderData.pay_status == '배송대기'
                    ? '#F6F7F9'
                    : props.orderData.pay_status == '배송준비'
                    ? '#00BE35'
                    : props.orderData.pay_status == '배송중'
                    ? '#B7E7BD'
                    : props.orderData.pay_status == '배송완료'
                    ? '#121842'
                    : props.orderData.pay_status == '주문취소'
                    ? '#FF3D3D'
                    : props.orderData.pay_status === '결제중'
                    ? '#3559ED'
                    : props.orderData.pay_status == '결제대기'
                    ? '#3259E6'
                    : props.orderData.pay_status == '결제완료'
                    ? '#131C31'
                    : '',
                color:
                  props.orderData.pay_status == '배송대기'
                    ? '#999999'
                    : props.orderData.pay_status == '배송준비'
                    ? '#00BE35'
                    : props.orderData.pay_status == '배송중'
                    ? '#00BE35'
                    : props.orderData.pay_status == '배송완료'
                    ? '#141736'
                    : props.orderData.pay_status == '주문취소'
                    ? '#FF3D3D'
                    : props.orderData.pay_status === '결제중'
                    ? '#3559ED'
                    : props.orderData.pay_status == '결제대기'
                    ? '#fff'
                    : props.orderData.pay_status == '결제완료'
                    ? '#fff'
                    : '',
                backgroundColor:
                  props.orderData.pay_status == '배송대기'
                    ? '#F6F7F9'
                    : props.orderData.pay_status == '결제대기'
                    ? '#3259E6'
                    : props.orderData.pay_status == '결제완료'
                    ? '#131C31'
                    : 'white',
              }}
            >
              {props.orderData.pay_status}
            </div>
            <img
              src={rightArrow3}
              alt="arrow"
              onClick={() => {
                if (props.orderData.pay_status === '결제중')
                  navigate(
                    `/order-continue/${props.orderData.combined_order_id}`,
                  );
                else navigate(`/orderDetail/${props.orderData.order_id}`);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
export default OrderFrame;
