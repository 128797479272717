import { useEffect } from 'react';
import styles from './PayChangeModal.module.scss';
import slide from '../../assets/dietProgram/slide.png';
import pay from '../../assets/dietProgram/pay.png';
import close from '../../assets/dietProgram/close.png';
import { isDesktop } from 'react-device-detect';
import useMobileSlide from '../../hook/MobileSlide';
import { useNavigate } from 'react-router-dom';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
  billId: string;
  subId: number;
}

function PayChangeModal(props: IPropsType) {
  const { modalRef, handleTouchStart, handleTouchMove, handleTouchEnd } =
    useMobileSlide({ closer: props.closer });

  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={`${styles.container} ${isDesktop ? styles.isDesktop : ''}`}>
      <div ref={modalRef} className={styles.contentWrap}>
        {isDesktop ? (
          <div className={styles.closeWrap}>
            <img
              src={close}
              alt="close"
              onClick={() => {
                props.closer(false);
              }}
            />
          </div>
        ) : (
          <div
            className={styles.slideWrap}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img src={slide} alt="slide" />
          </div>
        )}
        <div className={styles.mainWrap}>
          <img src={pay} alt="logo" />
          <span className={styles.quesText}>결제 수단을 변경하시겠어요?</span>
          {/* <div className={styles.smallText}>
            <span>구독 해지 이후 제품을 개별 구매할 시,</span>
            <span className={styles.hit}>약 130,000원 상당의 혜택</span>
            <span>을 받지 못할 수 있습니다.</span>
          </div> */}
        </div>
        <div className={styles.btnWrap}>
          <div
            className={styles.nextBtnWrap}
            onClick={() => {
              props.closer(false);
            }}
          >
            다음에
          </div>
          <div
            className={styles.okBtnWrap}
            onClick={() => {
              navigate('/myCard', {
                state: {
                  pathState: 'change',
                  billId: props.billId,
                  subId: props.subId,
                },
              });
            }}
          >
            네, 변경할게요.
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayChangeModal;
