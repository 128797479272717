import { useEffect, useState } from 'react';
import styles from './AppDownModal.module.scss';
import close from '../../assets/dietProgram/close.png';
import mobile from '../../assets/dietProgram/mobile.png';
import push from '../../assets/dietProgram/push.png';
import { isDesktop } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
}

function AppDownModal(props: IPropsType) {
  const navigate = useNavigate();
  const [isAndroid, setIsAndroid] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;
    setIsAndroid(/android/i.test(userAgent));
    setIsIOS(/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.contentWrap}>
        <div className={styles.closeWrap}>
          <img
            onClick={() => {
              props.closer(false);
            }}
            src={close}
            alt="close"
          />
        </div>
        <div className={styles.content}>
          <div className={styles.topWrap}>
            <div className={styles.pushFrame}>
              <div className={styles.leftWrap}>
                <img src={push} alt="push" />
              </div>
              <div className={styles.textWrap}>
                <span>CCA 주스 먹을 시간예요!</span>
                <br />
                <span>아침 식사 대용으로, 출근 전에 드시</span>
              </div>
            </div>
            <img className={styles.mobileImg} src={mobile} alt="mobile" />
          </div>
          <div className={styles.bottomWrap}>
            <div className={styles.hitTextWrap}>
              <span>온닥 다이어트 프로그램은</span>
              <br />
              <span>온닥 앱을 통해서 </span>
              <span className={styles.hit}>알람 및 서비스</span>
              <span>를</span>
              <br />
              <span>제공해 드리고 있습니다.</span>
            </div>
            {isDesktop ? (
              <span>지금 바로 온닥 앱을 설치하고, 모바일로 접속해주세요.</span>
            ) : (
              <span>지금 바로 온닥 앱을 설치하러 가시겠습니까?</span>
            )}
          </div>
          {isDesktop ? (
            <div
              className={styles.btnWrap}
              onClick={() => {
                navigate('/dietprogram');
              }}
            >
              다이어트 프로그램 바로가기
            </div>
          ) : (
            <div
              className={styles.btnWrap}
              onClick={() => {
                if (isAndroid) {
                  window.location.href =
                    'https://play.google.com/store/apps/details?id=kr.co.ondoc';
                } else if (isIOS) {
                  window.location.href =
                    'https://apps.apple.com/kr/app/id647595576';
                } else {
                  alert('앱 설치는 모바일 기기에서만 가능합니다.');
                }
              }}
            >
              온닥 앱 설치 바로가기
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppDownModal;
