import { useEffect, useRef, useState } from 'react';
import styles from './SubscribePlusModal.module.scss';
import slide from '../../assets/dietProgram/slide.png';
import bell from '../../assets/dietProgram/bell.png';
import close from '../../assets/dietProgram/close.png';
import { useNavigate } from 'react-router-dom';
import SubscribeCancelOkModal from '../SubscribeCancelOkModal/SubscribeCancelOkModal';
import { isDesktop } from 'react-device-detect';
import useMobileSlide from '../../hook/MobileSlide';
import axiosClient from '../../libs/axiosClient';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
  subscriptionId: number;
  getSubscription: any;
}

function SubscribePlusModal(props: IPropsType) {
  const navigate = useNavigate();
  const { modalRef, handleTouchStart, handleTouchMove, handleTouchEnd } =
    useMobileSlide({ closer: props.closer });

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const subscribeNo = () => {
    axiosClient
      .post(
        `/func/subscription/unrenew?subscription_id=${props.subscriptionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        console.log(res);
        props.closer(false);
        props.getSubscription();
        alert('구독을 연장하지 않았습니다.');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const subscribePlus = () => {
    axiosClient
      .post(
        `/func/subscription/renew?subscription_id=${props.subscriptionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        console.log(res);
        props.closer(false);
        props.getSubscription();
        alert('구독이 연장되었습니다.');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={`${styles.container} ${isDesktop ? styles.isDesktop : ''}`}>
      <div ref={modalRef} className={styles.contentWrap}>
        {isDesktop ? (
          <div className={styles.closeWrap}>
            <img
              src={close}
              alt="close"
              onClick={() => {
                props.closer(false);
              }}
            />
          </div>
        ) : (
          <div
            className={styles.slideWrap}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img src={slide} alt="slide" />
          </div>
        )}
        <div className={styles.mainWrap}>
          <img src={bell} alt="logo" />
          <span className={styles.quesText}>구독을 연장하시겠어요?</span>
          <div className={styles.smallText}>
            <span>구독을 연장할 시, </span>
            <span className={styles.hit}>월 34,000원 상당의 혜택</span>
            <span>을</span>
            <br />
            <span> 계속 받으실 수 있습니다.</span>
          </div>
        </div>
        <div className={styles.btnWrap}>
          <div
            className={styles.nextBtnWrap}
            onClick={() => {
              subscribeNo();
            }}
          >
            연장하지 않을게요.
          </div>
          <div
            className={styles.okBtnWrap}
            onClick={() => {
              subscribePlus();
            }}
          >
            네, 연장할게요.
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribePlusModal;
