import { isDesktop } from 'react-device-detect';
import Header from '../../components/Header/Header';
import leftArrow from '../../assets/leftArrow02.png';
import styles from './Pick.module.scss';
import { useNavigate } from 'react-router-dom';
import imsiProduct from '../../assets/imsi/imsiProduct4.png';
import star from '../../assets/star.png';
import Footer from '../../components/Footer/Footer';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import checkMark from '../../assets/checkMark.png';
import MyPageNavigate from '../../components/MyPageNavigate/MyPageNavigate';
import LoginHandler from '../../components/LoginHandler/LoginHandler';

interface IPickedProductType {
  brand_name: null | string;
  detail_url: string;
  discount_price: number;
  discount_rate: number;
  hashtag: string;
  id: string;
  image: string;
  is_like: number;
  list_image: string;
  price: number;
  product_id: number;
  title: string;
  soldout: string;
  review_score_avg: {
    product_id: number;
    review_point_0_avg: number;
    review_point_1_avg: number;
    review_point_2_avg: number;
    review_point_3_avg: number;
    review_point_total_avg: number;
    review_total_count: number;
  };
}
function Pick() {
  const navigate = useNavigate();
  const [pickList, setPickList] = useState<IPickedProductType[]>([]);
  const [checkedProductList, setCheckedProductList] = useState<number[]>([]);
  useEffect(() => {
    axiosClient
      .get('/data/like/products')
      .then((res) => setPickList(res.data.data));
  }, []);
  useEffect(() => {
    setCheckedProductList((prev) =>
      prev.filter((productId) => {
        let isInclude = false;
        pickList.map((product) => {
          if (product.product_id == productId) isInclude = true;
        });
        return isInclude;
      }),
    );
  }, [pickList]);
  console.log(pickList);
  function handleCheck(productId: number) {
    if (checkedProductList.includes(productId))
      setCheckedProductList((prev) => [
        ...prev.filter((id) => id !== productId),
      ]);
    else setCheckedProductList((prev) => [...prev, productId]);
  }
  function handleAllSelect() {
    if (!pickList) return;
    if (checkedProductList.length == pickList.length) setCheckedProductList([]);
    else {
      let newList: number[] = [];
      pickList.map((product) => newList.push(product.product_id));
      setCheckedProductList(newList);
    }
  }
  function handleDelete() {
    if (checkedProductList.length == 0) {
      alert('삭제할 상품을 선택해주세요.');
      return;
    }
    setPickList((prev) =>
      prev.filter(
        (product) => !checkedProductList.includes(product.product_id),
      ),
    );
    let promiseList: any[] = [];
    checkedProductList.map((productId) => {
      promiseList.push(
        new Promise((resolve) => {
          axiosClient
            .delete(`/func/like?target_type=Product&target_id=${productId}`)
            .then((res) => resolve(res));
        }),
      );
    });
    Promise.allSettled(promiseList).then((res) => {
      alert('삭제되었습니다.');
    });
  }
  return (
    <div className={styles.container}>
      <LoginHandler />
      <Header />
      {isDesktop && <MyPageNavigate />}
      <div className={styles.contentWrap}>
        <h3 className={styles.title}>
          {!isDesktop && (
            <img src={leftArrow} alt="arrow" onClick={() => navigate(-1)} />
          )}
          <span>찜</span>
        </h3>
        <div className={styles.selectWrap}>
          <div className={styles.selectBtn} onClick={handleAllSelect}>
            <div
              className={`${styles.customCheckbox} ${
                checkedProductList.length == pickList.length &&
                pickList.length !== 0
                  ? styles.hit
                  : ''
              }`}
            >
              <img src={checkMark} alt="checkMark" />
            </div>
            <span>모두선택</span>
          </div>
          <div className={styles.deleteBtn} onClick={handleDelete}>
            선택삭제
          </div>
        </div>
        <ul className={styles.pickedProductList}>
          {pickList.map((product) => (
            <li
              onClick={() => navigate(`/productDetail/${product.product_id}`)}
            >
              <div className={styles.productBox}>
                <div className={styles.thumbnailWrap}>
                  <div
                    className={`${styles.checkboxWrap} ${
                      checkedProductList.includes(product.product_id)
                        ? styles.hit
                        : ''
                    }`}
                    onClick={(e) => {
                      handleCheck(product.product_id);
                      e.stopPropagation();
                    }}
                  >
                    <img src={checkMark} alt="checkMark" />
                  </div>
                  <img
                    src={product.list_image}
                    alt="thumbnail"
                    className={styles.thumbnail}
                  />
                  {product.soldout == 'Y' && (
                    <div className={styles.soldoutBox}>품절</div>
                  )}
                </div>
                <div className={styles.productInfoWrap}>
                  <h4 className={styles.productName}>{product.title}</h4>
                  <div className={styles.priceWrap}>
                    <span className={styles.discountPrice}>
                      {product.discount_price.toLocaleString()}원
                    </span>
                    {product.discount_price !== product.price && (
                      <span className={styles.price}>
                        {product.price.toLocaleString()}원
                      </span>
                    )}
                  </div>
                  <div className={styles.score}>
                    <img src={star} alt="star" />
                    <span>
                      (
                      {Math.floor(
                        (product.review_score_avg.review_point_total_avg / 2) *
                          10,
                      ) / 10}
                      )
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </div>
  );
}
export default Pick;
