import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './MyPage.module.scss';
import rightArrow from '../../assets/rightArrow.png';
import rightArrow2 from '../../assets/rightArrow04.png';
import rightArrow3 from '../../assets/rightArrow03.png';
import basket from '../../assets/myPage/basket.png';
import point from '../../assets/myPage/point.png';
import review from '../../assets/myPage/review.png';
import coupon from '../../assets/myPage/coupon.png';
import heart from '../../assets/myPage/heart.png';
import card from '../../assets/myPage/card.png';
import challenge from '../../assets/myPage/challenge.png';
import referral from '../../assets/myPage/referral.png';
import challengeDetailDirectBtn from '../../assets/challengeDetailDirectBtn.png';
import referralCodeLogo from '../../assets/myPage/referralCode.png';
import copy from '../../assets/myPage/copy.png';
import bannerScaleImg from '../../assets/bannerScaleImg.png';
import { useNavigate } from 'react-router-dom';
import OrderFrame from '../../components/OrderFrame/OrderFrame';
import { useEffect, useState } from 'react';
import { ITokenInfoType, logout } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import axiosClient from '../../libs/axiosClient';
import { IOrderDataType } from '../OrderComplete/OrderComplete';
import LoginHandler from '../../components/LoginHandler/LoginHandler';
import { isDesktop, isMobile } from 'react-device-detect';
import ChallengeSubmitModal from '../../components/ChallengeSubmitModal/ChallengeSubmitModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import programLogo1 from '../../assets/dietProgram/programLogo1.png';
import programLogo2 from '../../assets/dietProgram/programLogo2.png';
import programLogo3 from '../../assets/dietProgram/programLogo3.png';
import programLogo4 from '../../assets/dietProgram/programLogo4.png';
import right1 from '../../assets/dietProgram/right1.png';
import right2 from '../../assets/dietProgram/right2.png';
import right3 from '../../assets/dietProgram/right3.png';
import moment from 'moment';

export interface IMyPageInfoType {
  cart_count: number;
  member_point: number;
  order_count: {
    결제대기: number;
    결제완료: number;
    배송완료: number;
    배송준비: number;
    배송중: number;
    주문취소: number;
  };
}

export interface IProgramType {
  amount: number;
  billing_cycle: number;
  cycle_expire: string;
  cycle_start: string;
  cycle_type: string;
  id: number;
  latest_payment_at: string;
  next_payment_at: string;
  nth: number;
  option_name: string;
  order_id: string;
  price: number;
  product_name: string;
  status: string;
  subscript_products: ISubProductType[];
}

export interface ISubProductType {
  desc: string;
  image: string;
  name: string;
  price: string;
  product_id: string;
}

function MyPage() {
  const navigate = useNavigate();
  const [inputWeight, setInputWeight] = useState('');
  const [userInfo, setUserInfo] = useState<ITokenInfoType>();
  const [inputWeekData, setInputWeekData] = useState<any[]>([]);
  const [startInputDate, setStartInputDate] = useState();
  // const [lastInputDate, setLastInputDate] = useState<String>();
  const [todayDate, setTodayDate] = useState('');
  const [weekDay, setWeekDay] = useState([
    '일',
    '월',
    '화',
    '수',
    '목',
    '금',
    '토',
  ]);
  const [submitMsg, setSubmitMsg] = useState(''); // 몸무게 입력시 확인 문구
  const [isInputFocused, setIsInputFocused] = useState(false); // input focus
  const [challengeSubmitModalOpen, setChallengeSubmitModalOpen] =
    useState(false);
  const [challengeParticipation, setChallengeParticipation] =
    useState<String>();
  const [challengeNextStartDay, setChallengeNextStartDay] = useState<String>();
  const [challengeNextEndDay, setChallengeNextEndDay] = useState<String>();
  const [referralCode, setReferralCode] = useState<string>('');

  // Next Start End Day
  let startDay;
  let endDay;

  const [programList, setProgramList] = useState<IProgramType[]>([]);
  const [subscribeList, setSubscribeList] = useState<IProgramType>();

  useEffect(() => {
    axiosClient
      .get('/data/subscription', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const result = res.data.data;

        if (result.length === 1) setProgramList([result[0]]);
        else {
          // 여러 결과가 있는 경우
          let selectedProgram = null;
          let latestOnceExpiry = new Date(0); // 가장 이른 날짜로 초기화
          let hasOnceType = false;
          let hasMonthType = false;

          // 전체 배열을 순회하며 Once와 Month 타입의 존재 여부를 확인
          result.forEach((program: any) => {
            if (program.cycle_type === 'Once') {
              hasOnceType = true;
            } else if (program.cycle_type === 'Month') {
              hasMonthType = true;
            }
          });

          // Once와 Month가 둘 다 있거나 Once만 있는 경우
          if (hasOnceType) {
            result.forEach((program: any) => {
              if (program.cycle_type === 'Once') {
                const expireDate = new Date(program.cycle_expire);
                if (expireDate > latestOnceExpiry) {
                  latestOnceExpiry = expireDate;
                  selectedProgram = program;
                }
              }
            });
          }
          // Month만 있는 경우
          else if (hasMonthType) {
            selectedProgram = result.find(
              (program: any) => program.cycle_type === 'Month',
            );
          }

          // 선택된 프로그램 설정
          if (selectedProgram) setProgramList([selectedProgram]);
        }

        // 정기 구독에 넣을 데이터
        let subscription = null;

        result.some((program: any) => {
          if (program.cycle_type === 'Month') {
            subscription = program;
            return true; // 첫 번째 'Month' 타입을 찾으면 중단 (만료일 내림차순)
          }
          return false;
        });

        if (subscription !== null) setSubscribeList(subscription);
      });
  }, []);

  const formatDate = (date: string) => {
    return moment(date).format('YYYY년 M월 D일');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token == null || token == 'null') return;
    try {
      const decodedUserInfo: ITokenInfoType = jwt_decode(token!);
      setUserInfo(decodedUserInfo);
    } catch (err) {
      logout();
    }
  }, []);
  const [orderData, setOrderData] = useState<IOrderDataType[]>();
  useEffect(() => {
    axiosClient
      .get('/data/order?limit=3')
      .then((res) => setOrderData(res.data.data));
  }, []);

  const [myPageInfo, setMyPageInfo] = useState<IMyPageInfoType>();
  useEffect(() => {
    axiosClient.get('/data/mypage').then((res) => {
      setMyPageInfo(res.data.data);
    });
  }, []);

  const onChangeWeight = (e: any) => {
    const weightValue = e.target.value;
    const weightNumber = weightValue.replace(/[^0-9.]/g, ''); // 숫자와 .만 입력되도록
    const weightZeroRem = weightNumber.replace(/(^0+)/, ''); // 맨처음 0 삭제
    setInputWeight(weightZeroRem);
    // setInputWeight(e.target.value);
  };

  const onKeyUpWeight = (e: any) => {
    let weightValue = e.target.value;

    // 맨처음 . 입력 x
    if (weightValue.charAt(0) === '.') {
      weightValue = weightValue.substring(1);
    }

    // . 총 1번만
    const parts = weightValue.split('.');
    if (parts.length > 2) {
      weightValue = parts[0] + '.' + parts[1];
    }

    // 정수부분은 3자리까지, 소수점 아래는 2자리까지만 입력
    const regex = /^\d{0,3}(\.\d{0,2})?$/;
    if (!regex.test(weightValue)) {
      weightValue = weightValue.match(/^\d{0,3}(\.\d{0,2})?/)[0];
    }

    setInputWeight(weightValue);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    // 입력 몸무게 숫자 -> 문자
    const weight = e.target.weight.value.toString();

    // forData이용하여 몸무게 저장
    let formData = new FormData();
    formData.append('content', weight);

    // 소수점 2자리까지 숫자
    const weightRegux = /^(?!0\d)([1-9]\d*|0)(\.\d{1,2})?$/;

    if (weight === '') return;

    if (weightRegux.test(weight) && challengeParticipation === 'N') {
      setChallengeParticipation('Y');
      // 몸무게 등록
      axiosClient.post('/func/challenge/1/apply', formData).then((res) => {
        setChallengeSubmitModalOpen(true);
      });
      // .catch(function (error) {
      //   alert('몸무게 등록 실패!' + error);
      // });
    } else {
      alert('몸무게를 올바르게 작성해주세요.');
    }
  };

  // input란 focus 메시지 숨김
  const handleInputFocus = () => {
    setIsInputFocused(true);
    setSubmitMsg('');
  };

  // input란 focus 빠지면서 메시지 나타냄
  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  useEffect(() => {
    // 몸무게 조회 (주별)
    axiosClient.get(`/data/challenge/my/1`).then(function (res) {
      // console.log(res.data.challenge_log);
      setInputWeekData(res.data.challenge_log);
    });

    // 몸무게 참여여부 조회
    axiosClient
      .get(`/data/challenge/my/1/is_participated`)
      .then(function (res) {
        setChallengeParticipation(res.data.data.is_participated);
        startDay = new Date(res.data.data.next_start_date);
        endDay = new Date(res.data.data.next_end_date);
        setChallengeNextStartDay(
          startDay.getMonth() + 1 + '월 ' + startDay.getDate() + '일',
        );
        setChallengeNextEndDay(
          endDay.getMonth() + 1 + '월 ' + endDay.getDate() + '일',
        );
      });

    //오늘 날짜 계산
    const date = new Date();
    const today =
      date.getMonth() +
      1 +
      '월 ' +
      date.getDate() +
      '일 ' +
      weekDay[date.getDay()] +
      '요일';
    setTodayDate(today);
  }, []);

  useEffect(() => {
    axiosClient
      .get('/data/referrer/code', {
        headers: {
          'ngrok-skip-browser-warning': true,
        },
      })
      .then((res) => {
        setReferralCode(res.data.data.code);
      });
  }, []);

  return (
    <div>
      <LoginHandler />
      <Header />
      <div className={styles.user}>
        <div className={styles.mark}>ONDOC</div>
        <div
          className={styles.userNameWrap}
          onClick={() => navigate('/updateProfile')}
        >
          <span>{userInfo?.name} 님</span>
          <img src={rightArrow} alt="arrow" />
        </div>
        <ul className={styles.menuList}>
          <li className={styles.countMenu} onClick={() => navigate('/basket')}>
            <div>
              <img src={basket} alt="basket" />
              <span>장바구니</span>
            </div>
            <span className={styles.count}>{myPageInfo?.cart_count}</span>
          </li>
          <li className={styles.countMenu} onClick={() => navigate('/point')}>
            <div>
              <img className={styles.pointImg} src={point} alt="point" />
              <span>적립금</span>
            </div>
            <span className={styles.count}>
              {myPageInfo?.member_point.toLocaleString()}
            </span>
          </li>
          <li className={styles.normalMenu} onClick={() => navigate('/pick')}>
            <img src={heart} alt="heart" />
            <span>찜한상품</span>
          </li>
          <li className={styles.normalMenu} onClick={() => navigate('/coupon')}>
            <img src={coupon} alt="coupon" />
            <span>쿠폰</span>
          </li>
          <li
            className={styles.normalMenu}
            onClick={() => navigate('/myReview')}
          >
            <img src={review} alt="review" />
            <span>후기</span>
          </li>
          <li className={styles.normalMenu} onClick={() => navigate('/myCard')}>
            <img src={card} alt="card" />
            <span>간편결제</span>
          </li>
          <li
            className={styles.normalMenu}
            // onClick={() => navigate('/myChallenge')}
            onClick={() => navigate('/myWeight')}
          >
            <img src={challenge} alt="challenge" />
            {isMobile && <span>챌린지</span>}
            {isDesktop && <span>나의챌린지</span>}
          </li>
          <li
            className={styles.referralMenu}
            onClick={() => navigate('/referral')}
          >
            <div>
              <img src={referral} alt="referral" />
              <span>추천인</span>
            </div>
          </li>
          <li className={styles.referralMenu}>
            <div>
              <img
                className={styles.pointImg}
                src={referralCodeLogo}
                alt="referralCode"
              />
              <span>추천인 코드</span>
            </div>
            {isDesktop && (
              <span
                style={{
                  marginLeft: '8px',
                }}
              >
                {referralCode}
              </span>
            )}

            <CopyToClipboard
              text={referralCode}
              onCopy={() => console.log('복사완료')}
            >
              <span
                className={`${styles.copy} ${isMobile ? styles.isMobile : ''}`}
                onClick={() => navigate('/myPage')}
              >
                <img src={copy} alt="copy" />
                복사
              </span>
            </CopyToClipboard>
          </li>
        </ul>
      </div>
      <div
        className={`${styles.dietProgramWrap} ${
          isDesktop ? styles.isDesktop : ''
        }`}
      >
        <div className={styles.topWrap}>
          <div className={styles.titleWrap}>
            <span>다이어트 프로그램</span>
            <div className={`${programList[0] ? styles.hit : ''}`}>
              {programList[0] ? '진행중' : '미진행'}
            </div>
          </div>
          {programList[0] &&
            programList[0]?.cycle_type !== 'Month' &&
            !localStorage.getItem('mobileApp') && (
              <div className={styles.appInstallAlertWrap}>
                <span>
                  다이어트 프로그램의 경우,{isMobile && <br />} 프로그램 지정
                  시간에 따라{' '}
                </span>
                <span className={styles.hit}>제품 복용 알림</span>
                <span>을 보내드려요!</span>
                <br />
                <span className={styles.colorHit}>
                  온닥 앱을 설치하고, 앱 알림 PUSH 설정을 켜주세요.
                </span>
                <br />
                <span>
                  앱 접속 후, 마이페이지에서 진행 중인 다이어트 프로그램을
                  확인할 수 있어요.
                </span>
              </div>
            )}
          <div></div>
          {programList[0] ? (
            <div className={styles.dateWrap}>
              <span>
                {programList[0]?.cycle_type === 'Month'
                  ? '시작일자'
                  : '진행기간'}
              </span>
              <span>
                {formatDate(programList[0]?.cycle_start)} ~{' '}
                {programList[0]?.cycle_type !== 'Month' &&
                  formatDate(programList[0]?.cycle_expire)}
              </span>
            </div>
          ) : (
            <div className={styles.dateWrap}>
              <span>현재 진행 중인 다이어트 프로그램이 없습니다.</span>
            </div>
          )}
        </div>

        <div
          className={`${styles.programStatusWrap} ${
            programList[0] ? styles.hit : ''
          }`}
          onClick={() => {
            if (!programList[0]) return navigate('/productDetail/136');
            if (programList[0].cycle_type === 'Month')
              navigate('/subscribe-detail');
            else navigate('/dietprogram');
          }}
        >
          <div className={styles.leftWrap}>
            <div className={styles.imgWrap}>
              {programList[0]?.cycle_type === 'Month' ? (
                <img src={programLogo4} alt="logo" />
              ) : programList[0]?.option_name.includes('2주') ||
                programList[0]?.option_name.includes('4주') ? (
                <img src={programLogo2} alt="logo" />
              ) : programList[0]?.option_name.includes('3개월') ? (
                <img src={programLogo3} alt="logo" />
              ) : (
                <img src={programLogo1} alt="logo" />
              )}
            </div>
            <div
              className={`${styles.contentWrap} ${
                programList[0] ? styles.start : ''
              }`}
            >
              {programList[0] ? (
                <>
                  <span>
                    {/* {programList[0].cycle_type === 'Month'
                      ? programList[0]?.option_name
                      : programList[0]?.option_name?.split('/')[0].trim()} */}
                    {programList[0]?.cycle_type === 'Month'
                      ? '[정기배송] ' +
                        programList[0]?.option_name
                          ?.split('/')[1]
                          .trim()
                          .replace(/\s*\(.*$/, '')
                      : programList[0]?.option_name?.split('/')[0].trim()}
                  </span>
                  <span>을 진행하고 있어요.</span>
                </>
              ) : (
                <>
                  <span>다이어트 프로그램</span>
                  <span>진행하러 가기</span>
                </>
              )}
            </div>
          </div>
          <div className={styles.rightWrap}>
            {programList[0] ? (
              <img src={right2} alt="direct" />
            ) : (
              <img src={right1} alt="direct" />
            )}
          </div>
        </div>
      </div>
      <div
        className={`${styles.challengeWrap} ${isMobile ? styles.isMobile : ''}`}
      >
        {isDesktop && (
          <div className={styles.title}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <circle cx="9" cy="9" r="7" stroke="black" />
              <path d="M9 5V9H13" stroke="black" strokeLinecap="square" />
            </svg>
            <span>{todayDate}</span>
            <h2>체중 감량 챌린지</h2>
          </div>
        )}
        {isMobile && (
          <div className={`${styles.title} ${isMobile ? styles.isMobile : ''}`}>
            <span>체중 감량 챌린지</span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <circle cx="9" cy="9" r="7" stroke="black" />
                <path d="M9 5V9H13" stroke="black" strokeLinecap="square" />
              </svg>
              {todayDate}
            </span>
          </div>
        )}

        <div
          className={`${styles.weightForm} ${isMobile ? styles.isMobile : ''}`}
        >
          <form onSubmit={onSubmit}>
            <div className={styles.weightInputWrap}>
              {challengeParticipation === 'Y' ? (
                <input
                  value={''}
                  placeholder="체중을 입력해주세요   000.00"
                  disabled
                  style={{
                    background: '#fff',
                  }}
                />
              ) : (
                <input
                  type="text"
                  name="weight"
                  value={inputWeight}
                  onChange={onChangeWeight}
                  onKeyUp={onKeyUpWeight}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  placeholder="체중을 입력해주세요   000.00"
                />
              )}
              <span>kg</span>
            </div>
            {challengeParticipation === 'Y' ? (
              <button
                type="button"
                className={styles.unableBtn}
                disabled={false}
              >
                입력하기
              </button>
            ) : (
              <button type="submit" className={styles.inputBtn}>
                입력하기
              </button>
            )}
          </form>
          {/* <div className={styles.inputCheck}>{submitMsg}</div> */}
          {challengeParticipation === 'Y' && isMobile && (
            <div
              className={`${styles.bannerWrap} ${
                isMobile ? styles.isMobile : styles.isDesktop
              }`}
            >
              <div className={styles.bannerTextOk}>
                <p>이번 주</p>
                <span>체중이 입력 완료</span>
                <span>됐습니다.</span>
              </div>
              <div className={styles.bannerTextDate}>
                <span>다음 입력 기간: </span>
                <span>
                  {challengeNextStartDay} ~ {challengeNextEndDay}
                </span>
              </div>
              <div className={styles.bannerImg}>
                <img src={bannerScaleImg} alt="weightInputOk" />
              </div>
            </div>
          )}
          {challengeParticipation === 'Y' && isDesktop && (
            <div
              className={`${styles.bannerWrap} ${
                isMobile ? styles.isMobile : styles.isDesktop
              }`}
            >
              <div className={styles.bannerTextOk}>
                <span>이번 주 </span>
                <span>체중이 입력 완료</span>
                <span>됐습니다.</span>
              </div>
              <div className={styles.bannerTextDate}>
                <span>다음 입력 기간: </span>
                <span>
                  {challengeNextStartDay} ~ {challengeNextEndDay}
                </span>
              </div>
              <div className={styles.bannerImg}>
                <img src={bannerScaleImg} alt="weightInputOk" />
              </div>
            </div>
          )}
          <div
            className={styles.challengeDetailDirectWrap}
            onClick={() => navigate('/event/22')}
          >
            <span>체중 감량 챌린지 자세히보기</span>
            <img src={challengeDetailDirectBtn} alt="direct" />
          </div>
        </div>
      </div>
      <div className={styles.orderStatus}>
        <h3>진행중인 주문</h3>
        <div className={styles.statusList}>
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.결제대기 > 0
                ? styles.hit
                : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.결제대기}</span>
            <span>결제대기</span>
          </div>
          <img src={rightArrow3} alt="rightArrow" />
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.결제완료 > 0
                ? styles.hit
                : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.결제완료}</span>
            <span>결제완료</span>
          </div>
          <img src={rightArrow3} alt="rightArrow" />
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.배송준비 > 0
                ? styles.hit
                : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.배송준비}</span>
            <span>한약조제중</span>
          </div>
          <img src={rightArrow3} alt="rightArrow" />
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.배송중 > 0 ? styles.hit : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.배송중}</span>
            <span>배송중</span>
          </div>
          <img src={rightArrow3} alt="rightArrow" />
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.배송완료 > 0
                ? styles.hit
                : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.배송완료}</span>
            <span>배송완료</span>
          </div>
        </div>
        <p className={styles.orderGuide}>
          진행중인 주문 주문 및 배송 사이에 약재 수급에 따라 진료 완료일로부터
          2~3일정도 소요될 수 있습니다.
        </p>
      </div>
      <div className={styles.userOrderWrap}>
        <h3>주문 및 배송</h3>
        <ul className={styles.orderListWrap}>
          {orderData?.map((order) => (
            <li key={order.order_id}>
              <OrderFrame page={'myPage'} orderData={order} />
            </li>
          ))}
        </ul>
        <div
          className={styles.moreOrderBtn}
          onClick={() => navigate('/myOrder')}
        >
          주문내역 더보기
        </div>
      </div>
      <div
        className={`${styles.subscribeWrap} ${
          isDesktop ? styles.isDesktop : ''
        }`}
      >
        <div className={styles.titleWrap}>정기 구독</div>
        <div
          className={`${styles.contentWrap} ${
            subscribeList?.cycle_type === 'Month' ? styles.hit : ''
          }`}
        >
          {subscribeList?.cycle_type === 'Month' ? (
            <>
              <span>
                {'[정기배송] ' +
                  subscribeList?.option_name
                    ?.split('/')[1]
                    .trim()
                    .replace(/\s*\(.*$/, '')}
              </span>
              <div
                className={styles.priceWrap}
                onClick={() => {
                  navigate('/subscribe-detail');
                }}
              >
                <span>상세보기</span>
                <img src={right3} alt="direct" />
              </div>
            </>
          ) : (
            <span>현재 구독 중인 프로그램이 없습니다.</span>
          )}
        </div>
      </div>
      <div className={styles.event} onClick={() => navigate('/event')}>
        <span>이벤트</span>
        <img src={right3} alt="arrow" />
      </div>
      <div className={styles.faq} onClick={() => navigate('/faq')}>
        <span>FAQ</span>
        <img src={right3} alt="arrow" />
      </div>
      {challengeSubmitModalOpen && (
        <ChallengeSubmitModal closer={setChallengeSubmitModalOpen} />
      )}
      <Footer />
    </div>
  );
}
export default MyPage;
