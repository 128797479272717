import styles from './DietAlert.module.scss';
import Header from '../../components/Header/Header';
import product from '../../assets/dietProgram/product.png';
import lunch from '../../assets/dietProgram/lunch.png';
import dinner from '../../assets/dietProgram/dinner.png';
import tablet from '../../assets/dietProgram/tablet.png';
import gambi from '../../assets/dietProgram/gambi.png';
import salad from '../../assets/dietProgram/salad.png';
import bium from '../../assets/dietProgram/bium.png';
import ccaSalad from '../../assets/dietProgram/ccaSalad.png';
import cca from '../../assets/dietProgram/cca.png';
import { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import axiosClient from '../../libs/axiosClient';
import { useNavigate } from 'react-router-dom';

function DietAlert() {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [cca1, setCca1] = useState('08');
  const [cca2, setCca2] = useState('00');
  const [ccaOk, setCcaOk] = useState(false);
  const [tablet1, setTablet1] = useState('10');
  const [tablet2, setTablet2] = useState('00');
  const [tabletOk, setTabletOk] = useState(false);
  const [gambi1, setGambi1] = useState('13');
  const [gambi2, setGambi2] = useState('00');
  const [gambiOk, setGambiOk] = useState(false);
  const [tablet3, setTablet3] = useState('16');
  const [tablet4, setTablet4] = useState('00');
  const [tabletOk2, setTabletOk2] = useState(false);
  const [salad1, setSalad1] = useState('17');
  const [salad2, setSalad2] = useState('00');
  const [saladOk, setSaladOk] = useState(false);
  const [bium1, setBium1] = useState('19');
  const [bium2, setBium2] = useState('00');
  const [biumOk, setBiumOk] = useState(false);
  const [ccaOrSalad1, setCcaOrSalad1] = useState('21');
  const [ccaOrSalad2, setCcaOrSalad2] = useState('00');
  const [ccaOrSaladOk, setCcaOrSaladOk] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('mobileApp')) {
      navigate('/');
      return alert(
        '온닥 APP에서만 접근 가능한 페이지입니다. APP을 설치하고 복용 알림 시간을 설정해 주세요',
      );
    }
    window.flutter_inappwebview
      .callHandler('getDietAlert')
      .then((res: any) => {
        const result = res.data.data;
        setCca1(result.cca1);
        setCca2(result.cca2);
        setTablet1(result.tablet1);
        setTablet2(result.tablet2);
        setGambi1(result.gambi1);
        setGambi2(result.gambi2);
        setTablet3(result.tablet3);
        setTablet4(result.tablet4);
        setSalad1(result.salad1);
        setSalad2(result.salad2);
        setBium1(result.bium1);
        setBium2(result.bium2);
        setCcaOrSalad1(result.ccaOrSalad1);
        setCcaOrSalad2(result.ccaOrSalad2);
      })
      .catch((err: any) => {});
  }, []);

  useEffect(() => {
    if (cca1 && cca2) setCcaOk(true);
    else setCcaOk(false);

    if (tablet1 && tablet2) setTabletOk(true);
    else setTabletOk(false);

    if (gambi1 && gambi2) setGambiOk(true);
    else setGambiOk(false);

    if (tablet3 && tablet4) setTabletOk2(true);
    else setTabletOk2(false);

    if (salad1 && salad2) setSaladOk(true);
    else setSaladOk(false);

    if (bium1 && bium2) setBiumOk(true);
    else setBiumOk(false);

    if (ccaOrSalad1 && ccaOrSalad2) setCcaOrSaladOk(true);
    else setCcaOrSaladOk(false);
  }, [
    cca1,
    cca2,
    tablet1,
    tablet2,
    gambi1,
    gambi2,
    tablet3,
    tablet4,
    salad1,
    salad2,
    bium1,
    bium2,
    ccaOrSalad1,
    ccaOrSalad2,
  ]);

  useEffect(() => {
    axiosClient
      .get('/data/subscription/alarm')
      .then((res) => {
        const result = res.data.data;
        if (!result) return;
        setCca1(result?.cca1?.hour);
        setCca2(result?.cca1?.minute);
        setTablet1(result?.tablet1?.hour);
        setTablet2(result?.tablet1?.minute);
        setGambi1(result?.gambi_bium?.hour);
        setGambi2(result?.gambi_bium?.minute);
        setTablet3(result?.tablet2?.hour);
        setTablet4(result?.tablet2?.minute);
        setSalad1(result?.salad1?.hour);
        setSalad2(result?.salad1?.minute);
        setBium1(result?.bium1?.hour);
        setBium2(result?.bium1?.minute);
        setCcaOrSalad1(result?.cca_salad1?.hour);
        setCcaOrSalad2(result?.cca_salad1?.minute);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const formatValue = (value: string, max: number) => {
    let formatted = value.replace(/\D/g, '');
    const num = parseInt(formatted, 10);
    return num > max ? max.toString().padStart(2, '0') : formatted;
  };

  const handleBlur = (value: string, setValue: any, max: number) => {
    let formatted = value;
    if (formatted.length === 1) {
      formatted = '0' + formatted;
    }
    const num = parseInt(formatted, 10);
    formatted =
      num > max ? max.toString().padStart(2, '0') : formatted.padStart(2, '0');
    setValue(formatted);
  };

  useEffect(() => {
    // const isChanged =
    //   cca1 !== '08' ||
    //   cca2 !== '00' ||
    //   tablet1 !== '10' ||
    //   tablet2 !== '00' ||
    //   gambi1 !== '13' ||
    //   gambi2 !== '00' ||
    //   tablet3 !== '16' ||
    //   tablet4 !== '00' ||
    //   salad1 !== '17' ||
    //   salad2 !== '00' ||
    //   bium1 !== '19' ||
    //   bium2 !== '00' ||
    //   ccaOrSalad1 !== '21' ||
    //   ccaOrSalad2 !== '00';

    const isNotEmpty =
      cca1 !== '' &&
      cca2 !== '' &&
      tablet1 !== '' &&
      tablet2 !== '' &&
      gambi1 !== '' &&
      gambi2 !== '' &&
      tablet3 !== '' &&
      tablet4 !== '' &&
      salad1 !== '' &&
      salad2 !== '' &&
      bium1 !== '' &&
      bium2 !== '' &&
      ccaOrSalad1 !== '' &&
      ccaOrSalad2 !== '';

    // setIsSubmit(isChanged && isNotEmpty);
    setIsSubmit(isNotEmpty);
  }, [
    cca1,
    cca2,
    tablet1,
    tablet2,
    gambi1,
    gambi2,
    tablet3,
    tablet4,
    salad1,
    salad2,
    bium1,
    bium2,
    ccaOrSalad1,
    ccaOrSalad2,
  ]);

  const getValidate = () => {};

  const onSubmit = () => {
    if (
      !ccaOk &&
      !tabletOk &&
      !gambiOk &&
      !tabletOk2 &&
      !saladOk &&
      !biumOk &&
      !ccaOrSaladOk
    )
      return;

    let obj = {
      cca1: {
        hour: cca1,
        minute: cca2,
      },
      tablet1: {
        hour: tablet1,
        minute: tablet2,
      },
      tablet2: {
        hour: tablet3,
        minute: tablet4,
      },
      gambi_bium: {
        hour: gambi1,
        minute: gambi2,
      },
      salad1: {
        hour: salad1,
        minute: salad2,
      },
      bium1: {
        hour: bium1,
        minute: bium2,
      },
      cca_salad1: {
        hour: ccaOrSalad1,
        minute: ccaOrSalad2,
      },
    };

    axiosClient
      .post('/func/subscription/update/alarm', obj)
      // axiosClient
      //   .post(`/func/subscription/update/alarm?cca1=22&cca2=11`, obj)
      .then((res) => {
        // 00 00으로 설정된 경우에는 알림 비활성화
        if (res.data.data.message)
          alert('복용 알림 시간이 설정 완료되었습니다.');
        // 앱에 알림 요청
        window.flutter_inappwebview
          .callHandler('dietAlert')
          .then((res: any) => {
            return res;
          })
          .catch((err: any) => {
            return err;
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Header />
      <div
        className={`${styles.contentWrap} ${isDesktop ? styles.isDesktop : ''}`}
      >
        <div className={styles.titleWrap}>
          <span>제품별 섭취 및 복용 </span>
          {!isDesktop && <br />}
          <span>알림 시간 설정</span>
        </div>
        <div className={styles.listWrap}>
          <div
            className={`${styles.listFrame} ${
              cca1 === '00' && cca2 === '00' ? styles.noFrame : ''
            }`}
          >
            <div className={styles.leftWrap}>
              <img src={cca} alt="product" />
              <div className={styles.textWrap}>
                <span>CCA 주스</span>
                <span>아침 식사 대용으로, 출근 전에 드시는 것이 좋습니다.</span>
              </div>
            </div>
            <div className={styles.rightWrap}>
              <input
                maxLength={2}
                placeholder="00"
                value={cca1}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 23);
                  setCca1(value);
                }}
                onBlur={() => handleBlur(cca1, setCca1, 23)}
              />
              <span>:</span>
              <input
                maxLength={2}
                placeholder="00"
                value={cca2}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 59);
                  setCca2(value);
                }}
                onBlur={() => handleBlur(cca2, setCca2, 59)}
              />
            </div>
          </div>
          <div
            className={`${styles.listFrame} ${
              tablet1 === '00' && tablet2 === '00' ? styles.noFrame : ''
            }`}
          >
            <div className={styles.leftWrap}>
              <img src={tablet} alt="product" />
              <div className={styles.textWrap}>
                <span>다이어트 타블렛</span>
                <span>식욕 억제를 위해 점심 식전에 드시는 것이 좋습니다.</span>
              </div>
            </div>
            <div className={styles.rightWrap}>
              <input
                maxLength={2}
                placeholder="00"
                value={tablet1}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 23);
                  setTablet1(value);
                }}
                onBlur={() => handleBlur(tablet1, setTablet1, 23)}
              />
              <span>:</span>
              <input
                maxLength={2}
                placeholder="00"
                value={tablet2}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 59);
                  setTablet2(value);
                }}
                onBlur={() => handleBlur(tablet2, setTablet2, 59)}
              />
            </div>
          </div>
          <div className={styles.eatWrap}>
            <img src={lunch} alt="food" />
            <div>
              <span>점심 시간</span>
              <span>단백질과 채소 위주 저탄수 식단</span>
            </div>
          </div>
          <div
            className={`${styles.listFrame} ${
              gambi1 === '00' && gambi2 === '00' ? styles.noFrame : ''
            }`}
          >
            <div className={styles.leftWrap}>
              <img src={gambi} alt="product" />
              <div className={styles.textWrap}>
                <span>감비차 + 비움환</span>
                <span>점심 식후에 가볍게 비워내는 것이 좋습니다.</span>
              </div>
            </div>
            <div className={styles.rightWrap}>
              <input
                maxLength={2}
                placeholder="00"
                value={gambi1}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 23);
                  setGambi1(value);
                }}
                onBlur={() => handleBlur(gambi1, setGambi1, 23)}
              />
              <span>:</span>
              <input
                maxLength={2}
                placeholder="00"
                value={gambi2}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 59);
                  setGambi2(value);
                }}
                onBlur={() => handleBlur(gambi2, setGambi2, 59)}
              />
            </div>
          </div>
          <div
            className={`${styles.listFrame} ${
              tablet3 === '00' && tablet4 === '00' ? styles.noFrame : ''
            }`}
          >
            <div className={styles.leftWrap}>
              <img src={tablet} alt="product" />
              <div className={styles.textWrap}>
                <span>다이어트 타블렛</span>
                <span>식욕 억제를 위해 저녁 식전에 드시는 것이 좋습니다.</span>
              </div>
            </div>
            <div className={styles.rightWrap}>
              <input
                maxLength={2}
                placeholder="00"
                value={tablet3}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 23);
                  setTablet3(value);
                }}
                onBlur={() => handleBlur(tablet3, setTablet3, 23)}
              />
              <span>:</span>
              <input
                maxLength={2}
                placeholder="00"
                value={tablet4}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 59);
                  setTablet4(value);
                }}
                onBlur={() => handleBlur(tablet4, setTablet4, 59)}
              />
            </div>
          </div>
          <div
            className={`${styles.listFrame} ${
              salad1 === '00' && salad2 === '00' ? styles.noFrame : ''
            }`}
          >
            <div className={styles.leftWrap}>
              <img src={salad} alt="product" />
              <div className={styles.textWrap}>
                <span>샐러드 클렌즈 주스</span>
                <span>저녁 식전에 혈당스파이크 방지에 좋습니다.</span>
              </div>
            </div>
            <div className={styles.rightWrap}>
              <input
                maxLength={2}
                placeholder="00"
                value={salad1}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 23);
                  setSalad1(value);
                }}
                onBlur={() => handleBlur(salad1, setSalad1, 23)}
              />
              <span>:</span>
              <input
                maxLength={2}
                placeholder="00"
                value={salad2}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 59);
                  setSalad2(value);
                }}
                onBlur={() => handleBlur(salad2, setSalad2, 59)}
              />
            </div>
          </div>
          <div className={styles.eatWrap}>
            <img src={dinner} alt="food" />
            <div>
              <span>저녁 시간</span>
              <span>단백질과 채소 위주 저탄수 식단</span>
            </div>
          </div>
          <div
            className={`${styles.listFrame} ${
              bium1 === '00' && bium2 === '00' ? styles.noFrame : ''
            }`}
          >
            <div className={styles.leftWrap}>
              <img src={bium} alt="product" />
              <div className={styles.textWrap}>
                <span>비움환</span>
                <span>저녁 식후에 배변활동과 노폐물 제거에 좋습니다.</span>
              </div>
            </div>
            <div className={styles.rightWrap}>
              <input
                maxLength={2}
                placeholder="00"
                value={bium1}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 23);
                  setBium1(value);
                }}
                onBlur={() => handleBlur(bium1, setBium1, 23)}
              />
              <span>:</span>
              <input
                maxLength={2}
                placeholder="00"
                value={bium2}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 59);
                  setBium2(value);
                }}
                onBlur={() => handleBlur(bium2, setBium2, 59)}
              />
            </div>
          </div>
          <div
            className={`${styles.listFrame} ${
              ccaOrSalad1 === '00' && ccaOrSalad2 === '00' ? styles.noFrame : ''
            }`}
          >
            <div className={styles.leftWrap}>
              <img src={ccaSalad} alt="product" />
              <div className={styles.textWrap}>
                <span>CCA 주스 또는 샐러드 클렌즈 주스</span>
                <span>잠들기 전에 독소 배출 용으로 드시는 것이 좋습니다.</span>
              </div>
            </div>
            <div className={styles.rightWrap}>
              <input
                maxLength={2}
                placeholder="00"
                value={ccaOrSalad1}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 23);
                  setCcaOrSalad1(value);
                }}
                onBlur={() => handleBlur(ccaOrSalad1, setCcaOrSalad1, 23)}
              />
              <span>:</span>
              <input
                maxLength={2}
                placeholder="00"
                value={ccaOrSalad2}
                onChange={(e) => {
                  const value = formatValue(e.target.value, 59);
                  setCcaOrSalad2(value);
                }}
                onBlur={() => handleBlur(ccaOrSalad2, setCcaOrSalad2, 59)}
              />
            </div>
          </div>
        </div>
        <div
          className={`${styles.btnWrap} ${isSubmit ? '' : styles.noBtnWrap}`}
        >
          <div
            className={styles.btn}
            onClick={() => {
              if (!isSubmit) return;
              onSubmit();
            }}
          >
            설정 완료
          </div>
        </div>
      </div>
    </div>
  );
}

export default DietAlert;
