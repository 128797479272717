import styles from './Home.module.scss';
import Header from '../../components/Header/Header';
import HeaderMain from '../../components/HeaderMain/HeaderMain';
import MainBanner from '../../components/MainBanner/MainBanner';
import rightArrow from '../../assets/rightArrow.png';
import processImage01 from '../../assets/processImage01.png';
import processImage02 from '../../assets/processImage02.png';
import processImage03 from '../../assets/processImage03.png';
import star from '../../assets/star.png';
import review from '../../assets/review.png';
import blackheart from '../../assets/home/heart.png';
import basket from '../../assets/home/basket.png';
import heart from '../../assets/home/heart.png';
import dietbannerImg from '../../assets/home/dietbannerImg.png';
import ondocGlobal from '../../assets/home/ondocGlobal.png';
import ondocLegit from '../../assets/home/ondocLegit.png';
import ondocInfo1 from '../../assets/home/ondocInfo1.png';
import ondocInfo2 from '../../assets/home/ondocInfo2.png';
import ondocInfo3 from '../../assets/home/ondocInfo3.png';
import country01 from '../../assets/country/country1.png';
import country02 from '../../assets/country/country2.png';
import country03 from '../../assets/country/country3.png';
import country04 from '../../assets/country/country4.png';
import leesoyoungPc from '../../assets/doctors/leesoyoungPc.png';
import youjiyouPc from '../../assets/doctors/youjiyouPc.png';
import kimmiriPc from '../../assets/doctors/kimmiriPc.png';
import banner02 from '../../assets/bannerImage02.png';
import america from '../../assets/america.png';
import MainReview from '../../components/MainReview/MainReview';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Footer from '../../components/Footer/Footer';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { IProductListType } from '../ProductList/ProductList';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import MainFaqFrame from '../../components/MainFaqFrame/MainFaqFrame';
import process01 from '../../assets/process01.png';
import process02 from '../../assets/process02.png';
import process03 from '../../assets/process03.png';
import process04 from '../../assets/process04.png';
import mainFaqData from '../../assets/data/mainFaq.json';
import { useDispatch, useSelector } from 'react-redux';
import LazyLoad from 'react-lazy-load';
import ADLink from '../../components/ADLink/ADLink';
import challengeBanner from '../../assets/home/challengeBanner.jpg';
import getChallengeData from '../../common/getChallengeData';
import ChallengeFrame from '../../components/ChallengeFrame/ChallengeFrame';
import Popup from '../../components/Popup/Popup';
import { useCookies } from 'react-cookie';
import { IOptionType } from '../ProductDetail/ProductDetail';
export interface IAdLinkDataType {
  id: number;
  price: number;
  sale_price: number;
}
const countryList = [country01, country02, country03, country04];
const mainFaqList = mainFaqData;
function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  //프로세스
  const [processSliderIdx, setProcessSliderIdx] = useState(0);
  //옵션
  const [firstOptionList, setFirstOptionList] = useState<IOptionType[]>();
  const [programPrice, setProgramPrice] = useState([
    {
      price: 512000,
      salePrice: 380000,
    },
    {
      price: 210000,
      salePrice: 0,
      isMonthly: true,
    },
    {
      price: 72000,
      salePrice: 0,
    },
    {
      price: '72,000원/월',
      salePrice: 0,
    },
  ]);

  useEffect(() => {
    axiosClient.get(`/data/product/detail/136/options`).then((res) => {
      setFirstOptionList(res.data.data);
    });
  }, []);

  //상품
  const { data: productList } = useQuery<IProductListType[]>(
    ['productList', 'all'],
    async () => {
      let queryString = '';
      let ids = JSON.parse(
        sessionStorage.getItem('product_ids') || JSON.stringify([]),
      );
      if (ids.length) {
        queryString = 'add_products=';
        ids.map((id: number, idx: number) => {
          queryString += `${id}${idx !== ids.length - 1 ? ',' : ''}`;
        });
      }
      return await axiosClient
        .get(`/data/product/list?${queryString}`)
        .then((res) => res.data.data);
    },
    {
      staleTime: 60000 * 60 * 24,
    },
  );
  //상품
  //faq
  // const { data: faqListQuery } = useQuery<{
  //   data: IFaqType[];
  //   faq_type: {
  //     [key: number]: string;
  //   };
  // }>('faq', () => axiosClient.get('/data/faq').then((res) => res.data.data), {
  //   staleTime: 60000 * 60 * 24, //1일 캐싱
  // });
  //faq

  //기존 상품 url접속시 대응
  const gaInitialize = useSelector((state: any) => state.gaInitialize);
  const dispatch = useDispatch();
  useEffect(() => {
    let pn = new URLSearchParams(location.search).get('pn');
    if (!pn) return;
    if (pn == 'product.view') {
      axiosClient
        .get(
          `/data/product/get_new_url?is_blind=N&code=${new URLSearchParams(
            location.search,
          ).get('pcode')}`,
        )
        .then((res) => {
          if (res.data.data.message) alert(res.data.data.message);
          navigate(res.data.data.url);
        });
    } else if (pn == 'blind_gate') {
      axiosClient
        .get(
          `/data/product/get_new_url?is_blind=Y&code=${new URLSearchParams(
            location.search,
          ).get('bc')}`,
        )
        .then((res) => {
          if (res.data.data.message) alert(res.data.data.message);
          navigate(res.data.data.url);
        });
    }
  }, [gaInitialize]);
  //기존 상품 url접속시 대응
  const [doctorSliderIdx, setDoctorSliderIdx] = useState(0);
  // useEffect(() => {
  //   axiosClient.get('/data/banner').then((res) => console.log(res));
  // }, []);
  const [adLinkOpen, setAdLinkOpen] = useState(true);
  const [adLinkData, setAdLinkData] = useState<IAdLinkDataType>();
  // useEffect(() => {
  //   axiosClient.get('/data/product/promotional_product').then((res) => {
  //     if (!Object.keys(res.data.data).length) return;
  //     setAdLinkData(res.data.data);
  //   });
  // }, []);

  const [hasCookie, setHasCookie] = useState(true);
  const [cookies, setCookies] = useCookies();

  useEffect(() => {
    if (cookies['week-close']) setHasCookie(true);
    else setHasCookie(false);
  }, []);

  return (
    <>
      {adLinkOpen && adLinkData && (
        <ADLink
          productId={adLinkData.id}
          price={adLinkData.price}
          discountedPrice={adLinkData.sale_price}
          closer={setAdLinkOpen}
        />
      )}
      {/* {!hasCookie && <Popup />} */}
      <div className={styles.container}>
        <HeaderMain />
        {/* <Header /> */}
        <MainBanner />
        {/* <div className={styles.processWrap}>
          <h3>비대면 처방 프로세스</h3>
          <p>빠르고 안전한, 쉽고 편리한 진료 서비스</p>
          <ul className={styles.hashtag}>
            <li># 비대면서비스</li>
            <li># 가벼운 증상부터 만성질환까지</li>
            <li># 믿고받는진료</li>
          </ul>
          <div className={styles.processSliderWrap}>
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              className={styles.processSlider}
              onSlideChange={(e) => setProcessSliderIdx(e.activeIndex)}
            >
              <SwiperSlide className={styles.processSlide}>
                <img src={process01} alt="process" />
                <div className={styles.stage}>
                  <h4>문진표 작성</h4>
                  <p>제품 선택 후 문진표 작성</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.processSlide}>
                <img src={process02} alt="process" />
                <div className={styles.stage}>
                  <h4>결제 및 접수</h4>
                  <p>결제 완료 후 1-3일 이내 담당의사 접수</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.processSlide}>
                <img src={process03} alt="process" />
                <div className={styles.stage}>
                  <h4>전화처방</h4>
                  <p>담당 의사로부터 전화 진료 시작</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.processSlide}>
                <img src={process04} alt="process" />
                <div className={styles.stage}>
                  <h4>당일발송</h4>
                  <p>오후 12시 이내 처방 완료건 당일 배송</p>
                </div>
              </SwiperSlide>
            </Swiper>
            <ul className={styles.processSliderPagination}>
              {(() => {
                let list = [0, 1, 2, 3];
                return list.map((val) => (
                  <li
                    key={val}
                    className={processSliderIdx == val ? styles.hit : ''}
                  >
                    <div></div>
                  </li>
                ));
              })()}
            </ul>
          </div>
        </div> */}
        {/* <div className={styles.worldPlatform}>
          <h3>
            온닥은 해외 원격 진료를 통해
            <br />
            해외 각국에 한약을 처방하고 있습니다.
          </h3>
          <ul>
            {countryList.map((country, idx) => (
              <li key={idx}>
                <img src={country} />
              </li>
            ))}
          </ul>
          <p>
            미국, 태국, 호주, 아랍 등 총 6개 국가 해외 원격 진료 서비스 지원
          </p>
        </div> */}
        {/* <div className={styles.prescriptionProductWrap}>
          <h3 onClick={() => navigate(`/productList?cuid=487`)}>
            <span>처방의약품</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <span>온닥 비대면 처방의약품</span>
          <Swiper
            slidesPerView={2.3}
            spaceBetween={10}
            className={styles.prescriptionProductSlider}
          >
            {productList
              ?.filter((product) => product.is_prescription == 1)
              .map((product) => (
                <SwiperSlide
                  key={product.product_id}
                  className={styles.prescriptionProductSlide}
                  onClick={() =>
                    navigate(`/productDetail/${product.product_id}`)
                  }
                >
                  <div className={styles.thumbnailWrap}>
                    <img
                      src={product.list_image + '?w=530'}
                      alt="productThumbnail"
                    />
                  </div>
                  <div className={styles.productInfoWrap}>
                    <h3>{product.title}</h3>
                    <ul className={styles.hashtagList}>
                      {product.hashtag &&
                        product.hashtag
                          .split(',')
                          .map((hashtag) => <li># {hashtag}</li>)}
                    </ul>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div> */}
        {/* <section className={styles.challengeWrap}>
          <div className={styles.sectionBanner}></div>
          <div className={styles.sectionContent}>
            <h2>온닥 챌린지</h2>
            <p>다양한 챌린지 참여하고 혜택 받자!</p>
            <Swiper slidesPerView={2.5} spaceBetween={10}>
              {getChallengeData().map((challenge, idx) => (
                <SwiperSlide key={idx}>
                  <ChallengeFrame
                    challenge={challenge}
                    width={333}
                    height={400}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <button onClick={() => navigate('/challenge')}>
              모든 챌린지 보기
            </button>
          </div>
        </section> */}
        <div className={styles.normalProductWrap}>
          <h3 onClick={() => navigate(`/productList`)}>
            <span>건강 식품</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <Swiper slidesPerView={2.5} spaceBetween={20}>
            {productList &&
              productList
                ?.filter((product) => product.is_prescription === 0)
                .map((product, idx) => (
                  <SwiperSlide key={idx}>
                    <div
                      className={styles.productSlideWrap}
                      onClick={() =>
                        navigate(`/productDetail/${product.product_id}`)
                      }
                    >
                      <div className={styles.productThumbnailWrap}>
                        <img
                          src={product.list_image + '?w=530'}
                          alt="productThumbnail"
                        />
                      </div>
                      <div className={styles.productInfoWrap}>
                        <div className={styles.productInfoLeft}>
                          <p>{product.title}</p>
                          <div className={styles.productInfoPrice}>
                            <span>
                              {product.discount_price.toLocaleString()}원
                            </span>
                            {product.price !== 0 && (
                              <span>{product.price.toLocaleString()}원</span>
                            )}
                          </div>
                          <div className={styles.productInfoScore}>
                            <img src={review} alt="review" />
                            <span>
                              {/* {product.review_score_avg
                                ? Math.floor(
                                    (product.review_score_avg
                                      .review_point_total_avg /
                                      2) *
                                      10,
                                  ) / 10
                                : 0} */}
                              {product.review_score_avg?.review_total_count.toLocaleString()}
                            </span>
                          </div>
                          {/* {props.allReviewData
                    ? Math.floor(
                        (props.allReviewData.review_point_total_avg / 2) * 10,
                      ) / 10
                    : 0} */}
                        </div>
                        <div className={styles.productInfoRight}></div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
          </Swiper>
          {/* <ul className={styles.normalProductList}>
            {productList
              ?.filter((product) => product.is_prescription == 0)
              .filter((product, idx) => idx == 0)
              .map((product) => (
                <li
                  key={product.product_id}
                  onClick={() =>
                    navigate(`/productDetail/${product.product_id}`)
                  }
                >
                  <div className={styles.productThumbnailWrap}>
                    <img
                      src={product.list_image + '?w=530'}
                      alt="productThumbnail"
                    />
                  </div>
                  <div className={styles.productInfoWrap}>
                    <h3>{product.title}</h3>
                    <ul className={styles.hashtagList}>
                      {product.hashtag &&
                        product.hashtag
                          .split(',')
                          .map((hashtag, idx) => <li key={idx}>{hashtag}</li>)}
                    </ul>
                  </div>
                </li>
              ))}
          </ul> */}
        </div>
        {/* <div className={styles.allProductList}>
          <h3>
            <span>ONDOC PRODUCT</span>
          </h3>
          <span>내원없이 간편하게 받자!</span>
          <ul className={styles.productListWrap}>
            {productList
              ?.filter((product, idx) => idx < 4)
              .map((product) => (
                <li
                  key={product.product_id}
                  onClick={() =>
                    navigate(`/productDetail/${product.product_id}`)
                  }
                >
                  <div className={styles.productThumbnailWrap}>
                    <img
                      src={product.list_image + '?w=530'}
                      alt="productThumbnail"
                    />
                  </div>
                  <div className={styles.productInfoWrap}>
                    <h3>{product.title}</h3>
                    {product.discount_rate !== 0 && (
                      <span className={styles.discountRate}>
                        {product.discount_rate}%
                      </span>
                    )}
                    <span className={styles.price}>
                      {product.discount_price.toLocaleString()}원
                    </span>
                    <div className={styles.reviewScore}>
                      <img src={star} alt="star" />
                      <span>
                        {Math.floor(
                          (product.review_score_avg.review_point_total_avg /
                            2) *
                            10,
                        ) / 10}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          {productList && productList.length > 4 && (
            <button onClick={() => navigate(`/productList`)}>
              모든 제품 보기
            </button>
          )}
        </div> */}
        <div className={styles.dietBannerWrap}>
          <p>날씬해지는 습관</p>
          <p>온닥이 다이어트를 도와드립니다.</p>
          <p>
            유명 한의원의 다이어트 프로그램으로 확실한 다이어트를 보장합니다!
          </p>
          <img src={dietbannerImg} alt="dietbannerImg" />
        </div>
        <div className={styles.dietProgramWrap}>
          <div className={styles.titleWrap}>
            <span>온닥 다이어트 프로그램</span>
          </div>
          <div className={styles.dietSwiperWrap}>
            <Swiper
              slidesPerView={1.5}
              spaceBetween={10}
              slidesOffsetAfter={21}
              className={styles.reviewSlider}
              breakpoints={{ 700: { slidesPerView: 2.8 } }}
            >
              {firstOptionList &&
                firstOptionList.map((item, idx) => (
                  <SwiperSlide>
                    <div
                      className={styles.dietSliderWrap}
                      onClick={() => {
                        navigate('/productDetail/136', {
                          state: {
                            programId: idx,
                          },
                        });
                      }}
                    >
                      <div className={styles.dietImgWrap}>
                        <img
                          src={`https://img.ondoc.co.kr/upfiles/options/option${
                            idx + 1
                          }.png`}
                          alt="program"
                        />
                      </div>
                      <div className={styles.dietContentWrap}>
                        <span>{item.po_poptionname}</span>
                        <div className={styles.priceWrap}>
                          <span>
                            {programPrice[idx].salePrice
                              ? programPrice[idx].salePrice.toLocaleString()
                              : programPrice[idx].price.toLocaleString()}
                            원{programPrice[idx].isMonthly && '~/월'}
                          </span>
                          <span>
                            {programPrice[idx].salePrice !== 0 &&
                              programPrice[idx].price.toLocaleString() + '원'}
                          </span>
                        </div>
                        {/* <div className={styles.starWrap}>
                          <img src={star} alt="star" />
                          <span>{item.star}</span>
                        </div> */}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
        <LazyLoad>
          <MainReview />
        </LazyLoad>
        <div className={styles.faqWrap}>
          <h3 onClick={() => navigate(`/faq`)}>
            <span>자주 묻는 질문</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <ul>
            {mainFaqList?.map((faq, idx) => (
              <MainFaqFrame faq={faq} key={idx} />
            ))}
          </ul>
        </div>
        <div className={styles.globalLegitScriptWrap}>
          <div>
            <img src={ondocGlobal} alt="ondocGlobal" />
          </div>
          <div className={styles.legitWrap}>
            <img src={ondocLegit} alt="ondocLegit" />
            <img
              className={styles.legitLogo}
              src="	https://static.legitscript.com/seals/14145209.png"
              alt="legit"
            />
          </div>
        </div>
        {/* <div className={styles.legitScript}>
          <img src={america} alt="america" />
          <div>
            <div className={styles.legitLogoWrap}>
              <img
                src="	https://static.legitscript.com/seals/14145209.png"
                alt="legit"
              />
            </div>
            <p>온닥은 미국 legitScript 로 부터 원격 의료 인증을 받았습니다.</p>
            <p>
              Ondoc started with US legitScript
              <br />
              contactless medical certification has been obtained.
            </p>
          </div>
        </div> */}
        <div className={styles.ondocInfoWrap}>
          <h3>
            <span>온닥 세가지 원칙</span>
          </h3>
          <div className={styles.ondocInfo}>
            <div>
              <div>
                <p>원칙 1.</p>
                <h3>
                  다이어트 프로그램을 통해
                  <br />
                  쉽고 빠르게
                </h3>
                <p>
                  온닥 다이어트 프로그램은 간편하면서도 효과적인 방법으로 몸매를
                  빠르게
                  <br /> 조절하는데 중점을 두고 있습니다. 일상 생활 속에서 맞춤
                  처방된 약재를 통해
                  <br /> 건강을 유지하면서 다이어트의 효과를 최대로 느껴보세요.
                </p>
              </div>
              <img src={ondocInfo1} alt="image" />
            </div>
            <div>
              <div>
                <p>원칙 2.</p>
                <h3>
                  원격 진료를
                  <br />
                  간편하고 정확하게
                </h3>
                <p>
                  온닥은 내원없이 간편하게 받아보는 원격진료서비스입니다.
                  <br />
                  쉽고 편한 의료 서비스를 제공하여 언제 어디서나
                  <br />
                  정확한 진료를 받아볼 수 있습니다.
                </p>
              </div>
              <img src={ondocInfo2} alt="image" />
            </div>
            <div>
              <div>
                <p>원칙 3.</p>
                <h3>
                  정성과 과학으로
                  <br />
                  조제합니다.
                </h3>
                <p>
                  온닥의 의료진은 검증된 약재와 맞춤 처방으로 안전한
                  <br /> 효과를 추구하며 식약처로부터 의약용품 인증을
                  <br /> 받은 약재만을 사용하여 제조합니다.
                </p>
              </div>
              <img src={ondocInfo3} alt="image" />
            </div>
          </div>
        </div>
        <div className={styles.doctorInfoWrap}>
          <h3>온닥 다이어트 의료진</h3>
          <div className={styles.doctorSliderWrap}>
            <Swiper
              slidesPerView={3}
              spaceBetween={20}
              className={styles.doctorSlider}
              onSlideChange={(e) => setDoctorSliderIdx(e.activeIndex)}
            >
              {/* <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img src={doctor01} alt="doctor" />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>손영훈 한의사</h4>
                  <span>Son Younghoon</span>
                  <ul>
                    <li>원광대학교 한의과대학 졸업</li>
                    <li>보건복지부 장관상 수상</li>
                    <li>서울시 체육회장상 수상</li>
                    <li>서울시의회 의장상 수상</li>
                    <li>대한한방해외의료봉사단 부단장</li>
                    <li>대한한의사협회 국제위원</li>
                  </ul>
                </div>
              </div>
            </SwiperSlide> */}
              <SwiperSlide>
                <div className={styles.doctor}>
                  <div className={styles.doctorImageWrap}>
                    <img src={leesoyoungPc} alt="doctor" />
                  </div>
                  <div className={styles.doctorInfo}>
                    <h4>이소영 한의사</h4>
                    <span>Lee Soyoung</span>
                    <ul>
                      <li>동의방약학회 정회원</li>
                      <li>한방비만학회 준회원</li>
                      <li>심리상담사 1급 심리분석사 1급</li>
                      <li>원광대학교 한의대학졸업</li>
                      <li>
                        {'<'}습담제거로 뱃살 뺀 이야기{'>'} 저자
                      </li>
                      <li>유튜브 ‘당뇨스쿨’ 운영자</li>
                      <li>당뇨 쇼핑몰 '당몰' 자문위원</li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.doctor}>
                  <div className={styles.doctorImageWrap}>
                    <img
                      src={youjiyouPc}
                      alt="doctor"
                      // style={{ maxWidth: '151px' }}
                    />
                  </div>
                  <div className={styles.doctorInfo}>
                    <h4>유지유 한의사</h4>
                    <span>Yoo jiyou</span>
                    <ul>
                      <li>원광대학교 한의과대학 졸업</li>
                      <li>신농씨 학회 정회원</li>
                      <li>MPS(근막이완침술) 교육 이사</li>
                      <li>척추관절 추나교육 이수</li>
                      <li>동의보감 병인론 연구모임</li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.doctor}>
                  <div className={styles.doctorImageWrap}>
                    <img
                      src={kimmiriPc}
                      alt="doctor"
                      // style={{
                      //   maxWidth: '183px',
                      //   objectPosition: 'top',
                      // }}
                    />
                  </div>
                  <div className={styles.doctorInfo}>
                    <h4>김미리 한의사</h4>
                    <span>Kim miri</span>
                    <ul>
                      <li>세명대학교 한의과대학 졸업</li>
                      <li>강남위담한방병원 일반수련의 수료</li>
                      <li>대한한의학회 정회원</li>
                      <li>대한담적한의학회 회원</li>
                      <li>대한한방비만학회 회원</li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              {/* <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img src={doctor03} alt="doctor" />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>박나라 한의사</h4>
                  <span>Park Nara</span>
                  <ul>
                    <li>대전대학교 한의과대학 졸업</li>
                    <li>유튜브 ‘온라인한의사’ 운영진</li>
                    <li>한의 원격의료 연구회 회원</li>
                  </ul>
                </div>
              </div>
            </SwiperSlide> */}
              {/* <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img
                    src={doctor04}
                    alt="doctor"
                    className={styles.bigImage}
                  />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>김운정 한의사</h4>
                  <span>Kim Unjung</span>
                  <ul>
                    <li>세명대학교 한의학과 졸업</li>
                  </ul>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img
                    src={doctor05}
                    alt="doctor"
                    className={styles.bigImage}
                  />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>김지현 한의사</h4>
                  <span>Kim Jihyun</span>
                  <ul>
                    <li>세명대학교 한의과대학 한의학과 졸업</li>
                    <li>분당자생한방병원 일반수련의 수료</li>
                  </ul>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img
                    src={doctor06}
                    alt="doctor"
                    className={styles.bigImage}
                  />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>박은영 한의사</h4>
                  <span>Park Eunyoung</span>
                  <ul>
                    <li>동국대학교 한의과대학 졸업</li>
                    <li>보건복지부 불안장애 한의표준</li>
                    <li>임상진료 지침 연구원</li>
                    <li>2020~2022 하이닥-네이버IN상담 한의사</li>
                    <li>
                      {'<'}비만당뇨 한방치료사례집{'>'} 저자
                    </li>
                  </ul>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img
                    src={doctor07}
                    alt="doctor"
                    className={styles.bigImage}
                  />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>장효두 한의사</h4>
                  <span>Jang Hyodoo</span>
                  <ul>
                    <li>경희대학교 한의과대학 한의학과 졸업</li>
                    <li>경희의료원 임상과정 수료</li>
                    <li>강동경희대병원 임상과정 수료</li>
                  </ul>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img
                    src={doctor08}
                    alt="doctor"
                    className={styles.bigImage}
                  />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>전유경 한의사</h4>
                  <span>Jeon Youkyung</span>
                  <ul>
                    <li>대한한의학회 정회원</li>
                    <li>한방비만학회 준회원</li>
                    <li>대한약침학회 준회원</li>
                  </ul>
                </div>
              </div>
            </SwiperSlide> */}
            </Swiper>
            {/* <ul className={styles.doctorSliderPagination}>
            {(() => {
              let list = [0, 1, 2, 3, 4, 5, 6, 7];
              return list.map((val) => (
                <li
                  key={val}
                  className={doctorSliderIdx == val ? styles.hit : ''}
                >
                  <div></div>
                </li>
              ));
            })()}
          </ul> */}
          </div>
        </div>
        <div className={styles.ondocService}>
          <div className={styles.textWrap}>
            <span>
              온닥은 쉽고 편리한
              <br />
              진료 서비스를 제공합니다.
            </span>
            <p>
              온닥은 내원 없이 간편하게 받아보는 비대면 진료 서비스로 온닥의
              의료진과
              <br /> 함께 가벼운 증상부터 만성질환까지 의료 서비스 이용에
              어려움을 겪는 분들
              <br />
              에게 빠르고 안전한, 쉽고 편리한 원격 진료 서비스를 제공합니다.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default Home;
