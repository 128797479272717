import styles from './DietQna.module.scss';
import Header from '../../components/Header/Header';
import { Key, useEffect, useRef, useState } from 'react';
import enter from '../../assets/dietProgram/enter.png';
import noEnter from '../../assets/dietProgram/noEnter.png';
import test from '../../assets/dietProgram/noticeTest.png';
import test2 from '../../assets/dietProgram/noticeTest2.png';
import chatLogo from '../../assets/dietProgram/chatLogo.png';
import HeaderMain from '../../components/HeaderMain/HeaderMain';
import { isDesktop, isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import axiosClient from '../../libs/axiosClient';
import { ITokenInfoType, loginCheck, logout } from '../../auth/auth';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import jwt_decode from 'jwt-decode';

interface IBannerType {
  image: string;
  url?: string;
}

interface IConsultType {
  user_id: number;
  is_admin: string;
  is_fixed: string;
  consult_faq_id: number;
  tilte: string;
  message: string;
  button: string;
  created_at: string;
}

interface IFaqType {
  id: number;
  category: string;
  question: string;
  answer: string;
  is_blind: string;
  created_at: string | Date;
  updated_at: string;
}

function DietQna() {
  const today = new Date();
  const navigate = useNavigate();
  const chatRef = useRef<HTMLDivElement>(null);
  const [activeIdx, setActiveIdx] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [noticeList, setNoticeList] = useState<IBannerType[]>([]);
  useEffect(() => {
    setNoticeList(() => {
      let list: IBannerType[] = [
        { image: test, url: '' },
        { image: test2, url: '' },
        { image: test, url: '' },
      ];

      return [...list];
    });
  }, []);

  const [faqClick, setFaqClick] = useState(false);
  const [isSelectNum, setIsSelectNum] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState('');
  const [selectAnswer, setSelectAnswer] = useState('');
  const [userInput, setUserInput] = useState('');
  // const [consultList, setConsultList] = useState<IConsultType[]>([]);
  const [consultList, setConsultList] = useState<any[]>([]); // 채팅 리스트
  const [careList, setCareList] = useState<IFaqType[]>([]); // 선케어 질문
  const [faqList, setFaqList] = useState<IFaqType[]>([]); // faq 질문
  const [newList, setNewList] = useState<any[]>([]);
  const [userId, setUserId] = useState<any>();
  const [name, setName] = useState('');
  const [lastMessageType, setLastMessageType] = useState('');

  useEffect(() => {
    if (!loginCheck()) return navigate('/');

    const token = localStorage.getItem('token');
    if (token == null || token == 'null') return;
    try {
      const decodedUserInfo: ITokenInfoType = jwt_decode(token!);
      setName(decodedUserInfo.name);
      // setName('테스트');
      setUserId(decodedUserInfo.id);
    } catch (err) {
      logout();
    }

    // 읽음처리
    axiosClient
      .post('/func/consult_log/read', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });

    // 자주묻는질문 조회 및 매니저 상담 추가
    axiosClient
      .get('/data/consult_faq/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setFaqList(res.data.data.data);
        setFaqList((prev) => [
          ...prev,
          {
            id: 9999,
            category: '상담',
            question: '다른 질문이 있어요. 매니저님과 상담하고 싶어요.',
            answer:
              '상담이 필요한 내용을 입력해주세요. \n문의 사항에 대하여 최대한 자세히 작성해주시면, 보다 전문적이고 정확한 답변을 해드릴 수 있습니다.',
            is_blind: 'N',
            created_at: today,
            updated_at: '',
          },
        ]);
        setCareList((prev) => [
          ...prev,
          {
            id: 8888,
            category: '케어',
            question: '매니저님에게 답변할게요.',
            answer: '',
            is_blind: 'N',
            created_at: today,
            updated_at: '',
          },
        ]);
      })
      .catch((err) => {
        console.log(err.response.data.detail);
      });

    // 채팅 조회
    getConsult();

    // 테스트용으로 사용
    // let formData = new FormData();
    // formData.append('message', '어쩌고저쩌고 상담을  하겠습니다.');
    // formData.append('is_admin', 'Y');
    // formData.append('is_fixed', 'N');
    // formData.append('message_type', 'care');

    // axiosClient
    //   .post('/func/consult_log/', formData, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem('token')}`,
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  // 채팅 조회
  const getConsult = () => {
    axiosClient
      .get('/data/consult_log', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setConsultList(res.data.data.reverse());
        if (res.data.data.message_type)
          setLastMessageType(res.data.data.message_type);
      })
      .catch((err) => {
        console.log(err.response.data.detail);
      });
  };

  const [msgType, setMsgType] = useState('');
  const [isCare, setIsCare] = useState(false);

  // 마지막 채팅이 선케어 타입인지 확인
  // => 마지막 채팅이 관리자이고 '종료' 문구 있는지 확인 용도
  useEffect(() => {
    if (!consultList.length && !newList.length) return;

    // (consultList[consultList.length - 1]?.message_type === 'care' &&
    //   consultList[consultList.length - 1]?.is_admin === 'Y') ||
    // (newList[0]?.message_type === 'care' && newList[0]?.is_admin === 'Y')
    if (
      consultList[consultList.length - 1]?.message_type === 'care' ||
      newList[0]?.message_type === 'care'
    ) {
      setIsCare(true);
    } else setIsCare(false);
  }, [consultList, newList]);

  // 채팅 높이 조정
  const textRef = useRef<any>();
  const [chatHeight, setChatHeight] = useState(
    isMobile ? 'calc(100vh - 176px)' : 'calc(100vh - 245px)',
  );
  const [chatHeight2, setChatHeight2] = useState(
    isMobile ? 'calc(100vh - 248px)' : 'calc(100vh - 317px)',
  );

  // textarea 세로길이 변경
  const handleResizeHeight = () => {
    if (textRef && textRef.current) {
      const textHeight = textRef.current.scrollHeight;

      if (textHeight < 72) {
        textRef.current.style.height = '52px';
      } else {
        textRef.current.style.height = '72px';
      }
    }
  };

  // 채팅 리스트(입장후) 및 새로운 채팅 리스트 있는 경우 스크롤 다운
  useEffect(() => {
    scrollDown();
  }, [consultList, newList]);

  const scrollDown = () => {
    if (!chatRef.current) return;
    // chatRef.current.scrollTo({ top: 99999, behavior: 'smooth' });
    chatRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  // 매니저와 직접 상담 전송클릭 및 엔터
  const onSubmitUserInput = () => {
    if (userInput.trim() === '') return;
    onSubmitPrevInput();
  };

  const onEnterUserInput = (e: any) => {
    if (userInput.trim() === '') return;
    if (e.key === 'Enter' && !e.shiftKey && userInput.trim() !== '') {
      if (e.nativeEvent.isComposing) return;
      e.preventDefault();
      onSubmitPrevInput();
    }
  };

  // 케어 frame 가져오기
  const careFrame = (date: string, time: string, selectMsg?: any) => {
    return (
      <div className={styles.managerWrap}>
        <span className={styles.nameWrap}>다이어트 도움 전담 매니저</span>
        <div className={styles.chatContentFrame}>
          <div className={styles.chatDateWrap}>
            {/* <span>{date}</span>
            <span>{time}</span> */}
          </div>
        </div>
        {careList &&
          careList.map((care, idx) => (
            <div key={idx} className={styles.quesFrame}>
              <div
                className={styles.quesWrap}
                onClick={() => {
                  setIsSelectNum(care.id);
                  setSelectQuestion(care.question);
                  setSelectAnswer(care.answer);
                }}
              >
                {care.question}
              </div>
            </div>
          ))}
      </div>
    );
  };

  // 자주 묻는 질문 frame 가져오기
  const getFaqFrame = (date: string, time: string, selectMsg: any) => {
    return (
      <>
        <div className={styles.managerWrap}>
          <span className={styles.nameWrap}>다이어트 도움 전담 매니저</span>
          <div className={styles.chatContentFrame}>
            <div className={styles.chatContentWrap}>
              {name}님, 안녕하세요! 무엇을 도와드릴까요?
            </div>
            <div className={styles.chatDateWrap}>
              <span>{date}</span>
              <span>{time}</span>
            </div>
          </div>
          {faqList &&
            faqList.map((faq, idx) => (
              <div
                key={idx}
                // className={`${styles.quesFrame} ${
                //   selectMsg === faq.question ? styles.hit : ''
                // }`}
                className={styles.quesFrame}
              >
                <div
                  className={styles.quesWrap}
                  // style={{ cursor: faqClick ? 'pointer' : 'auto' }}
                  onClick={() => {
                    // 질문번호 질문메시지 답변메시지
                    setIsSelectNum(faq.id);
                    setSelectQuestion(faq.question);
                    setSelectAnswer(faq.answer);
                  }}
                >
                  {faq.question}
                </div>
              </div>
            ))}
        </div>
      </>
    );
  };

  // 자주 묻는 질문 frame 가져오기
  const getNoClickFaqFrame = (date: string, time: string, selectMsg: any) => {
    return (
      <>
        <div className={styles.managerWrap}>
          <span className={styles.nameWrap}>다이어트 도움 전담 매니저</span>
          <div className={styles.chatContentFrame}>
            <div className={styles.chatContentWrap}>
              {name}님, 안녕하세요! 무엇을 도와드릴까요?
            </div>
            <div className={styles.chatDateWrap}>
              <span>{date}</span>
              <span>{time}</span>
            </div>
          </div>
          {faqList &&
            faqList.map((faq, idx) => (
              <div
                key={idx}
                className={`${styles.quesFrame} ${
                  selectMsg === faq.question ? styles.hit : ''
                }`}
              >
                <div
                  className={styles.quesWrap}
                  style={{ cursor: 'auto' }}
                  onClick={() => {}}
                >
                  {faq.question}
                </div>
              </div>
            ))}
        </div>
      </>
    );
  };

  const renderFrame = () => {
    if (newList.length > 0) {
      // 새로운 채팅이 있는 경우

      // 마지막 채팅 선케어 타입이고 관리자인 경우
      // 메시지에 '종료'를 포함하면 faqList 불러오도록 getFaqFrame
      // 메시지에 '종료'를 포함하지 않으면 careList 불러오도록 careFrame
      if (isCare) {
        if (
          newList[newList.length - 1].message.includes('종료') &&
          newList[newList.length - 1].is_admin === 'Y'
        )
          return getFaqFrame(
            moment(today).format('YY.MM.DD'),
            moment(today).format('HH:mm'),
            selectQuestion,
          );
        else
          return careFrame(
            moment(today).format('YY.MM.DD'),
            moment(today).format('HH:mm'),
          );
      } else {
        return getNoClickFaqFrame(
          moment(today).format('YY.MM.DD'),
          moment(today).format('HH:mm'),
          selectQuestion,
        );
      }
    } else {
      // 새로운 채팅이 없는 경우

      // 마지막 채팅 선케어 타입이고 관리자인 경우
      // 메시지에 '종료'를 포함하면 faqList 불러오도록 getFaqFrame
      // 메시지에 '종료'를 포함하지 않으면 careList 불러오도록 careFrame
      if (isCare) {
        if (
          consultList[consultList.length - 1].message.includes('종료') &&
          consultList[consultList.length - 1].is_admin === 'Y'
        )
          return getFaqFrame(
            moment(today).format('YY.MM.DD'),
            moment(today).format('HH:mm'),
            selectQuestion,
          );
        else
          return careFrame(
            moment(today).format('YY.MM.DD'),
            moment(today).format('HH:mm'),
          );
      } else {
        return getFaqFrame(
          moment(today).format('YY.MM.DD'),
          moment(today).format('HH:mm'),
          selectQuestion,
        );
      }
    }
  };

  useEffect(() => {
    if (isSelectNum === 0) return;

    if (selectQuestion || selectAnswer) onSubmitPrev();
  }, [selectQuestion, selectAnswer, isSelectNum]);

  // 클릭했을 때 onSubmit 보내기 전에 확인하는 조건
  const onSubmitPrev = async () => {
    if (isSelectNum === 0) return;

    try {
      // 실제 서버로 전송
      if (isSelectNum !== 8888) {
        await onSubmit(
          selectQuestion,
          'user',
          isSelectNum === 9999 || isSelectNum === 8888 ? 'N' : 'Y',
        );
        await onSubmit(
          selectAnswer,
          'admin',
          isSelectNum === 9999 || isSelectNum === 8888 ? 'N' : 'Y',
        );
      }

      const updatedNewList = newList.concat([
        {
          user_id: userId,
          is_admin: 'N',
          is_fixed: isSelectNum === 9999 || isSelectNum === 8888 ? 'N' : 'Y',
          consult_faq_id: isSelectNum,
          title: '',
          message: selectQuestion,
          button: '',
          created_at: today,
        },
        {
          user_id: userId,
          is_admin: 'Y',
          is_fixed: isSelectNum === 9999 || isSelectNum === 8888 ? 'N' : 'Y',
          consult_faq_id: isSelectNum,
          title: '',
          message: selectAnswer,
          button: '',
          created_at: today,
        },
      ]);

      const updatedCareNewList = newList.concat([
        {
          user_id: userId,
          is_admin: 'N',
          is_fixed: 'N',
          consult_faq_id: isSelectNum,
          title: '',
          message: selectQuestion,
          button: '',
          created_at: today,
        },
      ]);
      if (isSelectNum === 9999) setNewList(updatedNewList);
      else if (isSelectNum === 8888) setNewList(updatedCareNewList);
    } catch (err) {
      console.error(err);
    }
  };

  const [faqFrame, setFaqFrame] = useState<any>(null);
  useEffect(() => {
    let frame;
    if (newList.length > 0) {
      if (
        newList[newList.length - 1].is_admin === 'Y' &&
        isSelectNum !== 9999 &&
        isSelectNum !== 8888
      ) {
        frame = getFaqFrame(
          moment(today).format('YY.MM.DD'),
          moment(today).format('HH:mm'),
          selectQuestion,
        );
      } else {
        frame = getFaqFrame(
          moment(today).format('YY.MM.DD'),
          moment(today).format('HH:mm'),
          selectQuestion,
        );
      }
      setFaqFrame(frame);
    }
  }, [newList, selectQuestion, isSelectNum]);

  const directAnswer = `전문 매니저가 ${name}님의 문의 내용을 파악 및 분석하고, 신중한 답변을 준비하고 있습니다. 
답변이 등록되면, 앱 푸쉬 알림으로 알려드릴 예정이니, 잠시만 기다려주세요.`;

  const directAnswer2 = `
  * 상담 운영 시간 *
am 10:00 - pm 18:00

점심시간 am 11:30 - pm 12:30
토요일, 공휴일 휴무`;

  // 입력했을 때 onSubmit 보내기 전에 확인하는 조건
  const onSubmitPrevInput = async () => {
    if (userInput.trim() === '') return;
    const value = userInput.trim();

    try {
      // 사용자 메시지는 무조건 서버에 전송
      await onSubmit(value, 'user', 'N');

      // 매니저와 상담하는 경우 관리자 메시지는 일반 타입만 전송
      if (directAnswer && directAnswer2 && isSelectNum !== 8888)
        await onSubmit(directAnswer, 'admin', 'Y', directAnswer2);

      // if (directAnswer && directAnswer2) {
      // 일반 상담 보내는 경우에는 작은 글씨 메시지(운영시간) 함께 보내기
      //   if (isSelectNum !== 8888)
      //     await onSubmit(directAnswer, 'admin', 'N', directAnswer2);
      // } else if (directAnswer) {
      //   if (isSelectNum !== 8888) await onSubmit(directAnswer, 'admin', 'N');
      // }

      const updatedNewList = newList.concat([
        {
          user_id: userId,
          is_admin: 'N',
          is_fixed: 'N',
          consult_faq_id: isSelectNum,
          title: '',
          message: value,
          button: '',
          created_at: today,
        },
        {
          user_id: userId,
          is_admin: 'Y',
          is_fixed: 'N',
          consult_faq_id: isSelectNum,
          title: '',
          message: directAnswer,
          add_message: directAnswer2,
          button: '',
          created_at: today,
        },
      ]);
      const updatedCareNewList = newList.concat([
        {
          user_id: userId,
          is_admin: 'N',
          is_fixed: 'N',
          consult_faq_id: isSelectNum,
          title: '',
          message: value,
          button: '',
          created_at: today,
        },
      ]);

      // 새로운 채팅 리스트에 선케어인 경우 updatedCareNewList
      // 아닌 경우 updatedNewList
      setNewList(isSelectNum !== 8888 ? updatedNewList : updatedCareNewList);

      setUserInput('');
    } catch (err) {
      console.error(err);
    }
  };

  // 메시지, 관리자 여부, 자주 묻는 질문 여부, 작은 글씨 메시지 여부(directAnswer2)
  const onSubmit = async (
    value: string,
    user: string,
    isFaq: string,
    addValue?: string,
  ) => {
    if (isSelectNum === 0) return;
    if (!value) return;

    let formData = new FormData();
    formData.append('message', value);
    if (addValue) formData.append('add_message', addValue);
    formData.append('is_admin', user === 'admin' ? 'Y' : 'N');
    formData.append('consult_faq_id', isSelectNum.toString());
    formData.append('is_fixed', isFaq);
    if (isSelectNum === 8888) formData.append('message_type', 'care');

    await axiosClient
      .post('/func/consult_log/', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (isFaq === 'Y') {
          getConsult();
          setIsSelectNum(0);
          setSelectQuestion('');
          setSelectAnswer('');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DateFormat = (date: any) => {
    const formattedDate = moment(date).format('YYYY년 M월 D일');

    return <div>{formattedDate}</div>;
  };

  return (
    <div>
      {/* <HeaderMain /> */}
      <Header />
      <div
        className={`${styles.contentWrap} ${isDesktop ? styles.isDesktop : ''}`}
      >
        <div className={styles.chatOutWrap}>
          <div
            ref={chatRef}
            className={styles.chatWrap}
            style={{
              height:
                isSelectNum !== 9999 && isSelectNum !== 8888
                  ? chatHeight
                  : chatHeight2,
            }}
          >
            {/* 마지막 채팅 날짜 또는 현재 날짜로 입장 */}
            <div className={styles.dateWrap}>
              {consultList[0]
                ? DateFormat(consultList[0].created_at)
                : DateFormat(new Date())}
            </div>
            <div className={styles.enterWrap}>
              {name}님과 다이어트 도움 전담 매니저님이 입장하셨습니다.
            </div>
            {/* 채팅 리스트 */}
            {consultList &&
              consultList.map((consult, idx) => {
                // 관리자 메시지
                if (consult.is_admin === 'Y') {
                  const value = consult.created_at;
                  const date = moment(value).format('YY.MM.DD');
                  const time = moment(value).format('HH:mm');
                  return (
                    <div key={idx}>
                      <div className={styles.managerWrap}>
                        <span className={styles.nameWrap}>
                          다이어트 도움 전담 매니저
                        </span>
                        {consult.image && (
                          <div className={styles.noticeWrap}>
                            <Swiper
                              onSwiper={setSwiper}
                              slidesPerView={1}
                              spaceBetween={0}
                              onSlideChange={(e) => setActiveIdx(e.activeIndex)}
                            >
                              {consult.image.map(
                                (imageUrl: string, idx: number) => (
                                  <SwiperSlide
                                    key={idx}
                                    className={styles.noticeFrame}
                                  >
                                    <img src={imageUrl} alt={`slide-${idx}`} />
                                  </SwiperSlide>
                                ),
                              )}
                            </Swiper>
                            <ul className={styles.paginationWrap}>
                              {consult.image.map((_: string, idx: number) => (
                                <li
                                  key={idx}
                                  onClick={() => swiper?.slideTo(idx, 200)}
                                  className={
                                    idx === activeIdx ? styles.hit : ''
                                  }
                                ></li>
                              ))}
                            </ul>
                          </div>
                        )}
                        <div className={styles.chatContentFrame}>
                          <div className={styles.chatContentWrap}>
                            {consult?.title && (
                              <div className={styles.noticeTitleWrap}>
                                <img src={chatLogo} alt="logo" />
                                <span>{consult.title}</span>
                              </div>
                            )}
                            {consult.message}
                            <p className={styles.centerTimeWrap}>
                              {consult.add_message}
                            </p>
                            {consult?.button && (
                              <div
                                className={styles.noticeBtnWrap}
                                onClick={() => {
                                  window.location.href = consult.button.link;
                                }}
                              >
                                <span>{consult?.button.name}</span>
                              </div>
                            )}
                          </div>
                          <div className={styles.chatDateWrap}>
                            <span>{date}</span>
                            <span>{time}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  // 사용자 메시지
                  const value = consult.created_at;
                  const date = moment(value).format('YY.MM.DD');
                  const time = moment(value).format('HH:mm');
                  return (
                    <div key={idx}>
                      {consult.is_admin === 'N' &&
                        consult.is_fixed === 'Y' &&
                        getNoClickFaqFrame(date, time, consult.message)}
                      <div className={`${styles.userWrap}`}>
                        <div className={styles.userChatContentFrame}>
                          <div className={styles.userChatDateWrap}>
                            <span>{date}</span>
                            <span>{time}</span>
                          </div>
                          <div className={styles.userChatContentWrap}>
                            {consult.message}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            {/* 질문 프레임 무조건 마지막에 나타나야함 */}
            {renderFrame()}

            {/* 새로운 채팅 리스트 */}
            {newList &&
              newList.map((newList, idx) => {
                // 관리자 메시지
                if (newList.is_admin === 'Y') {
                  const value = newList.created_at;
                  const date = moment(value).format('YY.MM.DD');
                  const time = moment(value).format('HH:mm');
                  return (
                    <div key={idx}>
                      <div className={styles.managerWrap}>
                        <span className={styles.nameWrap}>
                          다이어트 도움 전담 매니저
                        </span>
                        <div className={styles.chatContentFrame}>
                          <div className={styles.chatContentWrap}>
                            {newList.message}
                            <p className={styles.centerTimeWrap}>
                              {newList.add_message}
                            </p>
                          </div>
                          <div className={styles.chatDateWrap}>
                            <span>{date}</span>
                            <span>{time}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  // 사용자 메시지
                  const value = newList.created_at;
                  const date = moment(value).format('YY.MM.DD');
                  const time = moment(value).format('HH:mm');
                  return (
                    <div key={idx}>
                      <div className={`${styles.userWrap}`}>
                        <div className={styles.userChatContentFrame}>
                          <div className={styles.userChatDateWrap}>
                            <span>{date}</span>
                            <span>{time}</span>
                          </div>
                          <div className={styles.userChatContentWrap}>
                            {newList.message}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            {/* {faqFrame} */}

            {/* 매니저 상담(일반/선케어) 경우 하단에 input 표시 */}
            {(isSelectNum === 9999 || isSelectNum === 8888) && (
              <div className={styles.userInputWrap}>
                <div className={styles.userInputFrame}>
                  <textarea
                    ref={textRef}
                    placeholder="채팅을 입력하세요."
                    value={userInput}
                    onInput={handleResizeHeight}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      setUserInput(value);
                      if (value.trim() === '') {
                        handleResizeHeight();
                      }
                    }}
                    onKeyDown={onEnterUserInput}
                  />
                  <img
                    onClick={() => {
                      onSubmitUserInput();
                    }}
                    src={userInput ? enter : noEnter}
                    alt="enter"
                  />
                </div>
              </div>
            )}
            {/* {selectQuestion && (
              <div className={styles.userWrap}>
                <div className={styles.userChatContentFrame}>
                  <div className={styles.userChatDateWrap}>
                    <span>24.01.31</span>
                    <span>17:36</span>
                  </div>
                  <div className={styles.userChatContentWrap}>
                    {selectQuestion}
                  </div>
                </div>
              </div>
            )}
            
            {isSelectNum !== 0 && (
              <div
                className={styles.managerWrap}
              >
                <span className={styles.nameWrap}>다이어트 도움 전담 매니저</span>
                {faqList &&
                  faqList.map(
                    (item, idx) =>
                      item.id === isSelectNum && (
                        <div className={styles.chatContentFrame}>
                          <div className={styles.chatContentWrap}>
                            {item.answer}
                          </div>
                          <div className={styles.chatDateWrap}>
                            <span>{moment(today).format('YY.MM.DD')}</span>
                            <span>{moment(today).format('HH:mm')}</span>
                          </div>
                        </div>
                      ),
                  )}
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DietQna;
