import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './SubscribeDetail.module.scss';
import delivery from '../../assets/dietProgram/delivery.png';
import notice from '../../assets/dietProgram/notice.png';
import subRight from '../../assets/dietProgram/subRight.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PayChangeModal from '../../components/PayChangeModal/PayChangeModal';
import SubscribeCancelModal from '../../components/SubscribeCancelModal/SubscribeCancelModal';
import SubscribeCancelOkModal from '../../components/SubscribeCancelOkModal/SubscribeCancelOkModal';
import card from '../../assets/dietProgram/card.png';
import subBell from '../../assets/dietProgram/subBell.png';
import subPlus from '../../assets/dietProgram/subPlus.png';
import SubscribePlusModal from '../../components/SubscribePlusModal/SubscribePlusModal';
import subPlusRight from '../../assets/dietProgram/subPlusRight.png';
import { isDesktop } from 'react-device-detect';
import axiosClient from '../../libs/axiosClient';
import { IProgramType } from '../MyPage/MyPage';
import moment from 'moment';

function SubscribeDetail() {
  const navigate = useNavigate();
  const [cancelOpen, setCancelOpen] = useState(false);
  const [plusOpen, setPlusOpen] = useState(false);
  const [programList, setProgramList] = useState<IProgramType[]>([]);
  const [subId, setSubId] = useState<number>(0);

  useEffect(() => {
    getSubscription();
  }, []);

  const getSubscription = () => {
    axiosClient
      .get('/data/subscription', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setProgramList(res.data.data);
      });
  };

  return (
    <div>
      <Header />
      <div
        className={`${styles.container} ${isDesktop ? styles.isDesktop : ''}`}
      >
        {programList &&
          programList
            .filter((filterList, idx) => filterList.cycle_type === 'Month')
            .map((list, listIdx) => (
              <div key={listIdx} className={styles.contentWrap}>
                <div className={styles.topWrap}>
                  <span>{list.nth}회차 진행중</span>
                  <div>
                    <span>다음 결제 예정일</span>
                    <span>
                      {list.status === 'C' ||
                      (list.nth % 3 === 0 && list.status !== 'R')
                        ? '없음'
                        : moment(list.next_payment_at).format('YYYY.M.D')}
                    </span>
                  </div>
                </div>
                <div className={styles.detailWrap}>
                  <div className={styles.detailListFrame}>
                    <div className={styles.title}>
                      <span>
                        {'[정기배송] ' +
                          list.option_name
                            ?.split('/')[1]
                            .trim()
                            .replace(/\s*\(.*$/, '')}
                      </span>
                      <div
                        className={styles.priceWrap}
                        onClick={() => {
                          navigate('/subscribe-order-detail', {
                            state: {
                              subList: list,
                            },
                          });
                        }}
                      >
                        <span>결제 내역</span>
                        <img src={subRight} alt="direct" />
                      </div>
                    </div>
                    <div className={styles.subInfoWrap}>
                      <img src={card} alt="card" />
                      <div>
                        <span className={styles.hit}>
                          {list.option_name.includes('붓기') &&
                            '매일 9,050원으로 디톡스 '}
                          {list.option_name.includes('클렌즈') &&
                            '매일 7,000원으로 디톡스 '}
                          {list.option_name.includes('풀') &&
                            '매일 16,050원으로 디톡스 '}
                        </span>
                        <span>중이에요!</span>
                        <br />
                        <span className={styles.smallText}>
                          배송 주기 - 한 달에 한 번 / 총 3회{' '}
                        </span>
                      </div>
                    </div>
                    <div className={styles.detailContainer}>
                      <div className={styles.detailFrame}>
                        <div className={styles.titlePriceWrap}>
                          <span>제품구성</span>
                        </div>
                        <div className={styles.listWrap}>
                          {list.subscript_products.map((product, idx) => (
                            <div key={idx} className={styles.listFrame}>
                              <span>{product.name}</span>
                              <span>
                                {product.desc}
                                {list.amount !== 1 && `x${list.amount}`}
                                /월
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {list.status !== 'C' && (
                  <div className={styles.deliveryBtnWrap}>
                    <div
                      className={styles.deliveryBtn}
                      onClick={() => {
                        navigate('/delivery-change', {
                          state: {
                            subId: list.id,
                          },
                        });
                      }}
                    >
                      <img src={delivery} alt="delivery" />
                      <span>배송정보 변경</span>
                    </div>
                  </div>
                )}
                {list.status === 'X' ? (
                  <div className={styles.subNoPlusBtnWrap}>
                    <div className={styles.subNoPlusBtn}>
                      <div className={styles.textWrap}>
                        <img src={subBell} alt="bell" />
                        <span>구독을 연장하지 않았어요.</span>
                      </div>
                      <div
                        className={styles.directWrap}
                        onClick={() => {
                          setPlusOpen(true);
                          setSubId(list.id);
                        }}
                      >
                        <span>구독 연장하기</span>
                        <img src={subPlusRight} alt="right" />
                      </div>
                    </div>
                  </div>
                ) : list.status === 'C' ? (
                  <div className={styles.cancelBtnWrap}>
                    <div className={styles.cancelBtn}>
                      <img src={subBell} alt="bell" />
                      <span>구독을 해지했어요.</span>
                    </div>
                  </div>
                ) : list.status === 'R' ? (
                  <div className={styles.cancelBtnWrap}>
                    <div className={styles.cancelBtn}>
                      <img src={subBell} alt="bell" />
                      <span>구독 연장 완료(3개월)</span>
                    </div>
                  </div>
                ) : list.status === 'Y' && list.nth % 3 === 0 ? (
                  <div className={styles.subPlusBtnWrap}>
                    <div
                      className={styles.subPlusBtn}
                      onClick={() => {
                        setPlusOpen(true);
                        setSubId(list.id);
                      }}
                    >
                      <img src={subBell} alt="bell" />
                      <span>구독 연장하기(3개월)</span>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {list.status === 'Y' && list.nth % 3 === 0 && (
                  <div className={styles.subPlusNoticeWrap}>
                    <div className={styles.plusNoticeWrap}>
                      <div className={styles.plusNoticeFrame}></div>
                    </div>
                    <div className={styles.plusNoticeTextWrap}>
                      <span>구독을 연장하고 </span>
                      <span className={styles.hit}>월 34,000원의 혜택</span>
                      <span>을 계속 받아보세요!</span>
                    </div>
                  </div>
                )}
                {list.status !== 'C' && (
                  <div className={styles.noticeWrap}>
                    {list.status === 'Y' && <img src={notice} alt="notice" />}
                    <div className={styles.noticeTextWrap}>
                      {list.status === 'Y' && list.nth % 3 !== 0 && (
                        <span>
                          결제 실패 시 다음 날 재결제가 시도되며, <br />
                          3번 이상 재결제 실패 시 구독이 자동으로 취소됩니다.
                        </span>
                      )}
                      {list.status === 'Y' && list.nth % 3 === 0 && (
                        <span>
                          구독 연장하기를 신청하지 않으시면 자동으로 구독 결제가
                          해지됩니다.
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {list.status !== 'C' && (
                  <div className={styles.cancelWrap}>
                    <span
                      onClick={() => {
                        setSubId(list.id);
                        setCancelOpen(true);
                      }}
                    >
                      구독 해지
                    </span>
                  </div>
                )}
              </div>
            ))}
      </div>
      {cancelOpen && (
        <SubscribeCancelModal
          closer={setCancelOpen}
          subscriptionId={subId}
          getSubscription={getSubscription}
        />
      )}
      {plusOpen && (
        <SubscribePlusModal
          closer={setPlusOpen}
          subscriptionId={subId}
          getSubscription={getSubscription}
        />
      )}
      {/* {cancelOpen && <PayChangeModal closer={setCancelOpen} />} */}
      {/* {cancelOpen && <SubscribeCancelOkModal closer={setCancelOpen} />} */}
    </div>
  );
}

export default SubscribeDetail;
