import { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header/Header';
import styles from './DietManagement.module.scss';
import dietFood from '../../assets/dietProgram/dietFood.png';
import check from '../../assets/dietProgram/check.png';
import newAlert from '../../assets/dietProgram/new.png';
import SubscribeTermModal from '../../components/SubscribeTermModal/SubscribeTermModal';
import SubscribeTermModal2 from '../../components/SubscribeTermModal2/SubscribeTermModal2';
import { useNavigate } from 'react-router-dom';
import { isDesktop, isMobile } from 'react-device-detect';
import axiosClient from '../../libs/axiosClient';
import jwt_decode from 'jwt-decode';
import { ITokenInfoType, loginCheck, logout } from '../../auth/auth';
import moment from 'moment';

export interface ISurveyType {
  id: number;
  gender: string;
  age: number;
  height: number;
  weight: number;
  is_fixed: string;
  activity_level: number;
  prefer_protein: string;
  prefer_diet: string;
  avoid_food_tag: string;
  start_date: string;
  end_date: string;
  tota_kcal: number;
  created_at: string;
  updated_at: string;
  random_issue?: string;
}

interface IFoodDetailType {
  calories: number;
  diet_survey_id: number;
  img_url: string;
  max_calories: number;
  min_calories: number;
  weekly_diet: IFoodListType[];
}

interface IFoodListType {
  carb: number;
  consume_date: string;
  fat: number;
  foods: IFoodMenuType[];
  protein: number;
  result: number;
  total_kcal: number;
}

interface IFoodMenuType {
  avoid_tag: string;
  carb: number;
  category1: string;
  category2: string;
  consume_capacity: number;
  consume_quantity: number;
  created_at: string;
  fat: number;
  id: number;
  kcal: number;
  name: string;
  protein: number;
  updated_at: string;
}

function DietManagement() {
  const numRegex = /^[0-9]+$/;
  const navigate = useNavigate();
  const chatRef = useRef<HTMLDivElement>(null);
  const [name, setName] = useState('');
  const [isRequest, setIsRequest] = useState(false);
  const [isTerm1, setIsTerm1] = useState(false);
  const [isTerm2, setIsTerm2] = useState(false);
  const [isTermOpen1, setIsTermOpen1] = useState(false);
  const [isTermOpen2, setIsTermOpen2] = useState(false);
  const [isGender, setIsGender] = useState('');
  const [isAge, setIsAge] = useState('');
  const [isAgeOk, setIsAgeOk] = useState(false);
  // 나이 처음 변동
  const [isInitAgeOk, setIsInitAgeOk] = useState(false);
  const [isHeight, setIsHeight] = useState('');
  const [isWeight, setIsWeight] = useState('');
  const [isUserInfoOk, setIsUserInfoOk] = useState(false);
  // 키 몸무게 처음 변동
  const [isInitUserInfoOk, setIsInitUserInfoOk] = useState(false);
  // 평소 활동량
  const [isActive, setIsActive] = useState('');
  const [isActiveNum, setIsActiveNum] = useState(0);
  const [isActiveArr, setIsActiveArr] = useState([
    '활동이 적거나 운동을 하지 않아요.',
    '주 1~2일 가벼운 활동 및 산책을 해요.',
    '주 3~4일 적당한 활동이나 30분 이상의 숨차는 운동을 해요.',
    '주 5~6일 매우 활발한 활동이나 숨차는 운동을 해요.',
    '매일 몸을 쓰는 일을 하거나 숨차는 운동을 해요.',
  ]);
  // 선호 단백질 처음 변동
  const [isProteinUpdate, setIsProteinUpdate] = useState(false);
  const [isProtein, setIsProtein] = useState<any[]>([]);
  const [proteinArr, setProteinArr] = useState([
    '상관없어요',
    '닭고기',
    '돼지고기',
    '소고기',
    '두부',
    '콩',
    '생선',
  ]);
  // 싫은 음식 처음 변동
  const [isHateUpdate, setIsHateUpdate] = useState(false);
  const [isHateFood, setIsHateFood] = useState<any[]>([]);
  const [hateFoodArr, setHateFoodArr] = useState([
    '상관없어요',
    '돼지고기',
    '소고기',
    '우유',
    '땅콩',
    '호두',
    '메밀',
    '밀',
    '콩류',
    '계란류',
    '토마토',
    '조개류',
    '새우',
    '게',
    '고등어',
    '오징어',
    '복숭아',
    '기타(직접입력)',
  ]);
  const [isHateEtc, setIsHateEtc] = useState('');
  const [isHateEtcOk, setIsHateEtcOk] = useState(false);
  // 목표 감량 몸무게
  const [isLoseWeight, setIsLoseWeight] = useState('');
  const [isLoseWeightNum, setIsLoseWeightNum] = useState(0);
  const [isDietFoodUpdate, setIsDietFoodUpdate] = useState(false);
  const [isDietFood, setIsDietFood] = useState<any[]>([]);
  // 선호 다이어트 식단
  const [dietFoodArr, setDietFoodArr] = useState([
    '칼로리 맞춤식',
    '고단백식',
    '저탄고지식',
  ]);
  const [isNotSelect, setIsNotSelect] = useState('');
  const [isOk, setIsOk] = useState(false); // 확인했어요 누르자마자 색 표시
  const [isAnswerOk, setIsAnswerOk] = useState(''); // 설문 답변
  // 설문하고 식단 발급 받은 경우 true / 랜덤 발급 받고 설문만 update true
  const [isAskOk, setIsAskOk] = useState(false);

  const [isFoodListWait, setIsFoodListWait] = useState(false); // 식단 발급 로딩
  const [noFixLast, setNoFixLast] = useState(false); // 마지막 식단 확정x 변경 -> 나타났다가 사라지는 용
  const [noSurveyFix, setNoSurveyFix] = useState(false); // 설문 사용 외 식단 확정 여부
  const [surveyFix, setSurveyFix] = useState(false); // 설문에 사용한 식단 확정 여부 클릭했는지
  const [isDietFoodGoing, setIsDietFoodGoing] = useState('');
  const [isFixNum, setIsFixNum] = useState(0);
  const [allSurveyList, setAllSurveryList] = useState<ISurveyType[]>([]); // 식단 전체 리스트
  const [surveyList, setSurveyList] = useState<ISurveyType[]>([]); // 식단 전체 리스트 reverse
  const [randomList, setRandomList] = useState<any[]>([]); // 식단 랜덤 발급 리스트
  const [noRandomList, setNoRandomList] = useState<any[]>([]); // 설문에 사용하는 식단을 제외한 나머지 식단 리스트
  const [chatList, setChatList] = useState<any[]>([]); // 설문 여부 체크하여 스크롤 다운에 사용할 리스트
  const [foodDetailList, setFoodDetailList] = useState<IFoodDetailType>(); // 설문 후 생성 되는 식단 상세 리스트
  const [dietId, setDietId] = useState<any>();
  const [isWeekFood, setIsWeekFood] = useState(false); // 해당 주에 식단 발급 받은 여부
  const [isHateEtcOkBtnClicked, setIsHateEtcOkBtnClicked] = useState(false);

  useEffect(() => {
    if (!loginCheck()) return navigate('/');

    const token = localStorage.getItem('token');
    if (token == null || token == 'null') return;
    try {
      const decodedUserInfo: ITokenInfoType = jwt_decode(token!);
      setName(decodedUserInfo.name);
      // setName('테스트');
    } catch (err) {
      logout();
    }

    // 설문 조회
    getSurveyList();

    // 설문 읽음 처리
    axiosClient
      .post(`/func/diet_survey/read`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });

    // 테스트 전송용
    // let formData = new FormData();
    // formData.append('agree_privacy', 'Y');
    // formData.append('agree_sensitive', 'Y');
    // formData.append('gender', 'F');
    // formData.append('age', '22');
    // formData.append('height', '155');
    // formData.append('weight', '55');
    // formData.append('activity_level', '2');
    // formData.append('prefer_protein', '돼지고기,두부');
    // formData.append('avoid_food', '우유,콩류,오징어');
    // formData.append('target_weight', '2');
    // formData.append('prefer_diet', '칼로리 맞춤식');

    // axiosClient
    //   .post(`/func/diet_survey`, formData, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem('token')}`,
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res.data.data);
    //     setFoodDetailList(res.data.data);
    //     setIsNotSelect('');
    //     setIsAnswerOk('Y');
    //     setIsAskOk(true);
    //     setDietId(res.data.data.diet_survey_id);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  const getSurveyList = () => {
    axiosClient
      .get(`/data/diet_survey?is_current='Y'`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setAllSurveryList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!allSurveyList) return;

    const filterSurvey = allSurveyList.reverse();

    setSurveyList(filterSurvey);
    if (!filterSurvey.length) return;

    // 해당 주에 식단 발급 여부
    const today = new Date();
    const weekFood = filterSurvey.some((item) => {
      const startDate = new Date(item.start_date);
      const endDate = new Date(item.end_date);

      return today >= startDate && today <= endDate;
    });

    setIsWeekFood(weekFood);

    // 랜덤발급인 것만 리스트 filter
    const filterRandom = filterSurvey.filter(
      (survey) => survey.random_issue === 'Y',
    );
    if (filterRandom.length > 0) setRandomList(filterRandom.reverse());

    // 랜덤 아닌 것만 리스트 filter
    const filterNoRandom = filterSurvey.filter(
      (survey) => survey.random_issue === 'N',
    );

    // 전체가 랜덤발급인지 여부
    const isAllRandom = filterSurvey.every(
      (survey) => survey.random_issue === 'Y',
    );

    // 전체 설문 여부
    const isSurvey = filterSurvey.some((survey) => survey.gender !== 'N');

    // 전체가 랜덤 발급이고 설문이 하나라도 없는 경우
    if (isAllRandom && !isSurvey) {
      setDietId(filterSurvey[0]?.id);
      return;
    }

    let surveyList: any;

    // 랜덤 아닌 첫번째 배열을 설문항목으로 사용 -> x

    // 설문 있는 첫 번째 배열을 설문항목으로 사용
    const idx = filterSurvey.findIndex((survey) => survey.gender !== 'N');

    // // 마지막 배열
    // const idx = filterSurvey.findIndex((survey) => survey.gender !== 'N');
    surveyList = filterSurvey[idx];
    let reverseRandom: any;
    // 설문에 쓰는 식단을 제외한 나머지 식단들만 setNoRandomList에 설정
    if (filterNoRandom.length > 0) {
      if (filterNoRandom.length === 1 && filterSurvey.length !== 1)
        setNoRandomList(filterNoRandom);
      else {
        const noRandomList = filterNoRandom.filter(
          (item) => item !== surveyList,
        );
        // reverseRandom = noRandomList.reverse();
        reverseRandom = noRandomList;
        setNoRandomList(reverseRandom);
      }
      setNoSurveyFix(true);
    }

    if (filterNoRandom?.length)
      setDietId(filterNoRandom[filterNoRandom.length - 1]?.id);
    else setDietId(surveyList?.id);

    if (surveyList?.is_fixed === 'Y') {
      setIsDietFoodGoing('이대로 식단 진행할게요!');
      setSurveyFix(true);
    } else {
      setIsFixNum(0);
      setIsDietFoodGoing('');
      if (reverseRandom?.length && surveyList?.random_issue === 'N')
        setSurveyFix(true);
    }

    setIsRequest(true);
    setIsTerm1(true);
    setIsTerm2(true);
    if (surveyList?.gender === 'F') setIsGender('여성');
    else if (surveyList?.gender === 'M') setIsGender('남성');
    setIsAge(surveyList?.age.toString());
    setIsAgeOk(true);
    setIsInitAgeOk(true);
    setIsHeight(surveyList?.height.toString());
    setIsWeight(surveyList?.weight.toString());
    setIsUserInfoOk(true);
    setIsInitUserInfoOk(true);
    const index = surveyList?.activity_level - 1;
    const filterActive =
      isActiveArr[index] !== undefined ? isActiveArr[index].toString() : '';
    setIsActive(filterActive);
    setIsActiveNum(surveyList?.activity_level);
    if (surveyList?.prefer_protein) {
      const filterProtein = surveyList?.prefer_protein.split(',');
      setIsProtein(filterProtein);
      setIsProteinUpdate(true);
    }
    if (surveyList?.avoid_food_tag) {
      const filterHate = surveyList?.avoid_food_tag.split(',');
      setIsHateFood(filterHate);
      setIsHateUpdate(true);
    }

    // 목표 몸무게 범위 선택 추가
    setIsLoseWeight('내 몸무게의 10% 이상');
    setIsLoseWeightNum(1);

    if (surveyList.prefer_diet) {
      const filterPrefer = surveyList.prefer_diet.split(',');
      setIsDietFood(filterPrefer);
      setIsDietFoodUpdate(true);
    }

    if (surveyList.random_issue === 'N') {
      setIsAskOk(true);
      setIsAnswerOk('Y');
    } else {
      setIsAskOk(false);
      setIsAnswerOk('Y');
    }
  }, [allSurveyList]);

  useEffect(() => {
    if (isFixNum === 2) {
      const timer = setTimeout(() => {
        setIsFixNum(0);
        setNoFixLast(false);
        if (!noRandomList.length) setSurveyFix(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isFixNum]);

  const weekMsg = `${name}님, 한 주간 식단과 제품 복용은 잘 하셨나요?
                   목표 달성을 위해 한 발자국씩 나아가는 ${name}님을 응원합니다 :)
                   
                      다음 식단표 전해드립니다. 이번 한 주도 힘내세요!`;

  const randomMsg = `${name}님, 한 주 식단이 발급되었습니다.
[제 상태를 진단하고, 식단을 관리받고 싶어요.] 버튼을 눌러 ${name}님의 정보를 알려주세요.

진단을 완료하시면 그 정보를 토대로 다음 주 식단부터는 ${name}님 딱 맞춤 식단이 제공됩니다.`;

  const waitMsg = `${name}님의 다이어트를 위한 맞춤 식단을 구성하고 있습니다.
                    잠시만 기다려 주세요.
                  
                    다이어트 프로그램이 진행되면, 복용해야 하는 제품을 온닥 앱이 알려드리는 알림 시간에 따라 잘 섭취해 주세요.
                    제안 드린 식단을 최대한 지켜주시고, 가급적 음주를 피해주세요.
                    
                    온닥이 성공적인 다이어트를 위해 함께 노력하겠습니다 :)`;

  const getProtein = (value: any) => {
    const updatedProtein = [...isProtein];
    const index = updatedProtein.indexOf(value);

    if (index === -1) {
      // 배열 존재x
      updatedProtein.push(value);
    } else {
      // 배열에 존재
      updatedProtein.splice(index, 1);
    }

    setIsProtein(updatedProtein);
    setIsProteinUpdate(true);
  };

  const getHateFood = (value: any) => {
    const updatedHateFood = [...isHateFood];
    const index = updatedHateFood.indexOf(value);

    if (index === -1) {
      updatedHateFood.push(value);
    } else {
      updatedHateFood.splice(index, 1);
    }

    setIsHateFood(updatedHateFood);
    setIsHateUpdate(true);
  };

  const getDietFood = (value: any) => {
    const updatedDietFood = [...isDietFood];
    const index = updatedDietFood.indexOf(value);

    if (index === -1) {
      updatedDietFood.push(value);
    } else {
      updatedDietFood.splice(index, 1);
    }

    setIsDietFood(updatedDietFood);
    setIsDietFoodUpdate(true);
  };

  const handleContextMenu = (e: any) => {
    e.preventDefault();
  };

  const onFix = (num: number) => {
    if (num !== 1) return;
    let formData = new FormData();

    formData.append('diet_survey_id', dietId);
    formData.append('is_fixed', 'Y');

    axiosClient
      .put('/func/diet_survey/fix', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const DateFormat = (date: any) => {
    const formattedDate = moment(date).format('YYYY년 M월 D일');

    return <div>{formattedDate}</div>;
  };

  // 다시보내는지, 단순 설문 업데이트 디폴트 Y (랜덤발급 받은 경우 설문만 업데이트)
  // 설문만 업데이트 한다 N으로 전송
  const onSubmit = (reSubmit?: string, isFood?: string) => {
    if (!isAgeOk) {
      setIsNotSelect('나이 입력을 완료해 주세요.');
      setIsAnswerOk('N');
      return false;
    }
    if (!numRegex.test(isAge)) {
      setIsNotSelect('나이를 올바르게 입력해 주세요. (숫자)');
      setIsAnswerOk('N');
      return false;
    }
    if (!isUserInfoOk) {
      setIsNotSelect('키와 몸무게 입력을 완료해 주세요.');
      setIsAnswerOk('N');
      return false;
    }
    if (!numRegex.test(isHeight)) {
      setIsNotSelect('키와 몸무게를 올바르게 입력해 주세요. (숫자)');
      setIsAnswerOk('N');
      return false;
    }
    if (!numRegex.test(isWeight)) {
      setIsNotSelect('키와 몸무게를 올바르게 입력해 주세요. (숫자)');
      setIsAnswerOk('N');
      return false;
    }
    if (!isProtein.length) {
      setIsNotSelect('선호하는 단백질 종류가 선택되지 않았습니다.');
      setIsAnswerOk('N');
      return false;
    }
    if (!isHateFood.length) {
      setIsNotSelect('기피하거나 알러지가 있는 식품이 선택되지 않았습니다.');
      setIsAnswerOk('N');
      return false;
    }
    if (isHateFood.includes('기타(직접입력)') && !isHateEtcOk) {
      setIsNotSelect(
        '기피하거나 알러지가 있는 식품 기타란을 입력을 완료해 주세요.',
      );
      setIsAnswerOk('N');
      return false;
    }
    if (isHateFood.includes('기타(직접입력)') && !isHateEtc) {
      setIsNotSelect('기피하거나 알러지가 있는 식품 기타란을 입력해 주세요.');
      setIsAnswerOk('N');
      return false;
    }
    if (!isDietFood.length) {
      setIsNotSelect('선호하는 다이어트 식단이 선택되지 않았습니다.');
      setIsAnswerOk('N');
      return false;
    }

    let formData = new FormData();

    const hasEtc = isHateFood.includes('기타(직접입력)');

    const filteredHateFood = isHateFood.filter(
      (item) => item !== '기타(직접입력)',
    );

    const avoidFood = hasEtc
      ? [...filteredHateFood, isHateEtc].join(',')
      : filteredHateFood.join(',');

    formData.append('agree_privacy', 'Y');
    formData.append('agree_sensitive', 'Y');
    formData.append('gender', isGender === '남성' ? 'M' : 'F');
    formData.append('age', isAge);
    formData.append('height', isHeight);
    formData.append('weight', isWeight);
    formData.append('activity_level', isActiveNum.toString());
    formData.append('prefer_protein', isProtein.join(','));
    formData.append('avoid_food', avoidFood);
    formData.append('target_weight', isLoseWeightNum.toString());
    formData.append('prefer_diet', isDietFood.join(','));
    if (reSubmit) formData.append('diet_survey_id', dietId);

    // 랜덤 발급 받은 주에 설문하면 설문만 업데이트 한다는 내용
    if (isFood) formData.append('reissue', 'N');

    axiosClient
      .post(`/func/diet_survey`, formData, {
        // .post(`/func/diet_survey`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setFoodDetailList(res.data.data);
        setIsNotSelect('');
        setIsAnswerOk('Y');

        // 랜덤 발급 받은 주는 확인했어요 까지만 보여야 함
        // 설문만 업데이트 한 경우에는 N으로 값이 넘어옴
        // 설문만 업데이트했다는 값이 없으면 setIsAskOk true처리
        if (!isFood) setIsAskOk(true);
        setDietId(res.data.data.diet_survey_id);
      })
      .catch((err) => {
        console.log(err);
      });
    return true;
  };

  useEffect(() => {
    scrollDown();
  }, [chatList]);

  useEffect(() => {
    if (
      !isRequest &&
      !isTerm1 &&
      !isTerm2 &&
      !isGender &&
      !isInitAgeOk &&
      !isInitUserInfoOk &&
      !isActive &&
      !isProteinUpdate &&
      !isHateUpdate &&
      !isLoseWeight &&
      !isDietFoodUpdate &&
      !isDietFoodGoing &&
      !isFoodListWait &&
      (!isAskOk || !isAnswerOk)
    )
      return;
    setChatList((prev) => [...prev, true]);
  }, [
    isRequest,
    isTerm1,
    isTerm2,
    isGender,
    isInitAgeOk,
    isInitUserInfoOk,
    isActive,
    isProteinUpdate,
    isHateUpdate,
    isLoseWeight,
    isDietFoodUpdate,
    isDietFoodGoing,
    isAskOk,
    isAnswerOk,
    isFoodListWait,
  ]);

  const scrollDown = () => {
    if (!chatRef.current) return;
    chatRef.current.scrollTo({ top: 99999, behavior: 'smooth' });
  };

  return (
    <div>
      <Header />
      <div
        className={`${styles.contentWrap} ${isDesktop ? styles.isDesktop : ''}`}
      >
        <div
          style={{
            height: isMobile ? 'calc(100vh - 176px)' : 'calc(100vh - 245px)',
          }}
          className={styles.chatWrap}
          ref={chatRef}
        >
          <div className={styles.dateWrap}>
            {surveyList[0]
              ? DateFormat(surveyList[0]?.created_at)
              : DateFormat(new Date())}
          </div>
          <div className={styles.enterWrap}>
            {name}님과 식단 관리 매니저님이 입장하셨습니다.
          </div>

          {randomList &&
            randomList.map((survey, index) => (
              <div key={index}>
                <div className={styles.managerWrap}>
                  <span className={styles.nameWrap}>식단 관리 매니저</span>
                  <div className={styles.chatContentFrame}>
                    <div
                      className={`${styles.chatContentWrap} ${styles.dietFoodDetailWrap}`}
                      onClick={() => {
                        if (foodDetailList)
                          navigate(
                            `/dietfood-detail/${foodDetailList.diet_survey_id}`,
                            {
                              state: {
                                foodDetailList: foodDetailList,
                              },
                            },
                          );
                        else if (survey.id) {
                          navigate(`/dietfood-detail/${survey.id}`, {
                            state: {
                              foodDetailList: foodDetailList,
                            },
                          });
                        }
                      }}
                    >
                      <div className={styles.dietFoodDetailImgWrap}>
                        <img
                          src={dietFood}
                          alt="dietFood"
                          onContextMenu={handleContextMenu}
                        />
                      </div>
                      <span>식단 상세보기</span>
                    </div>
                    <div className={styles.chatDateWrap}>
                      <span>
                        {survey
                          ? moment(survey.created_at).format('YY.MM.DD')
                          : moment(new Date()).format('YY.MM.DD')}
                      </span>
                      <span>
                        {survey
                          ? moment(survey.created_at).format('HH:mm')
                          : moment(new Date()).format('HH:mm')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.managerWrap}>
                  <span className={styles.nameWrap}>식단 관리 매니저</span>
                  <div className={styles.chatContentFrame}>
                    <div className={styles.chatContentWrap}>{randomMsg}</div>
                    <div className={styles.chatDateWrap}>
                      <span>
                        {survey
                          ? moment(survey.created_at).format('YY.MM.DD')
                          : moment(new Date()).format('YY.MM.DD')}
                      </span>
                      <span>
                        {survey
                          ? moment(survey.created_at).format('HH:mm')
                          : moment(new Date()).format('HH:mm')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div
            className={`${styles.managerWrap} ${
              isRequest ? styles.isOpen : styles.isOpen
            }`}
          >
            <span className={styles.nameWrap}>식단 관리 매니저</span>
            <div className={styles.chatContentFrame}>
              <div className={styles.chatContentWrap}>
                {name}님, 안녕하세요! 무엇을 도와드릴까요?
              </div>
              <div className={styles.chatDateWrap}>
                <span>
                  {surveyList[0]
                    ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                    : moment(new Date()).format('YY.MM.DD')}
                </span>
                <span>
                  {surveyList[0]
                    ? moment(surveyList[0]?.created_at).format('HH:mm')
                    : moment(new Date()).format('HH:mm')}
                </span>
              </div>
            </div>
            <div
              className={`${styles.quesFrame} ${isRequest ? styles.hit : ''}`}
            >
              <div
                className={`${styles.quesWrap} ${
                  (isAskOk || isAnswerOk) && styles.noPointer
                }`}
                onClick={() => {
                  if (isAskOk || isAnswerOk) return;
                  setIsRequest(true);
                }}
              >
                제 상태를 진단하고, 식단을 관리받고 싶어요.
              </div>
            </div>
          </div>
          {isRequest && (
            <>
              <div className={styles.userWrap}>
                <div className={styles.userChatContentFrame}>
                  <div className={styles.userChatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                  <div className={styles.userChatContentWrap}>
                    제 상태를 진단하고, 식단을 관리받고 싶어요.
                  </div>
                </div>
              </div>
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div className={styles.chatContentWrap}>
                    온닥 다이어트 프로그램은 {name}님의 상황에 맞는 맞춤형
                    식단을 제공해 드리기 위해, 개인정보(민감정보 포함)를 수집 ·
                    이용하고 있습니다. 내용을 자세히 살펴보신 후 동의 여부를
                    체크해 주세요.
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
                <div
                  className={`${styles.quesFrame} ${
                    isTerm1 ? styles.hit : ''
                  } ${styles.termsFrame}`}
                >
                  <div
                    className={`${styles.quesWrap} ${
                      (isAskOk || isAnswerOk) && styles.noPointer
                    }`}
                    onClick={() => {
                      if (isAskOk || isAnswerOk) return;
                      setIsTerm1(true);
                    }}
                  >
                    <img src={check} alt="check" />
                    <div className={styles.termWrap}>
                      <span className={styles.bigText}>
                        개인정보 수집 · 이용 동의
                      </span>
                      <span>(필수)</span>
                    </div>
                  </div>
                  <span
                    className={styles.detailWrap}
                    onClick={() => {
                      setIsTermOpen1(true);
                    }}
                  >
                    상세보기
                  </span>
                </div>
                <div
                  className={`${styles.quesFrame} ${
                    isTerm2 ? styles.hit : ''
                  } ${styles.termsFrame}`}
                >
                  <div
                    className={`${styles.quesWrap} ${
                      (isAskOk || isAnswerOk) && styles.noPointer
                    }`}
                    onClick={() => {
                      if (isAskOk || isAnswerOk) return;
                      setIsTerm2(true);
                    }}
                  >
                    <img src={check} alt="check" />
                    <div className={styles.termWrap}>
                      <span className={styles.bigText}>
                        민감정보 수집 · 이용 동의
                      </span>
                      <span>(필수)</span>
                    </div>
                  </div>
                  <span
                    className={styles.detailWrap}
                    onClick={() => {
                      setIsTermOpen2(true);
                    }}
                  >
                    상세보기
                  </span>
                </div>
                <div className={styles.termTextWrap}>
                  <span>
                    - 만 14세 미만 아동은 서비스 이용이 제한됩니다. <br />-
                    본인은 만 14세 이상으로 개인정보 및 약관 동의 내용을
                    확인하였으며, 위 내용에 동의합니다.
                  </span>
                </div>
              </div>
            </>
          )}

          {isTerm1 && isTerm2 && (
            <>
              <div className={styles.userWrap}>
                <div className={styles.userChatContentFrame}>
                  <div className={styles.userChatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                  <div className={styles.userChatContentWrap}>동의합니다.</div>
                </div>
              </div>
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div className={styles.chatContentWrap}>
                    {name}님의 성별을 선택해 주세요.
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
                <div className={styles.quesFrame}>
                  <div
                    className={`${styles.quesWrap} ${
                      isGender === '여성' ? styles.hit : ''
                    } ${(isAskOk || isAnswerOk) && styles.noPointer}`}
                    onClick={() => {
                      if (isAskOk || isAnswerOk) return;
                      setIsGender('여성');
                    }}
                  >
                    여성
                  </div>
                  <div
                    className={`${styles.quesWrap} ${
                      isGender === '남성' ? styles.hit : ''
                    } ${(isAskOk || isAnswerOk) && styles.noPointer}`}
                    onClick={() => {
                      if (isAskOk || isAnswerOk) return;
                      setIsGender('남성');
                    }}
                  >
                    남성
                  </div>
                </div>
              </div>
            </>
          )}
          {isGender !== '' && (
            <>
              <div className={styles.userWrap}>
                <div className={styles.userChatContentFrame}>
                  <div className={styles.userChatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                  <div className={styles.userChatContentWrap}>{isGender}</div>
                </div>
              </div>
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div
                    className={`${styles.chatContentWrap} ${
                      isNotSelect.includes('나이') ? styles.errWrap : ''
                    }`}
                  >
                    {name}님의 나이를 입력해 주세요.
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.userWrap}>
                <div className={styles.userChatContentFrame}>
                  <div className={styles.userChatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                  <div
                    className={`${styles.userChatContentWrap} ${styles.userInputContentWrap}`}
                  >
                    <span>나이:</span>
                    <span className={styles.ageUnit1}>만</span>
                    <input
                      className={styles.ageInput}
                      placeholder=""
                      type="text"
                      maxLength={3}
                      value={isAge}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        setIsAge(value);
                      }}
                      disabled={isAgeOk && true}
                    />
                    <span className={styles.ageUnit2}>세</span>
                  </div>
                </div>
                <div
                  className={styles.inputBtnWrap}
                  onClick={() => {
                    if (isAskOk || isAnswerOk) return;
                    if (!isAge) return alert('나이를 입력해주세요.');
                    setIsAgeOk((prev) => !prev);
                    setIsInitAgeOk(true);
                  }}
                >
                  {isAgeOk ? '수정' : '입력완료'}
                </div>
              </div>
            </>
          )}
          {isInitAgeOk && (
            <>
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div
                    className={`${styles.chatContentWrap} ${
                      isNotSelect.includes('키') ? styles.errWrap : ''
                    }`}
                  >
                    {name}님의 키와 몸무게를 입력해 주세요.
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.userWrap}>
                <div className={styles.userChatContentFrame}>
                  <div className={styles.userChatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                  <div
                    className={`${styles.userChatContentWrap} ${styles.userInputContentWrap}`}
                  >
                    <span>키:</span>
                    <input
                      placeholder=""
                      type="text"
                      maxLength={3}
                      value={isHeight}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        setIsHeight(value);
                      }}
                      disabled={isUserInfoOk && true}
                    />
                    <span className={styles.unit1}>cm</span>
                    <span>몸무게:</span>
                    <input
                      className={styles.weightInput}
                      placeholder=""
                      type="text"
                      maxLength={3}
                      value={isWeight}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        setIsWeight(value);
                      }}
                      disabled={isUserInfoOk && true}
                    />
                    <span className={styles.unit2}>kg</span>
                  </div>
                </div>
                <div
                  className={styles.inputBtnWrap}
                  onClick={() => {
                    if (isAskOk || isAnswerOk) return;
                    if (!isHeight || !isWeight)
                      return alert('키와 몸무게를 입력해주세요.');
                    setIsUserInfoOk((prev) => !prev);
                    setIsInitUserInfoOk(true);
                  }}
                >
                  {isUserInfoOk ? '수정' : '입력완료'}
                </div>
              </div>
            </>
          )}
          {isInitUserInfoOk && (
            <>
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div className={styles.chatContentWrap}>
                    평소 활동량을 선택해 주세요.
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
                {isActiveArr &&
                  isActiveArr.map((item, idx) => (
                    <div key={idx}>
                      <div
                        className={styles.quesFrame}
                        onClick={() => {
                          if (isAskOk || isAnswerOk) return;
                          setIsActive(item);
                          setIsActiveNum(idx + 1);
                        }}
                      >
                        <div
                          className={`${styles.quesWrap} ${
                            isActiveNum === idx + 1 ? styles.hit : ''
                          }  ${(isAskOk || isAnswerOk) && styles.noPointer}`}
                        >
                          {item}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}

          {isActiveNum !== 0 && (
            <>
              <div className={styles.userWrap}>
                <div className={styles.userChatContentFrame}>
                  <div className={styles.userChatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                  <div className={styles.userChatContentWrap}>{isActive}</div>
                </div>
              </div>
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div
                    className={`${styles.chatContentWrap} ${
                      isNotSelect.includes('단백질') ? styles.errWrap : ''
                    }`}
                  >
                    선호하는 단백질의 종류를 선택해 주세요.
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
                <div className={styles.quesFrame}>
                  {proteinArr &&
                    proteinArr.map((item, idx) => (
                      <div
                        key={idx}
                        className={`${styles.quesWrap} ${
                          isProtein.includes(item) ? styles.hit : ''
                        } ${styles.quesWrap} ${
                          (isAskOk || isAnswerOk) && styles.noPointer
                        }`}
                        onClick={() => {
                          if (isAskOk || isAnswerOk) return;
                          getProtein(item);
                        }}
                      >
                        {item}
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}

          {isProteinUpdate && (
            <>
              <div className={styles.userWrap}>
                <div className={styles.userChatContentFrame}>
                  <div className={styles.userChatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                  <div className={styles.userChatContentWrap}>
                    {isProtein.join(', ')}
                  </div>
                </div>
              </div>
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div
                    className={`${styles.chatContentWrap} ${
                      isNotSelect.includes('기피') ? styles.errWrap : ''
                    }`}
                  >
                    기피하거나 알러지가 있는 식품을 선택해 주세요.
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
                <div className={styles.quesFrame}>
                  {hateFoodArr &&
                    hateFoodArr.map((item, idx) => (
                      <div
                        key={idx}
                        className={`${styles.quesWrap} ${
                          isHateFood.includes(item) ||
                          (item === '기타(직접입력)' &&
                            isHateFood.some(
                              (food) => !hateFoodArr.includes(food),
                            ))
                            ? styles.hit
                            : ''
                        } ${(isAskOk || isAnswerOk) && styles.noPointer}`}
                        onClick={() => {
                          if (isAskOk || isAnswerOk) return;
                          getHateFood(item);
                        }}
                      >
                        {item}
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
          {isHateUpdate && (
            <>
              <div className={styles.userWrap}>
                {isHateFood.filter((item) => item !== '기타(직접입력)').length >
                  0 && (
                  <div className={styles.userChatContentFrame}>
                    <div className={styles.userChatDateWrap}>
                      <span>
                        {surveyList[0]
                          ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                          : moment(new Date()).format('YY.MM.DD')}
                      </span>
                      <span>
                        {surveyList[0]
                          ? moment(surveyList[0]?.created_at).format('HH:mm')
                          : moment(new Date()).format('HH:mm')}
                      </span>
                    </div>
                    <div className={styles.userChatContentWrap}>
                      {isHateFood
                        .filter((item) => item !== '기타(직접입력)')
                        .join(', ')}
                    </div>
                  </div>
                )}
                {isHateFood && isHateFood.includes('기타(직접입력)') && (
                  <div className={styles.userChatContentFrame}>
                    <div className={styles.userChatDateWrap}>
                      <span>
                        {surveyList[0]
                          ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                          : moment(new Date()).format('YY.MM.DD')}
                      </span>
                      <span>
                        {surveyList[0]
                          ? moment(surveyList[0]?.created_at).format('HH:mm')
                          : moment(new Date()).format('HH:mm')}
                      </span>
                    </div>
                    <div
                      className={`${styles.userChatContentWrap} ${styles.userInputContentWrap}`}
                    >
                      <span>기타:</span>
                      <input
                        className={styles.hateEtcInput}
                        type="text"
                        value={isHateEtc}
                        onChange={(e) => {
                          const value = e.currentTarget.value;
                          setIsHateEtc(value);
                        }}
                      />
                    </div>
                  </div>
                )}
                {isHateFood && isHateFood.includes('기타(직접입력)') && (
                  <div
                    className={styles.inputBtnWrap}
                    onClick={() => {
                      if (!isHateEtc) return alert('기타란을 입력해 주세요.');
                      setIsHateEtcOk((prev) => !prev);
                      setIsHateEtcOkBtnClicked(true);
                      if (
                        isNotSelect ===
                        '기피하거나 알러지가 있는 식품 기타란을 입력을 완료해 주세요.'
                      ) {
                        setTimeout(() => {
                          setIsFoodListWait(true);
                          setIsAskOk(true);
                        }, 5000);
                      }
                      if (isAskOk || isAnswerOk) return;
                    }}
                  >
                    {isHateEtcOk ? '수정' : '입력완료'}
                  </div>
                )}
              </div>
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div className={styles.chatContentWrap}>
                    이번 다이어트 프로그램을 통해서 감량하고 싶은 몸무게의
                    범위를 선택해 주세요.
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
                <div className={styles.quesFrame}>
                  <div
                    className={`${styles.quesWrap} ${
                      isLoseWeight === '내 몸무게의 10% 미만' ? styles.hit : ''
                    } ${(isAskOk || isAnswerOk) && styles.noPointer}`}
                    onClick={() => {
                      if (isAskOk || isAnswerOk) return;
                      setIsLoseWeight('내 몸무게의 10% 미만');
                      setIsLoseWeightNum(1);
                    }}
                  >
                    내 몸무게의 10% 미만
                  </div>
                  <div
                    className={`${styles.quesWrap} ${
                      isLoseWeight === '내 몸무게의 10% 이상' ? styles.hit : ''
                    } ${(isAskOk || isAnswerOk) && styles.noPointer}`}
                    onClick={() => {
                      if (isAskOk || isAnswerOk) return;
                      setIsLoseWeight('내 몸무게의 10% 이상');
                      setIsLoseWeightNum(2);
                    }}
                  >
                    내 몸무게의 10% 이상
                  </div>
                </div>
              </div>
            </>
          )}
          {isLoseWeight !== '' && (
            <>
              <div className={styles.userWrap}>
                <div className={styles.userChatContentFrame}>
                  <div className={styles.userChatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                  <div className={styles.userChatContentWrap}>
                    {isLoseWeight}
                  </div>
                </div>
              </div>
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div
                    className={`${styles.chatContentWrap} ${
                      isNotSelect.includes('식단') ? styles.errWrap : ''
                    }`}
                  >
                    선호하는 다이어트 식단을 선택해 주세요.
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
                <div className={styles.quesFrame}>
                  {dietFoodArr &&
                    dietFoodArr.map((item, idx) => (
                      <div
                        key={idx}
                        className={`${styles.quesWrap} ${
                          isDietFood.includes(item) ? styles.hit : ''
                        } ${(isAskOk || isAnswerOk) && styles.noPointer}`}
                        onClick={() => {
                          if (isAskOk || isAnswerOk) return;
                          getDietFood(item);
                        }}
                      >
                        {item}
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
          {isDietFoodUpdate && (
            <>
              <div className={styles.userWrap}>
                <div className={styles.userChatContentFrame}>
                  <div className={styles.userChatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                  <div className={styles.userChatContentWrap}>
                    {isDietFood.join(', ')}
                  </div>
                </div>
              </div>
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div className={styles.chatContentWrap}>
                    모든 질문이 마무리되었습니다. 응답하신 내용이 정확한 지
                    마지막으로 확인해 주세요.
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
                <div
                  className={`${styles.quesFrame} ${
                    isAskOk || isAnswerOk || isOk ? styles.hit : ''
                  }`}
                >
                  <div
                    className={`${styles.quesWrap} ${
                      (isAskOk || isAnswerOk) && styles.noPointer
                    }`}
                    onClick={() => {
                      if (isAskOk || isAnswerOk) return;
                      setIsOk(true);
                      //랜덤발급 받은 주에 설문 한 경우
                      if (isWeekFood) onSubmit('Y', 'N');
                      else {
                        if (onSubmit())
                          setIsFoodListWait(true);
                      }
                    }}
                  >
                    확인했어요!
                  </div>
                </div>
                {isAnswerOk === 'N' && !isHateEtcOkBtnClicked && (
                  <div className={styles.notOkWrap}>* {isNotSelect}</div>
                )}
              </div>
            </>
          )}
          {/* 
          식단 랜덤 발급 받은 경우
          (랜덤 발급 받은 주는 확인했어요 까지만 나타나야 함)
          */}
          {isAnswerOk === 'Y' && !isAskOk && (
            <>
              <div className={styles.userWrap}>
                <div className={styles.userChatContentFrame}>
                  <div className={styles.userChatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                  <div className={styles.userChatContentWrap}>확인했어요!</div>
                </div>
              </div>
            </>
          )}
          {/* 설문하고 식단 발급 받은 경우 로딩 문자 */}
          {isHateFood.includes('기타(직접입력)') && !isHateEtcOkBtnClicked
            ? null
            : isFoodListWait && (
                <>
                  <div className={styles.userWrap}>
                    <div className={styles.userChatContentFrame}>
                      <div className={styles.userChatDateWrap}>
                        <span>
                          {surveyList[0]
                            ? moment(surveyList[0]?.created_at).format(
                                'YY.MM.DD',
                              )
                            : moment(new Date()).format('YY.MM.DD')}
                        </span>
                        <span>
                          {surveyList[0]
                            ? moment(surveyList[0]?.created_at).format('HH:mm')
                            : moment(new Date()).format('HH:mm')}
                        </span>
                      </div>
                      <div className={styles.userChatContentWrap}>
                        확인했어요!
                      </div>
                    </div>
                  </div>
                  <div className={styles.managerWrap}>
                    <span className={styles.nameWrap}>식단 관리 매니저</span>
                    <div className={styles.chatContentFrame}>
                      <div className={styles.chatContentWrap}>{waitMsg}</div>
                      <div className={styles.chatDateWrap}>
                        <span>
                          {surveyList[0]
                            ? moment(surveyList[0]?.created_at).format(
                                'YY.MM.DD',
                              )
                            : moment(new Date()).format('YY.MM.DD')}
                        </span>
                        <span>
                          {surveyList[0]
                            ? moment(surveyList[0]?.created_at).format('HH:mm')
                            : moment(new Date()).format('HH:mm')}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
          {/* 설문을 하고 식단을 발급 받은 경우 (랜던 발급 받아서 설문만 업데이트 x) */}
          {isAskOk && !randomList.length && (
            <>
              {!isFoodListWait && (
                <>
                  <div className={styles.userWrap}>
                    <div className={styles.userChatContentFrame}>
                      <div className={styles.userChatDateWrap}>
                        <span>
                          {surveyList[0]
                            ? moment(surveyList[0]?.created_at).format(
                                'YY.MM.DD',
                              )
                            : moment(new Date()).format('YY.MM.DD')}
                        </span>
                        <span>
                          {surveyList[0]
                            ? moment(surveyList[0]?.created_at).format('HH:mm')
                            : moment(new Date()).format('HH:mm')}
                        </span>
                      </div>
                      <div className={styles.userChatContentWrap}>
                        확인했어요!
                      </div>
                    </div>
                  </div>
                  <div className={styles.managerWrap}>
                    <span className={styles.nameWrap}>식단 관리 매니저</span>
                    <div className={styles.chatContentFrame}>
                      <div className={styles.chatContentWrap}>{waitMsg}</div>
                      <div className={styles.chatDateWrap}>
                        <span>
                          {surveyList[0]
                            ? moment(surveyList[0]?.created_at).format(
                                'YY.MM.DD',
                              )
                            : moment(new Date()).format('YY.MM.DD')}
                        </span>
                        <span>
                          {surveyList[0]
                            ? moment(surveyList[0]?.created_at).format('HH:mm')
                            : moment(new Date()).format('HH:mm')}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div
                    className={`${styles.chatContentWrap} ${styles.dietFoodDetailWrap}`}
                    onClick={() => {
                      if (foodDetailList)
                        navigate(
                          `/dietfood-detail/${foodDetailList.diet_survey_id}`,
                          {
                            state: {
                              foodDetailList: foodDetailList,
                            },
                          },
                        );
                      else if (surveyList[0]?.id)
                        navigate(`/dietfood-detail/${surveyList[0]?.id}`, {
                          state: {
                            foodDetailList: foodDetailList,
                          },
                        });
                    }}
                  >
                    <div className={styles.dietFoodDetailImgWrap}>
                      <img
                        src={dietFood}
                        alt="dietFood"
                        onContextMenu={handleContextMenu}
                      />
                    </div>
                    <span>식단 상세보기</span>
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div className={styles.chatContentWrap}>
                    식단은 1주 구성으로, 1주에 한 번씩 해당 창을 통해 제공해드릴
                    예정이니 참고 부탁드립니다 :)
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
                <div className={styles.quesFrame}>
                  <div
                    // className={`${styles.quesWrap} ${
                    //   isFixNum === 1 ? styles.hit : ''
                    // }`}
                    className={`${styles.quesWrap} ${
                      isFixNum === 1 || surveyList[0]?.is_fixed === 'Y'
                        ? styles.hit
                        : ''
                    } ${
                      (noRandomList.length || surveyFix) && styles.noPointer
                    }`}
                    onClick={() => {
                      if (noRandomList.length) return;
                      setIsDietFoodGoing('이대로 식단 진행할게요!');
                      setIsFixNum(1);
                      onFix(1);
                      setSurveyFix(true);
                      if (isFixNum === 1 || surveyList[0].is_fixed === 'Y')
                        return;
                    }}
                  >
                    이대로 식단 진행할게요!
                  </div>
                  <div
                    className={`${styles.quesWrap} ${
                      (noRandomList.length &&
                        surveyList[0]?.is_fixed === 'N') ||
                      isFixNum === 2
                        ? styles.hit
                        : ''
                    } ${
                      (noRandomList.length || surveyFix) && styles.noPointer
                    }`}
                    onClick={() => {
                      if (noRandomList.length) return;
                      setIsDietFoodGoing('식단을 다른 구성으로 변경할래요.');
                      setIsFixNum(2);
                      onSubmit('Y');
                      setSurveyFix(true);
                      // onFix();
                      if (isFixNum === 1 || surveyList[0].is_fixed === 'Y')
                        return;
                    }}
                  >
                    식단을 다른 구성으로 변경할래요.
                  </div>
                </div>
              </div>
            </>
          )}
          {/* 설문에 사용한 식단 확정 여부 클릭했는지 */}
          {surveyFix && (
            <>
              <div className={styles.userWrap}>
                <div className={styles.userChatContentFrame}>
                  <div className={styles.userChatDateWrap}>
                    <span>
                      {surveyList[0]?.updated_at
                        ? moment(surveyList[0]?.updated_at).format('YY.MM.DD')
                        : surveyList[0]?.created_at
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]?.updated_at
                        ? moment(surveyList[0]?.updated_at).format('HH:mm')
                        : surveyList[0]?.created_at
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                  <div className={styles.userChatContentWrap}>
                    {surveyList[0]?.is_fixed === 'Y'
                      ? '이대로 식단 진행할게요!'
                      : isFixNum === 1
                      ? '이대로 식단 진행할게요!'
                      : '식단을 다른 구성으로 변경할래요.'}
                  </div>
                </div>
              </div>
            </>
          )}
          {/* 설문 식단 확정한 경우 */}
          {(isFixNum === 1 || surveyList[0]?.is_fixed === 'Y') && (
            <>
              <div className={styles.managerWrap}>
                <span className={styles.nameWrap}>식단 관리 매니저</span>
                <div className={styles.chatContentFrame}>
                  <div className={styles.chatContentWrap}>
                    이번 한 주도 파이팅 입니다! :)
                  </div>
                  <div className={styles.chatDateWrap}>
                    <span>
                      {surveyList[0]?.updated_at
                        ? moment(surveyList[0]?.updated_at).format('YY.MM.DD')
                        : surveyList[0]?.created_at
                        ? moment(surveyList[0]?.created_at).format('YY.MM.DD')
                        : moment(new Date()).format('YY.MM.DD')}
                    </span>
                    <span>
                      {surveyList[0]?.updated_at
                        ? moment(surveyList[0]?.updated_at).format('HH:mm')
                        : surveyList[0]?.created_at
                        ? moment(surveyList[0]?.created_at).format('HH:mm')
                        : moment(new Date()).format('HH:mm')}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* 설문에 사용하는 식단 외 나머지 식단 리스트 */}
          {noRandomList &&
            noRandomList.map((survey, idx) => (
              <div key={idx}>
                <div className={styles.reFrame}>
                  <div className={styles.dateWrap}>
                    <span>
                      {survey
                        ? DateFormat(survey.created_at)
                        : DateFormat(new Date())}
                    </span>
                  </div>
                  <div
                    className={`${styles.managerWrap} ${
                      isRequest ? styles.isOpen : styles.isOpen
                    }`}
                  >
                    <span className={styles.nameWrap}>식단 관리 매니저</span>
                    <div className={styles.chatContentFrame}>
                      <div className={styles.chatContentWrap}>{weekMsg}</div>
                      <div className={styles.chatDateWrap}>
                        <span>
                          {survey
                            ? moment(survey.created_at).format('YY.MM.DD')
                            : moment(new Date()).format('YY.MM.DD')}
                        </span>
                        <span>
                          {survey
                            ? moment(survey.created_at).format('HH:mm')
                            : moment(new Date()).format('HH:mm')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.managerWrap}>
                    <span className={styles.nameWrap}>식단 관리 매니저</span>
                    <div className={styles.chatContentFrame}>
                      <div
                        className={`${styles.chatContentWrap} ${styles.dietFoodDetailWrap}`}
                        onClick={() => {
                          if (foodDetailList)
                            navigate(
                              `/dietfood-detail/${foodDetailList.diet_survey_id}`,
                              {
                                state: {
                                  foodDetailList: foodDetailList,
                                },
                              },
                            );
                          else if (survey.id)
                            navigate(`/dietfood-detail/${survey.id}`, {
                              state: {
                                foodDetailList: foodDetailList,
                              },
                            });
                        }}
                      >
                        <div className={styles.dietFoodDetailImgWrap}>
                          <img
                            src={dietFood}
                            alt="dietFood"
                            onContextMenu={handleContextMenu}
                          />
                        </div>
                        <span>식단 상세보기</span>
                      </div>
                      <div className={styles.chatDateWrap}>
                        <span>
                          {survey
                            ? moment(survey.created_at).format('YY.MM.DD')
                            : moment(new Date()).format('YY.MM.DD')}
                        </span>
                        <span>
                          {survey
                            ? moment(survey.created_at).format('HH:mm')
                            : moment(new Date()).format('HH:mm')}
                        </span>
                      </div>
                    </div>
                    <div className={styles.quesFrame}>
                      <div
                        className={`${styles.quesWrap} ${
                          (idx === noRandomList.length - 1 && isFixNum === 1) ||
                          (idx === noRandomList.length - 1 &&
                            survey?.is_fixed === 'Y') ||
                          (idx !== noRandomList.length - 1 &&
                            survey?.is_fixed === 'Y')
                            ? styles.hit
                            : ''
                        } ${
                          (survey.id !== dietId || survey.is_fixed === 'Y') &&
                          styles.noPointer
                        }`}
                        onClick={() => {
                          if (survey.id !== dietId) return;
                          if (isFixNum === 1 || survey.is_fixed === 'Y') return;
                          setIsDietFoodGoing('이대로 식단 진행할게요!');
                          setIsFixNum(1);
                          onFix(1);
                        }}
                      >
                        이대로 식단 진행할게요!
                      </div>
                      <div
                        className={`${styles.quesWrap} ${
                          (idx === noRandomList.length - 1 && isFixNum === 2) ||
                          (idx !== noRandomList.length - 1 &&
                            survey?.is_fixed === 'N')
                            ? styles.hit
                            : ''
                        } ${
                          (survey.id !== dietId || survey.is_fixed === 'Y') &&
                          styles.noPointer
                        }`}
                        onClick={() => {
                          if (survey.id !== dietId) return;
                          if (isFixNum === 1 || survey.is_fixed === 'Y') return;
                          setIsDietFoodGoing(
                            '식단을 다른 구성으로 변경할래요.',
                          );
                          setIsFixNum(2);
                          onSubmit('Y');
                          setNoFixLast(true);
                          // onFix();
                        }}
                      >
                        식단을 다른 구성으로 변경할래요.
                      </div>
                    </div>
                  </div>
                </div>
                {/* 마지막배열 확정x 경우 noFixLast가 true일때만 보이고 그 외는 다 보임 */}
                {((noSurveyFix &&
                  ((isFixNum === 1 && idx === noRandomList.length - 1) ||
                    (survey.is_fixed === 'Y' &&
                      idx === noRandomList.length - 1) ||
                    idx < noRandomList.length - 1)) ||
                  (survey.is_fixed === 'N' &&
                    idx === noRandomList.length - 1 &&
                    noFixLast)) && (
                  <>
                    <div className={styles.userWrap}>
                      <div className={styles.userChatContentFrame}>
                        <div className={styles.userChatDateWrap}>
                          <span>
                            {survey.updated_at
                              ? moment(survey.updated_at).format('YY.MM.DD')
                              : survey.created_at
                              ? moment(survey.created_at).format('YY.MM.DD')
                              : moment(new Date()).format('YY.MM.DD')}
                          </span>
                          <span>
                            {survey.updated_at
                              ? moment(survey.updated_at).format('HH:mm')
                              : survey.created_at
                              ? moment(survey.created_at).format('HH:mm')
                              : moment(new Date()).format('HH:mm')}
                          </span>
                        </div>
                        {/* 이전 설문들은 확정된 경우 is_fixed가 Y로 오고 새로운 설문의 확정여부는 isDietFoodGoingNum으로 판단하기 때문에 마지막 설문(새로운 설문)인 경우 조건을 추가  */}
                        <div className={styles.userChatContentWrap}>
                          {survey.is_fixed === 'Y'
                            ? '이대로 식단 진행할게요!'
                            : isFixNum === 1 && idx === noRandomList.length - 1
                            ? '이대로 식단 진행할게요!'
                            : '식단을 다른 구성으로 변경할래요'}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {((isFixNum === 1 && idx === noRandomList.length - 1) ||
                  survey.is_fixed === 'Y') && (
                  <>
                    <div className={styles.managerWrap}>
                      <span className={styles.nameWrap}>식단 관리 매니저</span>
                      <div className={styles.chatContentFrame}>
                        <div className={styles.chatContentWrap}>
                          이번 한 주도 파이팅 입니다! :)
                        </div>
                        <div className={styles.chatDateWrap}>
                          <span>
                            {survey.updated_at
                              ? moment(survey.updated_at).format('YY.MM.DD')
                              : survey.created_at
                              ? moment(survey.created_at).format('YY.MM.DD')
                              : moment(new Date()).format('YY.MM.DD')}
                          </span>
                          <span>
                            {survey.updated_at
                              ? moment(survey.updated_at).format('HH:mm')
                              : survey.created_at
                              ? moment(survey.created_at).format('HH:mm')
                              : moment(new Date()).format('HH:mm')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
      {isTermOpen1 && <SubscribeTermModal closer={setIsTermOpen1} />}
      {isTermOpen2 && <SubscribeTermModal2 closer={setIsTermOpen2} />}
    </div>
  );
}

export default DietManagement;
