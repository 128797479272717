import Header from '../../components/Header/Header';
import styles from './DietFoodDetail.module.scss';
import download from '../../assets/dietProgram/download.png';
import circleCheck from '../../assets/dietProgram/circleCheck.png';
import { isDesktop } from 'react-device-detect';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import jwt_decode from 'jwt-decode';
import { ITokenInfoType, loginCheck, logout } from '../../auth/auth';
import moment from 'moment';

interface IFoodDetailType {
  activity_level: number;
  age: number;
  avoid_food_tag: string;
  created_at: string;
  end_date: string;
  food_materials: IMaterialType[];
  foods: Meal[];
  gender: string;
  height: number;
  id: number;
  img_url: string;
  material_img_url: string;
  daily_img_url: [];
  prefer_diet: string;
  prefer_protein: string;
  start_date: string;
  target_weight: number;
  total_kcal: number;
  updated_at: string;
  weight: number;
}

interface Meal {
  consume_date: string;
  total_kcal: number;
  total_carb: number;
  total_protein: number;
  total_fat: number;
  foods: IFoodType[];
}

interface IFoodType {
  carb: number;
  category1: string;
  category2: string;
  consume_capacity: number;
  consume_date: string;
  consume_quantity: number;
  fat: number;
  kcal: number;
  meal_time: string;
  name: string;
  protein: number;
}

interface IMaterialType {
  name: string;
  sum_capacity: number;
  sum_quantity: number;
}

function DietFoodDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { dietId } = useParams();
  const [name, setName] = useState('');
  const [foodStartDate, setFoodStartDate] = useState('');
  const [foodEndDate, setFoodEndDate] = useState('');
  const [foodDetail, setFoodDetail] = useState<IFoodDetailType>();
  const [foodList, setFoodList] = useState();
  const [mobileImgList, setMobileImgList] = useState<string[]>([]);

  useEffect(() => {
    if (!loginCheck()) return navigate('/');

    const token = localStorage.getItem('token');
    if (token == null || token == 'null') return;
    try {
      const decodedUserInfo: ITokenInfoType = jwt_decode(token!);
      setName(decodedUserInfo.name);
      // setName('테스트');
    } catch (err) {
      logout();
    }

    axiosClient
      .get(`/data/diet_survey/${dietId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setFoodStartDate(formatDate(res.data.data.foods[0]?.[0]?.consume_date));
        setFoodEndDate(
          formatDate(
            res.data.data.foods[res.data.data.foods.length - 1]?.[0]
              ?.consume_date,
          ),
        );
        setFoodDetail(res.data.data);
        setFoodList(res.data.data.foods);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!foodDetail) return;
    const combinedList: string[] = [
      ...(foodDetail.daily_img_url || []),
      foodDetail.material_img_url,
    ];

    setMobileImgList(combinedList);
  }, [foodDetail]);

  const formatDate = (date: string) => {
    return moment(date).format('YY.MM.DD');
  };

  // const handleDownload = (url: string, name: string) => {
  //   fetch(url, { method: 'GET' })
  //     .then((res) => res.blob())
  //     .then((blob) => {
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = name;
  //       document.body.appendChild(a);
  //       a.click();
  //       setTimeout((_: any) => {
  //         window.URL.revokeObjectURL(url);
  //       }, 1000);
  //       a.remove();
  //     })
  //     .catch((err) => {
  //       console.error('err', err);
  //     });
  // };

  const handleDownload = (
    urlOrUrls: string | string[],
    nameOrNames: string | string[],
  ) => {
    const urls = Array.isArray(urlOrUrls) ? urlOrUrls : [urlOrUrls];
    const names = Array.isArray(nameOrNames) ? nameOrNames : [nameOrNames];

    urls.forEach((url, index) => {
      fetch(url, { method: 'GET' })
        .then((res) => res.blob())
        .then((blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = names[index] || `image_${index + 1}`;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
          }, 1000);
          a.remove();
        })
        .catch((err) => {
          console.error(
            `Error downloading ${names[index] || `image_${index + 1}`}:`,
            err,
          );
        });
    });
  };

  return (
    <div>
      <Header />
      <div
        className={`${styles.contentWrap} ${isDesktop ? styles.isDesktop : ''}`}
      >
        <div className={styles.downWrap}>
          {isDesktop ? (
            <div className={styles.leftWrap}>
              <div>
                <span>{name}님 식단표</span>
                <span>
                  ({foodStartDate}~{foodEndDate})
                </span>
              </div>
              <div className={styles.kcalWrap}>
                <div>
                  다이어트를 위한 하루 권장 섭취 칼로리 :{' '}
                  {foodDetail?.total_kcal?.toString().split('.')[0]}Kcal 기준
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.leftWrap}>
              <span>{name}님 식단표</span>
              <span>
                ({foodStartDate}~{foodEndDate})
              </span>
            </div>
          )}
          <div
            className={styles.rightWrap}
            onClick={() => {
              if (!foodDetail || !mobileImgList) return;
              if (isDesktop) handleDownload(foodDetail?.img_url, '식단');
              else handleDownload(mobileImgList, '식단');
            }}
          >
            <img src={download} alt="download" />
            <span>식단다운</span>
          </div>
        </div>
        {!isDesktop && (
          <div className={styles.kcalWrap}>
            <div>다이어트를 위한 하루 권장 섭취 칼로리 : 2,000Kcal 기준</div>
          </div>
        )}
        <div className={styles.listWrap}>
          {foodDetail &&
            foodDetail.foods &&
            foodDetail.foods.map((foodDay: any, dayIdx) => (
              <div key={dayIdx} className={styles.listFrame}>
                <div>
                  <div className={styles.dateWrap}>
                    <span>{`Day ${dayIdx + 1}`}</span>
                    <span>{`(${formatDate(foodDay[0].consume_date)})`}</span>
                  </div>
                  {foodDay.map((detail: any, detailIdx: any) => (
                    <div key={detailIdx} className={styles.listContentWrap}>
                      <div className={styles.contentFrame}>
                        <div className={styles.title}>
                          <span>{detailIdx === 0 ? '점심' : '저녁'}</span>
                          <div>
                            <span>총합 칼로리</span>
                            <span>{`${detail.total_kcal}Kcal`}</span>
                          </div>
                        </div>
                        <div className={styles.foodList}>
                          <div className={styles.foodFrame}>
                            <div className={styles.foodTitle}>
                              {detail.foods.map((food: any, foodIdx: any) => (
                                <span key={foodIdx}>{food.name}</span>
                              ))}
                            </div>
                            <div className={styles.foodCount}>
                              {detail.foods.map((food: any, foodIdx: any) => (
                                <span key={foodIdx}>
                                  {food.consume_capacity
                                    ? `${food.consume_capacity}g`
                                    : `${food.consume_quantity}개`}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className={styles.kcalList}>
                          <div>
                            <span>탄수화물</span>
                            <span>{`${detail.total_carb}g`}</span>
                          </div>
                          <div>
                            <span>단백질</span>
                            <span>{`${detail.total_protein}g`}</span>
                          </div>
                          <div>
                            <span>지방</span>
                            <span>{`${detail.total_fat}g`}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          <div className={styles.basketWrap}>
            <div className={styles.basketFrame}>
              <div className={styles.basketTitle}>
                <span>1주 식단을 위한 </span>
                <span>장바구니</span>
              </div>
              <div className={styles.basketListWrap}>
                {foodDetail &&
                  foodDetail.food_materials.map((material, idx) => (
                    <div key={idx} className={styles.basketListFrame}>
                      {/* <div className={styles.checkWrap}>
                  <input type="checkbox" />
                </div> */}
                      <span>{material.name}</span>
                      <span>
                        {material.sum_capacity && material.sum_capacity + 'g'}
                        {material.sum_capacity &&
                          material.sum_quantity &&
                          ' + '}
                        {material.sum_quantity && material.sum_quantity + '개'}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DietFoodDetail;
