import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './DeliveryChange.module.scss';
import notice from '../../assets/dietProgram/notice.png';
import closeBtn from '../../assets/closeBtn03.png';
import master from '../../assets/dietProgram/payIcon/master.png';
import nh from '../../assets/dietProgram/payIcon/nh.png';
import { useEffect, useState } from 'react';
import PayChangeModal from '../../components/PayChangeModal/PayChangeModal';
import { isDesktop } from 'react-device-detect';
import DaumPostcode from 'react-daum-postcode';
import { useForm } from 'react-hook-form';
import axiosClient from '../../libs/axiosClient';
import { useLocation } from 'react-router-dom';

declare var daum: any;

function DeliveryChange() {
  const location = useLocation();
  const [payChangeOpen, setPayChangeOpen] = useState(false);
  const [openPostcode, setOpenPostcode] = useState<boolean>(false);
  const [subId, setSubId] = useState(0);
  const [billKey, setBillKey] = useState('');
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (!location.state.subId) return;

    setSubId(location.state.subId);

    axiosClient
      .get(
        `/data/subscription/delivery?subscription_id=${location.state.subId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        const result = res.data.data;

        setBillKey(result.bill_key);
        setValue('cardNum', result.card_name + result.card_number);
        setValue('name', result.name);
        setValue('phone', result.phone);
        setValue('zipCode', result.post_code);
        setValue('address1', result.address);
        setValue('address2', result.address_add);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handle = {
    // 버튼 클릭 이벤트
    clickButton: () => {
      setOpenPostcode((current) => !current);
    },

    // 주소 선택 이벤트
    selectAddress: (data: any) => {
      setValue('zipCode', data.zonecode);
      setValue('address1', data.address);
      setOpenPostcode(false);
    },
  };

  const handleSearchAddressOpen = () => {
    if (!isDesktop) setOpenPostcode(true);
    else {
      new daum.Postcode({
        oncomplete: function (data: any) {
          setValue('zipCode', data.zonecode);
          setValue('address1', data.address);
        },
      }).open({ popupKey: 'pcPostPopup1' });
    }
  };

  const onSubmit = () => {
    let formData = new FormData();

    formData.append('subscription_id', subId.toString());
    formData.append('bill_keys', billKey);
    formData.append('name', watch().name);
    formData.append('phone', watch().phone);
    formData.append('post_code', watch().zipCode);
    formData.append('address', watch().address1);
    formData.append('address_add', watch().address2);

    axiosClient
      .post('/func/subscription/delivery', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200) alert('배송정보가 변경되었습니다.');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmitError = (error: any) => {
    let errorList: { message: string; ref: HTMLElement }[] =
      Object.values(error);
    window.scrollTo(
      0,
      window.scrollY + errorList[0].ref.getBoundingClientRect().top - 150,
    );
    alert(errorList[0].message);
  };

  return (
    <div>
      <Header />
      <div className={styles.container}>
        <div className={styles.contentWrap}>
          <div className={styles.topWrap}>배송정보 변경</div>
          <div className={styles.listWrap}>
            <div className={styles.deliveryWrap}>
              <div
                className={`${styles.deliveryFrame} ${
                  errors.cardNum ? styles.warning : ''
                }`}
              >
                <div className={styles.titleWrap}>
                  <span>*</span>
                  <span>결제수단</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${styles.changeInputWrap}`}
                >
                  <img src={master} alt="bank" />
                  <input
                    type="text"
                    {...register('cardNum', {
                      value: '',
                      required: {
                        value: true,
                        message: '결제수단을 선택해 주세요.',
                      },
                    })}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      setValue('cardNum', value);
                    }}
                    readOnly
                  />
                  <div
                    className={styles.payChangeBtn}
                    onClick={() => {
                      setPayChangeOpen(true);
                    }}
                  >
                    결제 수단 변경
                  </div>
                </div>
              </div>
              <div
                className={`${styles.deliveryFrame} ${
                  errors.name ? styles.warning : ''
                }`}
              >
                <div className={styles.titleWrap}>
                  <span>*</span>
                  <span>수령인</span>
                </div>
                <div className={styles.inputWrap}>
                  <input
                    type="text"
                    {...register('name', {
                      value: '',
                      required: {
                        value: true,
                        message: '수령인을 입력해 주세요.',
                      },
                    })}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      setValue('name', value);
                    }}
                  />
                </div>
              </div>
              <div
                className={`${styles.deliveryFrame} ${
                  errors.phone ? styles.warning : ''
                }`}
              >
                <div className={styles.titleWrap}>
                  <span>*</span>
                  <span>연락처</span>
                </div>
                <div className={styles.inputWrap}>
                  <input
                    type="text"
                    {...register('phone', {
                      value: '',
                      required: {
                        value: true,
                        message: '연락처를 입력해 주세요.',
                      },
                    })}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      setValue('phone', value);
                    }}
                  />
                </div>
              </div>
              <div
                className={`${styles.deliveryFrame} ${
                  errors.address1 ? styles.warning : ''
                }`}
              >
                <div className={styles.titleWrap}>
                  <span>*</span>
                  <span>배송지 주소</span>
                </div>
                <div className={styles.addressInputWrap}>
                  <div
                    className={`${styles.searchWrap} ${
                      errors.zipCode ? styles.warning : ''
                    }`}
                  >
                    <input
                      type="text"
                      {...register('zipCode', {
                        value: '',
                        required: {
                          value: true,
                          message: '우편번호를 입력해 주세요.',
                        },
                      })}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        setValue('zipCode', value);
                      }}
                      readOnly
                    />
                    <div
                      className={styles.searchBtn}
                      onClick={handleSearchAddressOpen}
                    >
                      검색
                    </div>
                    {openPostcode && (
                      <div className={styles.addressModalWrap}>
                        <div className={styles.addressModal}>
                          <div className={styles.modalHeader}>
                            <img
                              src={closeBtn}
                              alt="closeBtn"
                              onClick={() => setOpenPostcode(false)}
                            />
                          </div>
                          <DaumPostcode
                            onComplete={handle.selectAddress}
                            autoClose={false}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <input
                    className={styles.address1Input}
                    type="text"
                    {...register('address1', {
                      value: '',
                      required: {
                        value: true,
                        message: '주소를 입력해 주세요.',
                      },
                    })}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      setValue('address1', value);
                    }}
                    readOnly
                  />
                  <input
                    type="text"
                    {...register('address2', {
                      value: '',
                    })}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      setValue('address2', value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.noticeWrap}>
            <img src={notice} alt="notice" />
            <span>변경된 정보는 다음 결제일부터 적용됩니다.</span>
          </div>
          <div className={styles.changeBtnWrap}>
            <div
              className={styles.changeBtn}
              onClick={handleSubmit(onSubmit, onSubmitError)}
            >
              변경하기
            </div>
          </div>
        </div>
      </div>
      {payChangeOpen && (
        <PayChangeModal
          closer={setPayChangeOpen}
          billId={billKey}
          subId={subId}
        />
      )}
      <Footer />
    </div>
  );
}

export default DeliveryChange;
