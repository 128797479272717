import { useEffect } from 'react';
import styles from './AppPushModal.module.scss';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
}

function AppPushModal(props: IPropsType) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  // 푸쉬 허용하면 앱에 허용 보내기
  const handlePush = () => {
    if (localStorage.getItem('mobileApp')) {
      window.flutter_inappwebview
        .callHandler('appPush')
        .then((res: any) => {
          return res;
        })
        .catch((err: any) => {});
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <div className={styles.topWrap}>
            <div className={styles.titleWrap}>
              <span>‘온닥’에서 알림을</span>
              <br />
              <span>보내고자 합니다.</span>
            </div>
            <div className={styles.smallTextWrap}>
              <span>해당 기기로 복용 알람 등 서비스 이용에 필요한</span> <br />
              <span>
                안내 사항을 푸시 알림으로 보내드리겠습니다.
              </span> <br /> <br />
              <span>앱 푸시 수신에 동의하시겠습니까?</span>
            </div>
          </div>
          <div className={styles.btnWrap}>
            <div
              className={styles.noBtn}
              onClick={() => {
                props.closer(false);
              }}
            >
              허용 안 함
            </div>
            <div
              className={styles.okBtn}
              onClick={() => {
                handlePush();
                props.closer(false);
              }}
            >
              허용
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AppPushModal;
