import styles from './CardRegister.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { isDesktop, isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import prevNavi from '../../assets/prevNavi.png';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import jwt_decode from 'jwt-decode';
import { ITokenInfoType, logout } from '../../auth/auth';

function CardRegister() {
  const phoneRegex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubmit, setIsSubmit] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const dateMonthRef = useRef<HTMLInputElement>(null);
  const dateYearRef = useRef<HTMLInputElement>(null);
  const cardPasswordRef = useRef<HTMLInputElement>(null);
  const birthRef = useRef<HTMLInputElement>(null);
  const [isHeader, setIsHeader] = useState(1);
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const pathState = location.state;
    if (pathState) setIsHeader(2);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token == null || token == 'null') return;
    try {
      const decodedUserInfo: ITokenInfoType = jwt_decode(token!);
      setUserId(decodedUserInfo.id.toString());
      setName(decodedUserInfo.name);
    } catch (err) {
      logout();
    }
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: any,
  ) => {
    const { value } = e.target;

    if (value.length === 2) {
      switch (field) {
        case 'dateMonth':
          setValue('dateMonth', value);
          dateYearRef.current?.focus();
          break;
        case 'dateYear':
          setValue('dateYear', value);
          cardPasswordRef.current?.focus();
          break;
        case 'cardPassword':
          setValue('cardPassword', value);
          birthRef.current?.focus();
          break;
        case 'birth':
          break;
      }
    }
  };

  const onSubmit = () => {
    let formData = new FormData();

    formData.append('card_number', watch().cardNum);
    formData.append('expire_month', watch().dateMonth);
    formData.append('expire_year', watch().dateYear);
    formData.append('buyer_auth_number', watch().birth);
    formData.append('card_password_pre_two', watch().cardPassword);
    formData.append('phone', watch().phone);
    formData.append('name', name);
    formData.append('user_id', userId);

    axiosClient
      .post('/func/billkey/register', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (res.data.error_message) return alert(res.data.error_message);
        alert('카드가 등록되었습니다.');
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.detail);
      });
  };

  const onSubmitError = (error: any) => {
    let errorList: { message: string; ref: HTMLElement }[] =
      Object.values(error);
    window.scrollTo(
      0,
      window.scrollY + errorList[0].ref.getBoundingClientRect().top - 150,
    );
    alert(errorList[0].message);
  };

  useEffect(() => {
    if (
      watch().cardNum &&
      watch().dateMonth &&
      watch().dateYear &&
      watch().birth &&
      watch().cardPassword &&
      watch().phone
    )
      setIsSubmit(true);
    else setIsSubmit(false);
  }, [
    watch().cardNum,
    watch().dateMonth,
    watch().dateYear,
    watch().birth,
    watch().cardPassword,
    watch().phone,
  ]);

  return (
    <div>
      {(isHeader === 1 || isDesktop) && <Header />}
      <div
        className={`${styles.contentWrap} ${isMobile ? styles.isMobile : ''}`}
      >
        {isHeader === 1 || isDesktop ? (
          <div className={styles.header}>
            {isMobile && (
              <img onClick={() => navigate(-1)} src={prevNavi} alt="prev" />
            )}
            <span>카드 정보 입력</span>
            <div>(최초 1회)</div>
          </div>
        ) : (
          <div className={styles.orderHeader}>
            <div className={styles.naviWrap}>
              {isMobile && (
                <img onClick={() => navigate(-1)} src={prevNavi} alt="prev" />
              )}
            </div>
            <div className={styles.titleWrap}>
              <span>카드 정보 입력</span>
              <div>(최초 1회)</div>
            </div>
          </div>
        )}
        <div className={styles.cardListWrap}>
          <div className={styles.cardListFrame}>
            <div
              className={`${styles.inputWrap} ${
                errors.cardNum ? styles.waringInputWrap : ''
              }`}
            >
              <span>카드 번호</span>
              <input
                type="text"
                placeholder="카드 번호를 입력해 주세요."
                {...register('cardNum', {
                  value: '',
                  required: {
                    value: true,
                    message: '카드 번호는 필수 입력사항입니다.',
                  },
                })}
                // onChange={handleDateChange}
                onChange={(e) => {
                  let value = e.currentTarget.value;
                  setValue('cardNum', value);
                }}
              />
            </div>
            <div className={styles.dateWrap}>
              <div className={styles.inputWrap}>
                <span>유효 기간</span>
                <div
                  className={`${styles.inputDateWrap} ${
                    errors.dateMonth || errors.dateYear
                      ? styles.waringInputWrap
                      : ''
                  }`}
                >
                  <input
                    type="password"
                    placeholder="MM"
                    maxLength={2}
                    {...register('dateMonth', {
                      value: '',
                      required: {
                        value: true,
                        message: '유효 기간은 필수 입력사항입니다.',
                      },
                    })}
                    ref={dateMonthRef}
                    onChange={(e) => handleInputChange(e, 'dateMonth')}
                  />
                  <span className={styles.placeLabel}> / </span>
                  <input
                    type="password"
                    placeholder="YY"
                    maxLength={2}
                    {...register('dateYear', {
                      value: '',
                      required: {
                        value: true,
                        message: '유효 기간은 필수 입력사항입니다.',
                      },
                    })}
                    ref={dateYearRef}
                    onChange={(e) => handleInputChange(e, 'dateYear')}
                  />
                </div>
              </div>
              <div
                className={`${styles.inputWrap} ${
                  errors.cardPassword ? styles.waringInputWrap : ''
                }`}
              >
                <span>카드 비밀번호</span>
                <input
                  type="password"
                  maxLength={2}
                  placeholder="비밀번호 앞 2자리 숫자"
                  {...register('cardPassword', {
                    value: '',
                    required: {
                      value: true,
                      message: '카드 비밀번호는 필수 입력사항입니다.',
                    },
                  })}
                  ref={cardPasswordRef}
                  onChange={(e) => handleInputChange(e, 'cardPassword')}
                />
              </div>
            </div>
            <div
              className={`${styles.inputWrap} ${
                errors.birth ? styles.waringInputWrap : ''
              }`}
            >
              <span>생년월일 6자리 혹은 사업자 등록 번호</span>
              <input
                type="password"
                placeholder="YYMMDD 또는 사업자번호"
                {...register('birth', {
                  value: '',
                  required: {
                    value: true,
                    message: '생년월일 또는 사업자번호는 필수 입력사항입니다.',
                  },
                })}
                ref={birthRef}
                onChange={(e) => {
                  let value = e.currentTarget.value;
                  setValue('birth', value);
                }}
              />
            </div>
            <div
              className={`${styles.inputWrap} ${
                errors.phone ? styles.waringInputWrap : ''
              }`}
            >
              <span>휴대번호</span>
              <input
                type="text"
                placeholder="01000000000"
                {...register('phone', {
                  value: '',
                  required: {
                    value: true,
                    message: '연락처는 필수 입력사항입니다.',
                  },
                  pattern: {
                    value: phoneRegex,
                    message:
                      '올바른 연락처 형식이 아닙니다. 01로 시작하는 10자리 11자리',
                  },
                })}
                onChange={(e) => {
                  let value = e.currentTarget.value;
                  setValue('phone', value);
                }}
              />
            </div>
            <span className={styles.alertWrap}>
              {name}님의 신용/체크카드만 이용 가능합니다.
            </span>
          </div>
        </div>
        <div className={styles.registerBtnWrap}>
          <div
            className={`${styles.registerBtn} ${isSubmit ? styles.okBtn : ''}`}
            onClick={handleSubmit(onSubmit, onSubmitError)}
          >
            등록하기
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardRegister;
