import { useEffect, useState } from 'react';
import styles from './SubscribeCancelModal.module.scss';
import slide from '../../assets/dietProgram/slide.png';
import bell from '../../assets/dietProgram/bell.png';
import close from '../../assets/dietProgram/close.png';
import { useNavigate } from 'react-router-dom';
import SubscribeCancelOkModal from '../SubscribeCancelOkModal/SubscribeCancelOkModal';
import { isDesktop } from 'react-device-detect';
import useMobileSlide from '../../hook/MobileSlide';
import axiosClient from '../../libs/axiosClient';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
  subscriptionId: number;
  getSubscription: any;
}

function SubscribeCancelModal(props: IPropsType) {
  const navigate = useNavigate();
  const [okModal, setOkModal] = useState(false);
  const { modalRef, handleTouchStart, handleTouchMove, handleTouchEnd } =
    useMobileSlide({ closer: props.closer });

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const subscribeCancel = () => {
    axiosClient
      .post(
        `/func/subscription/unsubscription?subscription_id=${props.subscriptionId}`,
      )
      .then((res) => {
        setOkModal(true);
        props.getSubscription();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div
        className={`${styles.container} ${isDesktop ? styles.isDesktop : ''} ${
          okModal && styles.notContainer
        }`}
      >
        <div ref={modalRef} className={styles.contentWrap}>
          {isDesktop ? (
            <div className={styles.closeWrap}>
              <img
                src={close}
                alt="close"
                onClick={() => {
                  props.closer(false);
                }}
              />
            </div>
          ) : (
            <div
              className={styles.slideWrap}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <img src={slide} alt="slide" />
            </div>
          )}
          <div className={styles.mainWrap}>
            <img src={bell} alt="logo" />
            <span className={styles.quesText}>정말 구독을 해지하시겠어요?</span>
            {/* <div className={styles.smallText}>
              <span>구독 해지 이후 제품을 개별 구매할 시,</span>
              <br />
              <span className={styles.hit}>약 130,000원 상당의 혜택</span>
              <span>을</span>
              <br />
              <span> 받지 못할 수 있습니다.</span>
            </div> */}
          </div>
          <div className={styles.btnWrap}>
            <div
              className={styles.nextBtnWrap}
              onClick={() => {
                props.closer(false);
              }}
            >
              다음에
            </div>
            <div
              className={styles.okBtnWrap}
              onClick={() => {
                subscribeCancel();
              }}
            >
              네, 해지할게요.
            </div>
          </div>
        </div>
      </div>
      {okModal && (
        <SubscribeCancelOkModal
          closer={() => {
            props.closer(false);
            setOkModal(false);
          }}
        />
      )}
    </div>
  );
}

export default SubscribeCancelModal;
