import { useEffect, useState } from 'react';
import styles from './DietProgramWelcomModal.module.scss';
import slide from '../../assets/dietProgram/slide.png';
import welcome from '../../assets/dietProgram/welcome.png';
import { isDesktop } from 'react-device-detect';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
}
function DietProgramWelcomModal(props: IPropsType) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={`${styles.container} ${isDesktop ? styles.isDesktop : ''}`}>
      <div className={styles.contentWrap}>
        <div className={styles.mainWrap}>
          <div className={styles.imgWrap}>
            <img src={welcome} alt="welcome" />
          </div>
          <div className={styles.textWrap}>
            <div className={styles.titleWrap}>
              <span>온닥 다이어트 프로그램</span>
              <br />
              <span>참여하신 것을 환영합니다!</span>
            </div>
            <div className={styles.centerWrap}>
              <span>프로그램을 통해 전담 한의사, 영양 컨설턴트,</span>
              <br />
              <span>다이어트 매니저가 3중 밀착 관리를 통해</span>
              <br />
              <span className={styles.hit}>
                여러분의 근본적인 체질을 개선해 드립니다.
              </span>
            </div>
            <div className={styles.bottomWrap}>
              <span>맞춤형 식단과 건강식품 복용을 통해 성공적인</span>
              <br />
              <br />
              <span>다이어트를 진행해 보세요!</span>
            </div>
          </div>
        </div>
        <div className={styles.btnWrap}>
          <div
            className={styles.btn}
            onClick={() => {
              props.closer(false);
            }}
          >
            진행해 보기
          </div>
        </div>
      </div>
    </div>
  );
}

export default DietProgramWelcomModal;
