import { useEffect } from 'react';
import styles from './SubscribeCancelOkModal.module.scss';
import slide from '../../assets/dietProgram/slide.png';
import ok from '../../assets/dietProgram/ok.png';
import close from '../../assets/dietProgram/close.png';
import { isDesktop } from 'react-device-detect';
import useMobileSlide from '../../hook/MobileSlide';

function SubscribeCancelOkModal(props: any) {
  const { modalRef, handleTouchStart, handleTouchMove, handleTouchEnd } =
    useMobileSlide({ closer: props.closer });

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={`${styles.container} ${isDesktop ? styles.isDesktop : ''}`}>
      <div ref={modalRef} className={styles.contentWrap}>
        {isDesktop ? (
          <div className={styles.closeWrap}>
            <img
              src={close}
              alt="close"
              onClick={() => {
                props.closer(false);
              }}
            />
          </div>
        ) : (
          <div
            className={styles.slideWrap}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img src={slide} alt="slide" />
          </div>
        )}
        <div className={styles.mainWrap}>
          <img src={ok} alt="logo" />
          <span className={styles.quesText}>해지 완료</span>
          <div className={styles.smallText}>
            <span>월간 구독형 지속 프로그램 구독이</span>
            <br />
            <span className={styles.hit}>정상적으로 해지</span>
            <span>되었습니다.</span>
          </div>
        </div>
        <div className={styles.btnWrap}>
          <div
            className={styles.okBtnWrap}
            onClick={() => {
              props.closer(false);
            }}
          >
            확인
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeCancelOkModal;
