import { useEffect, useState } from 'react';
import styles from './CardDelete.module.scss';
import slide from '../../assets/dietProgram/slide.png';
import pay from '../../assets/dietProgram/pay.png';
import close from '../../assets/dietProgram/close.png';
import { useNavigate } from 'react-router-dom';
import SubscribeCancelOkModal from '../SubscribeCancelOkModal/SubscribeCancelOkModal';
import { isDesktop } from 'react-device-detect';
import useMobileSlide from '../../hook/MobileSlide';
import axiosClient from '../../libs/axiosClient';
import { replace } from 'lodash';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
  billKey: any;
}

function CardDelete(props: IPropsType) {
  const navigate = useNavigate();
  const [isSubscribe, setIsSubscribe] = useState(false);
  const { modalRef, handleTouchStart, handleTouchMove, handleTouchEnd } =
    useMobileSlide({ closer: props.closer });

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const onDelete = () => {
    axiosClient
      .post(`/func/billkey/unregister?bill_id=${props.billKey}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (res.data.error_message) return alert(res.data.error_message);
        props.closer(false);
        alert('카드가 삭제되었습니다.');
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div
        className={`${styles.container} ${isDesktop ? styles.isDesktop : ''}`}
      >
        <div
          ref={modalRef}
          className={`${styles.contentWrap} ${
            !isSubscribe && styles.notSubContentWrap
          }`}
        >
          {isDesktop ? (
            <div className={styles.closeWrap}>
              <img
                src={close}
                alt="close"
                onClick={() => {
                  props.closer(false);
                }}
              />
            </div>
          ) : (
            <div
              className={styles.slideWrap}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <img src={slide} alt="slide" />
            </div>
          )}
          <div className={styles.mainWrap}>
            <img src={pay} alt="logo" />
            <span className={styles.quesText}>
              선택한 카드를 {isSubscribe && '정말'} 삭제하시겠어요?
            </span>
            {isSubscribe && (
              <div className={styles.smallText}>
                <span>카드를 삭제할 시, </span>
                <span className={styles.hit}>
                  정기배송 디톡스 풀 패키지(3개월)
                </span>
                <br />
                <span>구독이 자동으로 해지됩니다. 카드 삭제에 따른</span>
                <br />
                <span>구독 해지는 재연장이 불가합니다.</span>
              </div>
            )}
          </div>
          <div className={styles.btnWrap}>
            <div
              className={styles.nextBtnWrap}
              onClick={() => {
                props.closer(false);
              }}
            >
              다음에
            </div>
            <div
              className={styles.okBtnWrap}
              onClick={() => {
                onDelete();
              }}
            >
              네, 삭제할게요.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardDelete;
