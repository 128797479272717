import styles from './SubscribeTermModal.module.scss';
import close from '../../assets/dietProgram/close.png';
import { useEffect } from 'react';

function SubscribeTermModal(props: any) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.contentWrap}>
        <div className={styles.closeWrap}>
          <img
            onClick={() => {
              props.closer(false);
            }}
            src={close}
            alt="close"
          />
        </div>
        <div className={styles.titleWrap}>
          <span>개인정보 수집 · 이용 동의</span>
          <span>(필수)</span>
        </div>
        <div className={styles.listWrap}>
          <div className={styles.listFrmae}>
            <span>항목</span>
            <span>
              성별, 나이, 활동량, 선호 단백질, 감량 목표, 선호 다이어트 식단
            </span>
          </div>
          <div className={styles.listFrmae}>
            <span>이용 목적</span>
            <span>
              개인별 섭취 칼로리 권장량 파악 및 맞춤형 식단 제공을 위한 정밀
              분석
            </span>
          </div>
          <div className={styles.listFrmae}>
            <span>보유 및 이용기간</span>
            <span>회원 탈퇴 또는 프로그램 종료 후 5년 </span>
          </div>
        </div>
        <div className={styles.textWrap}>
          위 개인정보 수집 · 이용에 대한 동의를 거부하실 수 있으나, 필수항목
          동의 거부 시에는 온닥 다이어트 프로그램의 식단 제공 서비스 이용이
          불가능합니다.
        </div>
      </div>
    </div>
  );
}

export default SubscribeTermModal;
